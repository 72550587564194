@import "../../../public/style.scss";

.Template {
    width:100%;
    position: relative;
    overflow: hidden;
    height: 0px;;
    flex-direction: column;
    display: flex;
    background-color: rgba( $gn-white-color , 1 );
    //box-shadow: 0 0px 5px 0 rgba(33, 33, 33 , 0.37);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    transition: all 0.25s ease-in;
    //padding-bottom: 10px;
    //opacity : 0;
    &.appear {
      flex-shrink: 0;
      opacity : 1!important;
      height: calc( 50% - 30px);
      .panel {
        opacity: 1!important;
      }
      .board {
        opacity: 1!important;
      }
    }
    .panel {
      opacity: 0;
      padding: 1.5rem 0px;
      display: flex;
      flex-direction: row;
      width: fit-content;
      min-width: 100%;
      display: none;
      .btn {
        //margin-left:auto;
        //margin-right: 1rem;
        background-color: rgba( $gn-frame-color , 1 ) !important;
        //box-shadow: 0 0px 5px 0 rgba(33, 33, 33 , 0.37) !important;
        //backdrop-filter: blur(2px);
        //-webkit-backdrop-filter: blur(2px);
        //color : rgba($gn-white-color, 1);
        //border-radius: 0px;
        border-style: none;
        &:hover {
          background-color: rgba( $gn-badge-color , 1 ) !important;
        }
      }
      .form-holder {
        display: flex;
        flex-direction: unset;
        flex-wrap: wrap;
        //flex-direction: column;
        .input-holder {
          width: 33.33%;
          display: flex;
          flex-direction: column;
          .name {
            color : rgba( $gn-black-color , 0.75 );
            font-size: 90%; 
            
          }
          .input {
            padding:0px 0.5rem;
            text-align: center;
            color : rgba( $gn-black-color , 0.75 );
            border-style: none;
            background-color: rgba( $gn-black-color , 0.1 ) !important;
            transition: background-color 0.25s ease-in-out;
            &:hover {
              background-color: rgba( $gn-frame-color , 0.25 ) !important;
            }
          }
        }
      }
    }
    .board {
      opacity: 0;
      flex-grow : 1;
      overflow: auto;
      .title {
        position: sticky;
        top:0px;
        width: calc( 100% - 1rem) ;
        background-color: rgba( $gn-frame-color , 1 );
        //box-shadow: 0 0px 5px 0 rgba(33, 33, 33 , 0.37);
        backdrop-filter: blur(2px);
        -webkit-backdrop-filter: blur(2px);
        color: rgba($gn-white-color,1);
        z-index : 99;
        padding: 10px 0.5rem;
        font-size: 110%;
        font-weight: bolder;
      }
      .login {
        margin:1rem auto;
        background-color: rgba( $gn-white-color , 1 ) !important;
        box-shadow: 0 0px 5px 0 rgba( 33, 33, 33 , 0.37 ) !important;
        backdrop-filter: blur(2px);
        -webkit-backdrop-filter: blur(2px);
        color : rgba($gn-white-color, 1);
        border-radius: 0px;
        border-style: none;
        &:hover {
          background-color: rgba( $gn-badge-color , 0.25 ) !important;
        }
      }
      .item {
        width: calc(100% - 3rem);
        padding: 1rem 1.5rem;
        margin : 0px 0rem;
        background-color: rgba( $gn-white-color , 1 );
        border-bottom: 1px solid rgba( $gn-black-color , 0.1 );
        //box-shadow: 0 0px 5px 0 rgba(33, 33, 33 , 0.37);
        //backdrop-filter: blur(2px);
        //-webkit-backdrop-filter: blur(2px);
        display: flex;
        flex-direction: column;
        cursor: pointer;
        transition: all 0.25s ease-in-out;
        z-index : 10;
        position: relative;
        &.selected {
          background-color: rgba( $gn-badge-color , 0.1 );
        }
        .upper {
          background-color: rgba( $gn-black-color , 0.00 );
          color : rgba($gn-black-color, 0.9);
          display: flex;
          flex-direction: row;
          font-size: 100%;
          font-weight: bold;
          justify-content: flex-start;
          padding:5px 0.5rem;
          //padding-bottom:0px;
          text-align: left;
        }
        .middle{
          color : rgba($gn-black-color, 0.8);
          background-color: rgba( $gn-white-color , 0.025 );
          font-size: 90%;
          padding:0px 0rem;
          margin : 0.5rem 0rem;
          margin-top: 0rem;
          text-align: left;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          .badge {
            padding : 0.25rem 0.5rem;
            margin : 0px 0.5rem;
            //background-color: rgba( $gn-badge-color , 0.25 );
            color : rgba($gn-white-color, 1);
            &.public {
              background-color: rgba( $gn-green-color , 1 );
            }
            &.private {
              background-color: rgba( $gn-grey-color , 1 );
            }
          }
          span {
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
        }
        .lower {
          background-color: rgba( $gn-black-color , 0.05 );
          color : rgba($gn-black-color, 0.5);
          display: flex;
          flex-direction: column;
          //padding-top:0px;
          font-size: 90%;
          justify-content: flex-start;
          padding:0.5rem 1.5rem;
          text-align: left;
          .desc {
            height:25px;
            //filter: blur(2px);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            transition: all 0.25s ease-in-out;
            &.show {
              height: fit-content;
              white-space: break-spaces;
              margin: 0.5rem 0px;
            }
          }
        }
        .panel {
          background-color: rgba( $gn-black-color , 0.05 );
          color : rgba($gn-badge-color, 1);
          display: flex;
          flex-direction: column;
          font-size: 100%;
          justify-content: flex-start;
          padding:0px 0rem;
          margin-bottom: 0px;
          height: 0px;
          overflow: hidden;
          transition: all 0.25s ease-in-out;
          .form-group {
            .name {

            }
            .form-control {
              background-color: rgba( $gn-black-color , 0.1 );
              color : rgba($gn-black-color, 0.9);
              border-radius: 0px;
              border-style: none;
              margin:0px 0.75rem;
              width:calc(100% - 3rem);
            }
          }
          .btn {
            margin:1rem auto;
            //margin-right: 1rem;
            //background-color: rgba( $gn-white-color , 0.25 ) !important;
            //box-shadow: 0 0px 5px 0 rgba(33, 33, 33 , 0.37) !important;
            //backdrop-filter: blur(2px);
            //-webkit-backdrop-filter: blur(2px);
            color : rgba($gn-white-color, 1);
            //border-radius: 0px;
            //border-style: none;
            &:hover {
              background-color: rgba( $gn-badge-color , 1 ) !important;
            }
          }
          &.show {
            height: fit-content;
            overflow: visible;
            padding:10px 0rem;
            margin-bottom: 10px;
          }
        }
        &:hover {
          background-color: rgba( $gn-badge-color , 0.25 );
        }
      }
      padding-bottom:100px;
    }

}

@media screen and (max-width: 750px)  {
  .Code {

  }
}