@import "../../public/style.scss";

.Picker-Backdrop {
  display: none;
}

.Picker-Frame {
  position: fixed;
  //width : calc( 30% - 0px );
  //min-width:320px;
  width:320px;
  left:0px;
  top:40px;
  left: initial;
  background-color: rgba( $gn-white-color , 1);
  backdrop-filter: blur( 5px ) ;
  //border-right: 2px solid rgba( $gn-black-color , 1);
  //border-top: 2px solid rgba( $gn-black-color , 1);
  //box-shadow: 0px 0px 5px rgba( $gn-black-color , 0.5);
  border-right: 1px solid rgba( $gn-black-color , 0.1);
  display: flex;
  flex-direction: column;
  .Picker-Container {
    flex-grow: 1;
    background-color: rgba( $gn-white-color , 1);
    margin: 0px;
    width: 100%;
    height: 100%;
    max-width: none;
    display: flex;
    flex-direction: column;
    //backdrop-filter: blur(5px);
    .modal-content {
      background-color: transparent !important;
      //background-color: rgba( $gn-black-color , 0.25) !important;
      //backdrop-filter: blur( 4px );
      border-style: none;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      position: relative;
      overflow: hidden;
      .Picker-Header {
        position: sticky;
        background-color: rgba( $gn-black-color , 0.0);
        top:0px;
        backdrop-filter: blur(5px);
        z-index:10;
        color : rgba($gn-frame-color, 1);
        border-bottom: 0px solid  rgba($gn-frame-color, 1);
        //background-color: transparent;
        //border-bottom: 0px solid rgba( $gn-white-color , 0.25 );
        .close {
          line-height: 50px;
        }
        .Picker-Header-Frame {
          display: flex;
          flex-direction: row;
          .Picker-Header-Logo {
            float:left;
            width:35px;
            height:35px;
            /*padding : 2px 0px;*/
            margin: 7.5px 5px;
            border: 0px solid rgba($gn-white-color, 1);
            line-height: 35px;
            font-weight: 600;
            font-size: 30px;
            color : rgba($gn-frame-color,1);
          }
          .Fa {
            margin: auto 0.5rem;
            margin-left: 0rem;
            height:30px;
            width:30px;
            color: rgba($gn-frame-color,1);
            display: none;
          }
          .Picker-Header-Title {
            height:50px;
            /*padding : 2px 0px;*/
            margin: auto 0.5rem;
            color: rgba($gn-frame-color, 1);
            border-radius: 7.5px;
            -moz-border-radius:7.5px;
            -webkit-border-radius:7.5px;
            line-height: 50px;
            font-weight: bolder;
            font-size: 120%;
            cursor: default;
          }
          
        }
        .Back {
          margin: auto;
          margin-right: 0rem;
          font-size: 15px;
          color : rgba($gn-frame-color,1);
          transition: all 0.25s ease-in-out;
          padding:0.5rem 2.5rem;
          padding-right:0px;
          cursor: pointer;
          &:hover {
            color : rgba($gn-badge-color,1);
            transform: scale3d(1.2, 1.2, 1.2);
            //background-color: rgba( $gn-badge-color, 1 );
          }
        }
      }

      .Picker-Body {
        position: relative;
        z-index:5;
        //background-color: rgba( $gn-black-color , 0.25);
        overflow: auto;
        flex: 1;
        padding-bottom: calc( 2rem + 1rem - 2px );
        background-color: rgba( $gn-black-color , 0.0) !important;
        .Holder {
          flex-direction: column;
          display: flex;
          padding:1rem 1.25rem;
          transition: all 0.25s ease-in-out;
          &:hover {
            background-color: rgba($gn-white-color, 0.1);
            .Title {
              color : rgba($gn-black-color, 0.5); 
              //transform: scale3d(1.1, 1.1, 1.1) translatex(20px);
              //text-transform: uppercase;
            }
          }
          .Title {
            position: relative;
            font-weight: bolder;
            color : rgba($gn-black-color, 0.5);
            text-align: left;
            transition: all 0.25s ease-in-out;
            cursor: default;
            display: flex;
            flex-direction: row;
            .Text {
              font-size: 100%;
              margin: auto 0rem;
            }
            .Fa {
              margin: auto;
              margin-left: 0.5rem;
              font-size: 100%;
            }
            
          }
          .Regions {
            position: relative;
            //margin-right:0px !important;
            border-radius: 0.5rem !important;
            margin: 0.25rem 0px;
            margin-bottom: 0px;
            //margin-left : 1.5rem;
            margin-right : auto;
            background-color : transparent !important;
            //background-color: rgba($gn-badge-color, 0.5) !important;
            color : rgba($gn-white-color, 1);
            border: 0px solid rgba($gn-badge-color, 0.5) !important;
            font-weight: bolder;
            .btn {
              border-radius: 0.5rem !important;
              padding:0.375rem 1rem;
              font-weight: bolder;
              border-width: 0px;       
              display: flex;
              flex-direction: row;           
              .Scope {
                display: flex;
                flex-direction: row;
                font-size: 100%;
                font-weight: bolder;
                .Region {
                  margin:auto 0px;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  background-color: rgba( $gn-frame-color , 0);
                  height : fit-content;
                  .react-emojis {
                    font-size: 20px;
                    font-weight: 400;
                    margin: auto 0.5rem;
                    //margin-top: 0.5rem;
                  }
                }
              }
              &::after {
                margin: auto;
                margin-left: 0.5rem;
              }
              //display: flex;
              //flex-direction: row;
              //justify-content: center;
            }
            .dropdown-menu {
              .Line {
                display: flex;
                flex-direction: row;
                font-size: 100%;
                font-weight: bolder;
                padding : 1rem 0.5rem;
                width : calc( 100% - 1rem);
                border-bottom: 1px solid rgba( $gn-black-color, 0.1 );
                .Region {
                  margin:auto 0px;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  background-color: rgba( $gn-frame-color , 0);
                  height : fit-content;
                  .react-emojis {
                    font-size: 20px;
                    font-weight: 400;
                    margin: auto 0.5rem;
                    //margin-top: 0.5rem;
                  }
                }
              }
            }

          }
          .Box-Frame {
            display: flex;
            flex-direction: row;
            &.Type0 {
              margin : 1rem 0px;
              position: relative;
              .Box-Holder {
                flex-basis: auto;
                flex: auto;
                position: relative;
                .Box-Title {
                  position: absolute;
                  height:15px;
                  font-size: 75%;
                  font-weight: bolder;
                  bottom : -15px;
                  left:-1px;
                }
              }
              .Range {
                position: absolute;
                top:-21px;
                right:0px;
                font-size: 100%;
                color : rgba( $gn-black-color , 1 );
                background-color: rgba( $gn-badge-color , 1 );
                padding:0px 1rem;
                height:20px;
                border-top-left-radius : 0.5rem;
              }
            }
            .Box-Holder {
              display: flex;
              flex-direction: column;
              flex-basis: 16.667%;
              flex-shrink: 0;
              
              .Box-Title {
                font-size: 80%;
                color : rgba($gn-frame-color, 0.75);
                margin: 1rem auto;
                margin-bottom: 0rem;
                font-weight: bolder;
                cursor: default;
              }
              .Box {
                border: 1px solid rgba($gn-frame-color, 0.1);
                background: rgba( $gn-frame-color , 0.1 );
                transition: all 0.25s ease-in-out;
                height:30px;
                margin-right: -1px;
                &:hover {
                    background: rgba( $gn-frame-color , 0.5 );
                }
                &.selected {
                  background: rgba( $gn-frame-color , 1 );
                  box-shadow: 0px 0px 5px rgba( $gn-frame-color , 0.5 );
                  filter: blur(0.5px);
                }
              }
              &:first-child {
                .Box {
                  border-top-left-radius: 1rem;
                  border-bottom-left-radius: 1rem;
                }
              }
              &:last-child {
                .Box {
                  border-top-right-radius: 1rem;
                  border-bottom-right-radius: 1rem;
                }
              }
            }
          }
        }

      }

      .Picker-Footer {
        //position: sticky;
        //bottom: -100px;
        //z-index:10;
        //background-color: transparent;    
        position: sticky;
        z-index:10;
        background-color: rgba( $gn-black-color , 0.0);
        bottom: calc( 2rem + 1rem - 2px );
        backdrop-filter: blur(5px);
        color : rgba($gn-white-color, 1);
        //border-top: 1px solid rgba($gn-white-color, 1);
        display: flex;
        flex-direction: row-reverse;
        //justify-content: space-between;
        border-top: 0px solid rgba( $gn-black-color , 0.1 ) ;
        border-radius: 0px;
        padding: 0.5rem 2rem;
        .btn {
         border-radius: 0px; 
        }
        .search {
          //flex-basis: 40%;
          //flex-basis: calc( 40% - 4px);
          width: calc( 40% - 4px);
          margin-left: auto;
          background-color: rgba( $gn-frame-color, 1 ) !important;
          border: 2px solid rgba( $gn-frame-color, 1 ) !important;
          color: rgba( $gn-white-color, 1 ) !important;
          &:hover {
            background-color: rgba( $gn-badge-color, 1 ) !important;
            border: 2px solid rgba( $gn-badge-color, 1 ) !important;
            color: rgba( $gn-white-color, 1 ) !important;
          }
          border-top-right-radius: 1rem;
          border-bottom-right-radius: 1rem;
          
        }
        .reset {
          //flex-basis: 40%;
          width: calc( 40% - 4px);
          margin-left: auto;
          background-color: transparent !important;
          border: 2px solid rgba( $gn-frame-color, 1 ) !important;
          color: rgba( $gn-frame-color, 1 ) !important;
          
          border-top-left-radius: 1rem;
          border-bottom-left-radius: 1rem;
          &:hover {
            background-color: rgba( $gn-red-color, 1 ) !important;
            border: 2px solid rgba( $gn-red-color, 1 ) !important;
            color: rgba( $gn-white-color, 1 ) !important;
          }
        }
      }
    }
  }


}


