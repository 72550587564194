@import "../../public/style.scss";

.TemplateList {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width:100%;
  //border-right: 1px solid rgba( $gn-white-color, 0.25 );
  .panel {
    height: auto;
    padding: 1rem 0rem;
    flex-shrink: 0;
    flex-direction: column;
    display: flex;
    background-color: rgba( $gn-white-color , 0 );
    .btn-group {
      //margin: 25px 0px;
      //margin-top : 0px;
      height:40px;
      flex-direction: row;
      display: flex;
      .btn {
        font-size: 90%;
        padding:0.25rem 0.5rem;
        background-color: transparent !important;
        color : rgba($gn-black-color, 1);
        border-color:transparent !important;
        margin:0px 1px;
        opacity: 0.5;
        transition: background-color,opacity 0.25s ease-in-out;
        position: relative;
        z-index : 1;
        width: fit-content;
        &:hover {
          //background-color: rgba( $gn-frame-color, 0.75  ) !important;;
          opacity: 0.75;
        }
        &.active {
          opacity : 1;
          //background-color: rgba( $gn-frame-color,1  ) !important;
          transform: scale3d(1.1,1.1,1.1);
          border-bottom: 2px solid rgba( $gn-frame-color, 1  ) !important;
          z-index : 5
        }
      }
    }
    .Lower {
      display: flex;
      flex-direction: row;
      .Regions {
        position: relative;
        //margin-right:0px !important;
        border-radius: 0.5rem !important;
        margin: 0rem 0px;
        margin-left : 0.5rem;
        margin-right : auto;
        margin-top: 1.1rem;;
        background-color : transparent !important;
        //background-color: rgba($gn-badge-color, 0.5) !important;
        color : rgba($gn-white-color, 1);
        border: 0px solid rgba($gn-badge-color, 0.5) !important;
        font-weight: bolder;
        .btn {
          border-radius: 0.5rem !important;
          padding: 0.25rem 1em;
          font-weight: bolder;
          border-width: 0px;       
          display: flex;
          flex-direction: row;           
          .Scope {
            display: flex;
            flex-direction: row;
            font-size: 100%;
            font-weight: bolder;
            .Region {
              margin:auto 0px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              background-color: rgba( $gn-frame-color , 0);
              height : fit-content;
              .react-emojis {
                font-size: 20px;
                font-weight: 400;
                margin: auto 0.5rem;
                //margin-top: 0.5rem;
              }
            }
          }
          &::after {
            margin: auto;
            margin-left: 0.5rem;
          }
          //display: flex;
          //flex-direction: row;
          //justify-content: center;
        }
        .dropdown-menu {
          .Line {
            display: flex;
            flex-direction: row;
            font-size: 100%;
            font-weight: bolder;
            padding : 1rem 0.5rem;
            width : calc( 100% - 1rem);
            border-bottom: 1px solid rgba( $gn-black-color, 0.1 );
            .Region {
              margin:auto 0px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              background-color: rgba( $gn-frame-color , 0);
              height : fit-content;
              .react-emojis {
                font-size: 20px;
                font-weight: 400;
                margin: auto 0.5rem;
                //margin-top: 0.5rem;
              }
            }
          }
        }

      }
      .Add {
        font-size: 110%;
        background-color: rgba( $gn-frame-color, 1  ) !important;
        border: 0px solid rgba( $gn-badge-color, 0.5  ) !important;
        color : rgba( $gn-white-color, 1  );
        //border-style: none;
        padding:0.25rem 1em;
        border-radius: 0.5rem;
        min-height:25px;
        margin-top: 1rem;
        margin-left: auto;
        margin-bottom: 0px;
        margin-right: 0.5rem;
        font-weight: bolder;
        .Fa{
          font-size: 100%;
          width: 0.75rem;
          font-weight: bolder;
          margin-right: 0.75rem;
        }
        &:hover {
          color : rgba($gn-white-color, 1) !important;
          background-color: rgba( $gn-badge-color, 0.8  ) !important;
        }
      }
    }
  }
  .holder {
    border-top : 1px solid rgba( $gn-black-color, 0.1 );
    //height:100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex-wrap: nowrap;
    flex-grow: 1;
    padding-top: 10px;
    padding-bottom:100px;
    .item {
      width: calc(100% - 0.5rem);
      padding: 1rem 0.25rem;
      margin : 0px 0rem;
      background-color: rgba( $gn-white-color , 0.85 );
      border-bottom: 1px solid rgba( $gn-black-color , 0.1 );
      //box-shadow: 0 0px 5px 0 rgba(33, 33, 33 , 0.37);
      backdrop-filter: blur(2px);
      -webkit-backdrop-filter: blur(2px);
      display: flex;
      flex-direction: column;
      cursor: pointer;
      z-index : 1;
      position: relative;
      transition: all 0.25s ease-in-out;
      &.selected {
        position: sticky;
        z-index: 5;
        top:0.5rem;
        bottom:0.5rem;
        transform: scale3d(1.05, 1.05, 1.05);
        border-bottom: 1px solid rgba( $gn-frame-color , 1 );
        border-top: 1px solid rgba( $gn-frame-color , 1 );
      }
      &.noselected {
        filter: blur(2px);
      }
      .control {
        background-color: rgba( $gn-black-color , 0.00 );
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding:5px 0.5rem;
        text-align: left;
        .Region {
          margin:auto 0px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          background-color: rgba( $gn-frame-color , 0);
          height : fit-content;
          .react-emojis {
            font-size: 20px;
            font-weight: 400;
            margin: auto 0.5rem;
            //margin-top: 0.5rem;
          }
        }
        .badge {
          padding : 0.25rem 0.5rem;
          margin : 0px 0rem;
          margin-right: 0.5rem;
          color : rgba($gn-white-color, 1);
          background-color: transparent!important;
          &.public {
            //background-color: rgba( $gn-green-color , 0.25 );
            color : rgba( $gn-green-color , 1 );
            border: 2px solid rgba( $gn-green-color , 1 );
          }
          &.private {
            //background-color: rgba( $gn-white-color , 0.25 );
            color : rgba( $gn-black-color , 0.75 );
            border: 2px solid rgba( $gn-black-color , 0.75 );
          }
        }
        .remove {
          background-color: rgba( $gn-red-color, 0.5 ) !important;
          width:10px;
          padding:0px 0.5rem;
          margin-left: auto;
          border-style: none;
          border-radius: 0px;
          &:hover {
            background-color: rgba( $gn-red-color, 1 )!important;
          }
          .fa {
            color : rgba($gn-white-color, 1);
            font-size: 90%;
          }
        }
      }
      .upper {
        background-color: rgba( $gn-black-color , 0.00 );
        display: flex;
        flex-direction: row;
        font-size: 100%;
        justify-content: flex-start;
        padding:5px 0.5rem;
        //padding-bottom:0px;
        text-align: left;
        span{ 
          font-size: 110%;
          color : rgba($gn-black-color, 0.8);
          font-weight: bolder;
        }
      }
      .middle{
        color : rgba($gn-frame-color, 1);
        background-color: rgba( $gn-white-color , 0.025 );
        font-size: 80%;
        padding:0px 0.5rem;
        text-align: left;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        font-weight: lighter;
        .badge {
          padding : 0.25rem 0.5rem;
          margin : 0px 0rem;
          margin-right: 0.5rem;
          //background-color: rgba( $gn-badge-color , 0.25 );
          color : rgba($gn-white-color, 1);
          &.public {
            background-color: rgba( $gn-green-color , 0.25 );
          }
          &.private {
            background-color: rgba( $gn-white-color , 0.25 );
          }
        }
        span {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
      .lower {
        background-color: rgba( $gn-black-color , 0.05 );
        color : rgba($gn-white-color, 1);
        display: flex;
        flex-direction: row;
        //padding-top:0px;
        font-size: 75%;
        justify-content: flex-start;
        padding:5px 0.5rem;
        text-align: left;
        .desc {
          height:25px;
          //filter: blur(2px);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          &.show {
            height: fit-content;
            white-space: break-spaces;
            margin: 0.5rem 0px;
          }
        }
      }
      .panel {
        background-color: rgba( $gn-black-color , 1 );
        color : rgba($gn-badge-color, 1);
        display: flex;
        flex-direction: column;
        font-size: 80%;
        justify-content: flex-start;
        padding:10px 0rem;
        margin-bottom: 10px;
        .form-group {
          .form-control {
            background-color: rgba( $gn-white-color , 0.1 );
            color : rgba($gn-white-color, 1);
            border-radius: 0px;
            border-style: none;
            margin:0px 0.75rem;
            width:calc(100% - 3rem);
          }
        }
        .btn {
          margin-left:auto;
          margin-right: 1rem;
          background-color: rgba( $gn-white-color , 0.25 ) !important;
          box-shadow: 0 0px 5px 0 rgba(33, 33, 33 , 0.37) !important;
          backdrop-filter: blur(2px);
          -webkit-backdrop-filter: blur(2px);
          color : rgba($gn-white-color, 1);
          border-radius: 0px;
          border-style: none;
          &:hover {
            background-color: rgba( $gn-badge-color , 0.25 ) !important;
          }
        }

      }
      &:hover {
        background-color: rgba( $gn-badge-color , 0.1 );
        filter: blur(0px);

      }
    }
  } 
}

@media screen and (max-width: 750px)  {
  .Botfolio{

  }
}