@import "../../public/style.scss";


.Confirm-Frame {
  .Confirm-Btn {
    color : rgba($gn-white-color, 1);
  }
}


