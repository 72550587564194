@import "../../../../public/style.scss";

.trade-record-2 {
  //border-bottom: 1px solid rgba($gn-light-color, 0.05);
  //height:30px;
  //width:calc(100% - 1rem);
  //line-height: 30px;
  //padding:5px 0.5rem;
  //display: flex;
  //flex-direction: row;
  //cursor: pointer;
  //font-size: 90%;
  //justify-content: space-between;
  flex-direction: column;
  display: flex;
  position: relative;
  z-index : 1;
  margin: 0.25rem 0px;
  //border-top: 1px solid rgba( $gn-black-color , 0.05);
  border-bottom: 1px solid rgba( $gn-black-color , 0.025);
  //background-color: rgba( $gn-black-color , 0.05);
  transition: all 0.25s ease-in-out;
  &:hover {
    background-color: rgba($gn-white-color, 0.1);
  }
  .trade-name {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin:0rem 0.5rem;
    margin-top: 0.5rem;
    font-size: 80%;
    color : rgba( $gn-frame-color , 0.75);
    font-weight: bolder;
  }

  &.tc-title {
    background-color: rgba( $gn-black-color , 0.01);
    backdrop-filter: blur(10px);
    position: sticky;
    top:0px;
    z-index: 999;    
  }
  .trade-list {
    border-bottom: 2px solid rgba($gn-light-color, 0.00);
    //margin : 1px 0px;
    height:30px;
    width:calc(100% - 1rem);
    line-height: 30px;
    padding:5px 0.5rem;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    font-size: 90%;
    justify-content: space-between;
    transition: all 0.25s ease-in-out;
    //background-color: rgba( $gn-white-color , 0.01);
    

    .box {
      border-left : 0px solid rgba($gn-light-color, 0.05);
      text-align: center;
      flex-basis: calc( 100% / 3 - 50px / 3 );
      width: calc( 100% / 3 - 50px / 3 );
      height:30px;
      //line-height: 20px;
      font-size: 12.5px;
      flex-direction: row;
      display: flex;
      justify-content: space-evenly;
      flex-shrink: 0;
      color : rgba( $gn-white-color, 1 );
      &:first-child {
        border-left : 0px solid rgba($gn-light-color, 0.05);
      }
      &.bs {
        width:20px !important;
        height: 20px;
        margin: auto 0px;
        color : rgba($gn-white-color, 1);
        font-weight: bolder;
        padding:0px;
        flex-basis: auto !important;
        line-height: normal;
        flex-direction: column;
        display: flex;
        justify-content: space-around;
        border-radius: 50%;
        &.buy {
          flex-basis: 66%;
          background-color: rgba($gn-green-color, 1);
        }
        &.sell {
          flex-basis: 66%;
          background-color: rgba($gn-red-color, 1);
        }
        .smaller {
          &.paper {
            background-color: rgba($gn-black-color, 0.25) ;
            color : rgba($gn-white-color, 0.5);
          }
          &.real {
            background-color: rgba($gn-badge-color, 0.5) ;
            color : rgba($gn-black-color, 0.5);
          }
          flex-basis: 34%;
          font-size: 50%;
        }
      }
      &.type {
        margin-right: 0.25rem;
        width:30px !important;
        color : rgba($gn-white-color, 1);
        font-weight: lighter;
        padding:0px;
        flex-basis: auto !important;
        line-height: normal;
        flex-direction: column;
        display: flex;
        justify-content: space-around;
        transition: background-color 0.25s ease-in-out;
        &:hover {
          background-color: rgba($gn-frame-color, 0.5) !important;
        }
        &.paper {
            background-color: rgba($gn-white-color, 0.25) ;
            color : rgba($gn-white-color, 0.8);
        }
        &.real {
            background-color: rgba($gn-badge-color, 0.75) ;
            color : rgba($gn-white-color, 0.8);
        }
      }
      &.symbol {
        color : rgba($gn-black-color, 1);
        //flex-direction: column;
        //display: flex;
        //justify-content: center;
        //margin: 0px 1rem;
        flex-shrink: 0;
        font-size: 90%;
        .smaller {
          flex-basis: 34%;
          font-size: 70%;
          color : rgba($gn-black-color, 0.5);
        }
      }
      &.control {
        width:20px;
        flex-basis: auto;
        color: rgba($gn-frame-color-2, 1) ;
        text-align: center;
        transition: all 0.25s ease-in-out;
        
        margin: auto 0px;
        &.title {
          background-color: transparent !important;
        }
        .icon {
          padding:5px;
          height:10px;
          width:10px;
          font-size: 100%;
          border-radius: 50%;
          border: 1px solid rgba($gn-frame-color-2, 1) ;
        }
        &:hover {
          //background-color: rgba($gn-red-color, 0.8) ;
          transform: scale3d(1.2, 1.2, 1.2,);
        }
      }
      .arrow {
        //margin: 0px auto;
        height: 100%;
        padding: 0px 2.5px;
        color: rgba( $gn-black-color, 0.8 );
        transition: all 0.25s ease-in-out;
        font-size: 100%;
        //background-color : rgba( $gn-white-color, 0.2 );
        &:hover {
          color: rgba( $gn-accent-color, 1 );
          //background-color : rgba( $gn-frame-color, 0.5 );
        }
      }
      .holder {
        width: 100%;
        flex-direction: column;
        display: flex;
        justify-content: center;
        //margin: auto;
        .board_lot, .date, .buy {
          color : rgba( $gn-black-color ,0.5);
          line-height: normal;
          font-size: 80%;
          font-weight: bolder;
          margin: auto;
          white-space: pre;
          width: 90%;
          text-overflow: ellipsis;
          //margin: 0px 1rem;
          .control {

          }
        }
        .total-cost, .price {
          color : rgba($gn-black-color, 0.8);
          line-height: normal;
          font-size: 110%;
          margin: auto;
          white-space: pre;
          width: 90%;
          text-overflow: ellipsis;
          display: flex;
          flex-direction: row;
          justify-content: center;
          font-weight: bolder;
          &.up {
            color : rgba($gn-green-color, 1) !important;
            background-color: transparent !important;
          }
          &.down {
            color : rgba($gn-red-color, 1) !important;
            background-color: transparent !important;
          }
          .smaller {
            font-size: 70%;
            margin: auto 0rem;
            margin-left: 0.25rem;
          }
          //margin: 0px 1rem;
        }
        .symbol {
          color : rgba($gn-black-color, 1);
          flex-direction: column;
          display: flex;
          justify-content: center;
          line-height: normal;
          margin: auto;
          white-space: pre;
          width: 90%;
          font-size: 100%;
          text-overflow: ellipsis;
          font-weight: bolder;
          //margin: 0px 1rem;
        }
        .name {
          //transform: scale3d(0.75, 0.75, 0.75);
          color : rgba( $gn-black-color ,0.5);
          line-height: normal;
          font-weight: lighter;
          font-size: 70%;
          overflow: hidden;
          margin: auto;
          white-space: pre;
          width: 90%;
          text-overflow: ellipsis;
        }
      }
      &.action {
        width: calc( 100% / 3 - 80px / 3 );
        flex-basis: calc( 100% / 3 - 80px / 3 );
      }
    }
  }
  .trade-board {
    height:80px;
    background-color: rgba($gn-black-color, 0.25);
    transition: visibility,height 0.25s ease-in-out;
    visibility : hidden;
    height:0px;
    overflow: hidden;
    flex-direction: column;
    display: flex;
    justify-content: center;
    .title {
      font-size: 75%;
      color : rgba( $gn-light-color, 0.8 );
    }
    .dropdown {
      margin : 0px 0rem;
      //margin-top:0px;
      .dropdown-toggle {
        background-color: rgba($gn-white-color, .1 ) !important;
        color : rgba($gn-white-color, 1) ;
        border-radius: 0px;
        margin-top: 0px;
        //border-style: none;
      }
    }
  }

  &:hover {
    z-index : 1001;
    .trade-board {
      visibility : visible;
      height:80px;
      overflow:initial;
      
    }
  }
}

