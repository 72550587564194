@import "../../public/style.scss";

.terms-container {
  background-color: rgba( $gn-white-color, 1 );
  float:left;
  position: relative;
  top:0px;
  left:0px;
  right:0px;
  bottom:0px;
  overflow: auto;
  height : calc( 100vh - 40px);
}

.terms-controller {
  padding-top:10px;
  padding-bottom:10px;
  margin-bottom: 0px;
  width : 100%;
  line-height: 40px;
  height: 40px;
  text-align: left;
}

.terms-button {
  float : left;
  height:40px;
  line-height : 40px;
  padding : 0px 20px;
  margin-left: 10px;
  cursor: pointer;
  text-decoration: none;
}

.terms-button:hover {
  text-decoration: none;
}

.support-frame {
  position: absolute;
  top:60px;
  left:0px;
  width : 50%;
  bottom: 0px;
  z-index : 5;
}

.support-content {
  position: absolute;
  top:60px;
  left:0px;
  right : 0px;
  bottom: 0px;
  z-index : 10;
}

.support-container {
  position: relative;
  float: right;
  width : 80%;
  margin: 0px 10%;
  margin-top: 60px;
  max-width: 600px;
  height:500px;
}

.support-container-title {
  font-size: 30px;
  float:right;
  width:100%;
  text-align: right;
  line-height: 60px;
  height : 60px;
}

.support-container-input {
  font-size: 14px;
    float: right;
    width: 100%;
    width: calc( 100% - 20px );
    text-align: left;
    line-height: 40px;
    height: 40px;
    margin: 5px 0px;
    padding: 00px 10px;
    border-width: 2px;
    border-style: solid;
}

.support-container-textarea {
  font-size: 14px;
  float:right;
  width:calc( 100% - 20px );
  text-align: left;
  height : calc( 100vh - 390px);
  background-color: rgba( $gn-white-color, 1 );
  border-width: 2px;
  padding:10px;
}

.support-container-button {
  float : right;
  margin-top: 5px;
  padding : 0px 30px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
}




@media (max-width : 751px) {
  .support-frame {
    position: absolute;
    top:60px;
    left:0px;
    width : 100%;
    bottom: 0px;
  }
}