@import "../../public/style.scss";

.Trade-Log {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  .Control {
    height: calc( 60px + 20px );
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding:10px 0.5rem;
    //border-bottom: 1px solid rgba( $gn-white-color, 0.1 );
    cursor: default;
    .Sum {
      display: flex;
      flex-direction: column;
      text-align: left;
      .Title {
        color : rgba( $gn-black-color , 0.5) ;
        font-size: 60%;
      }
      .Value {
        color : rgba( $gn-black-color , 0.8) ;
        font-size: 200%;
        font-weight: lighter;
      }
    }
    .Btn {
      border-radius: 0.25rem;
      background-color: rgba( $gn-frame-color, 1 )!important;
      //border-color: rgba( $gn-badge-color, 0.5 ) !important;
      //border-style: none;
      border-width: 0px;
      width:fit-content;
      margin-left: auto;
      font-size: 100%;
      color : rgba( $gn-white-color, 1 );
      font-weight: bolder;
      .Fa {
        font-size: 80%;
        margin-right: 0.5rem;
        color : rgba( $gn-white-color, 1 );
      }
      &:hover {
        background-color: rgba( $gn-badge-color, 1 ) !important;
        color : rgba($gn-white-color, 1);
      }
    }
  }
  .Header {
    background-color: rgba( $gn-frame-color, 1 ) ;
    color : rgba( $gn-white-color, 1 ) ;
    padding : 0.25rem 1rem;
    cursor: default;
    height: 40px;
    line-height: 40px;
    font-weight: bolder;
  }
  .Holder {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;
    padding:2rem 0.5rem;
    
    .toast {
      border-bottom : 1px solid rgba( $gn-black-color, 0.1 ) ;
    }
    .Msg {
      color: rgba( $gn-black-color, 0.8);
      font-weight: bolder;
      font-size: 120%;
      cursor: default;
    }
  }
}

@media screen and (max-width: 750px)  {
  .Trade-Log {

  }
}