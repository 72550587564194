@import "../../public/style.scss";

.M-Login {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: fixed;
  top:0px;
  left:0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba( $gn-black-color , 0.8 );
  .carousel {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    .carousel-inner {
      width: 100%;
      height: 100%;
      //display: flex;
      //flex-direction: column;
      .carousel-item {
        //position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .cover {
          width:100%;
          height: 100%;
          position: absolute;
          top:0px;
          left : 0px;
          background-color: rgba( $gn-black-color , 0.25 );
          opacity: 0.8;
          background-image: radial-gradient(rgba( $gn-light-color , 0.5 ) 0.5px, rgba( $gn-black-color , 0.25 ) 0.5px);
          background-size: 10px 10px;
        }

        img {
          opacity: 1;
          margin: auto;
          width: 90% !important;
          border-radius: 0.5rem;
          box-shadow: 0px 0px 5px rgba( $gn-black-color , 0.5 );
        }
        //width: 100%;
        //height: 100%;
      }
    }
  }
}

@media screen and (max-width: 750px)  {
  .M-Login {

  }
}


@media (orientation: portrait) {
  .M-Login {
    .carousel {
      .carousel-inner {
        .carousel-item {
          //justify-content: flex-end;
          .cover {
          }
          img {
            //transform: rotate(90deg);
            //height:100vw;
            //width:auto !important;
          }
        }
      }
    }
  }
}