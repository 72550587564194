@import "../../public/style.scss";


.Activities-Log {
    transition: all 0.25s ease-in-out;
    opacity: 0.8 !important;
    cursor: pointer;
    margin-bottom: 0.5rem!important;
    box-shadow: 0px 0px 0px transparent !important;
    flex-basis: auto;
    border-width: 0px;
    position: relative;
    z-index :1;
    background-color: rgba( $gn-white-color, 0.5 );
    backdrop-filter: blur(4px);
    margin: 0rem auto;
    width: calc( 100% - 0.5rem);
    &.dark {
      background-color: rgba( $gn-frame-color, 0.1 );
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(2px);
      color : $gn-white-color;
      border-radius: 1rem;
      overflow: hidden;
      .toast-header {
        background-color : rgba($gn-frame-color,0.8);
        color : rgba($gn-black-color, 1);
        .buy, .sell {
          display: none;
        }
        .title {
          color : rgba($gn-white-color, 1);
          .name {
            color : rgba($gn-white-color, 1);
          }
        }
        .date {
          color : rgba($gn-black-color, 0.5);
        }
      }
      .Activities-Log-Body {
        font-size: 80%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        .profit {
          color : rgba($gn-black-color, 1);
        }
        .Value {
          color : rgba($gn-black-color, 1);
          font-weight: bolder;
        }
        .Price {
          font-size: 120%;
          font-weight: bolder;
          line-height:20px;
          
        }
      }
      .Activities-Log-Value {
        padding:5px 1rem;
        flex-direction: row;
        display: flex;
        justify-content:flex-start;
        height:20px;
        .Badge {
          background-color: transparent;
          //background-color: rgba($gn-badge-color, 0.25);
          font-size: 90%;
          padding:0px 0.5rem;
          line-height:20px;
          font-size: 80%;
          border-radius: 0.25rem;
          border-width: 1px;
          border-style: solid;
          &.paper {
            border-color : rgba($gn-black-color, 0.75);
            color: rgba($gn-black-color, 0.75);
          }
          &.real {
            border-color : rgba($gn-frame-color, 0.75);
            color: rgba($gn-frame-color, 0.75);
          }
        }
        .profit {
          font-size: 100%;
          color : rgba($gn-black-color, 0.5);
          margin-left: auto;
          margin-right: 0.5rem;
          line-height:20px;
          &.up {
            background-color: transparent!important;
            color : rgba($gn-green-color, 1) !important;
          }
          &.down {
            //background-color: rgba($gn-red-color,1);
            //color : rgba($gn-white-color, 1);
            background-color: transparent!important;
            color : rgba($gn-red-color, 1)!important;
          }
        }
        .Price {
          font-size: 90%;
          //font-weight: bolder;
          line-height:20px;
          color : rgba($gn-black-color, 1);
        }
      }
    }
    .toast-header {
      transition: all 0.25s ease-in-out;
      background-color : rgba($gn-white-color,0.5);
      color : rgba($gn-black-color, 1);
      padding:2.5px .75rem
    }
    .header {
      transition: all 0.25s ease-in-out;
    }
    &:hover {
      z-index : 99;
      //border-top: 2px solid rgba( $gn-black-color, 0.1) !important;
      //border-bottom: 2px solid rgba( $gn-black-color, 0.1) !important;
      //background-color : rgba($gn-black-color,0.75);
      //box-shadow: 0px 0px 5px rgba($gn-black-color, 0.25) !important;
      //transform: translate3d(-5px, -5px, -5px);
      transform: scale3d(1.05, 1.05, 1.05);
      opacity: 1 !important;
      //box-sha
      .header {
        //background-color : rgba($gn-frame-color,1);
        //color : rgba($gn-white-color, 1);
        .title {
          .name {
            //color : rgba($gn-white-color, 1);
          }
        }
        .date {
          //color : rgba($gn-white-color, 0.8);
        }
      }
      .strategy-name {
        background-color : rgba($gn-black-color,0.75);
        color : rgba($gn-white-color, 0.8);
      }
    }

    .theader {
      border-bottom: 0px;
    }
    .buy {      
      //border-bottom: 0px;
      background-color: rgba($gn-green-color, 1);
      padding:0rem 0.25rem;
      margin-right: 0.25rem;
      opacity: 0.9;
      color : rgba($gn-white-color, 1);
      font-weight: bold;
    }
    .sell {
      //border-bottom: 0px;
      background-color: rgba($gn-red-color, 1);
      padding:0rem 0.25rem;
      margin-right: 0.25rem;
      opacity: 0.9;
      color : rgba($gn-white-color, 1);
      font-weight: bold;
    }
    .title {
      //background-color : rgba($gn-dark-color, 1);
      //color : rgba($gn-white-color, 1);
      font-size: 120%;
      font-weight: normal;
      transition: all 0.25s ease-in-out;
      span {
        transition: all 0.25s ease-in-out;
      }
      .name {
        margin-left: 0.25rem;
        font-size: 75%;
        color : rgba( $gn-dark-color, 0.8) ;
        transition: all 0.25s ease-in-out;
      }
    }
    .date {
      color : rgba( $gn-black-color, 0.5);
    }
    .strategy-name {
      transition: all 0.25s ease-in-out;
      background-color: rgba( $gn-light-color, 1 );
      color: rgba($gn-black-color, 1) ;
      font-weight: normal;
      font-size: 75%;
      border-radius: 0px;
      padding: 1px .75rem;
    }
    .Activities-Log-Body {
      padding: 5px .25rem 5px 0.75rem;
      text-align: right;
      .Activities-Log-Name {
        text-align: left;
      }
      .powered {
        font-size: 70%;
        font-weight: bold;
        float: left;
        line-height: 20px;
      }
      span {
        margin: 0px 2.5px;
      }
      .Activities-Log-Green {
        color : rgba($gn-green-color, 1);
      }
      .Activities-Log-Red {
        color : rgba($gn-red-color, 1);
      }
      .price {
        color : rgba($gn-dark-color, 1);
        border: 1px solid rgba($gn-dark-color, 1);
        font-size: 150%;
        padding:5px;
      }
      .profile {
        float:left;
      }
      .profit {
        //border: 1px solid rgba($gn-dark-color, 1);
        font-size: 120%;
        //padding:5px;
        color : rgba($gn-black-color, 0.5);

        &.up {
          //background-color: rgba($gn-green-color, 1);
          //color : rgba($gn-white-color, 1);
          background-color: transparent!important;
          color : rgba($gn-green-color, 1) !important;
          font-size: 120%;
          font-weight: bolder;
        }
        &.down {
          //background-color: rgba($gn-red-color, 1);
          //color : rgba($gn-white-color, 1);
          background-color: transparent!important;
          color : rgba($gn-red-color, 1)!important;
          font-size: 120%;
          font-weight: bolder;
        }
      }

      .Value {
        //margin-top: 0.25rem;
        margin: auto 0.25rem;
        //border-top: 1px solid rgba($gn-black-color, 0.25);
        font-size: 175%;
        font-weight: lighter;
        //line-height:20px;
      }
      .Percent {
        margin: auto 0.25rem;
        font-size: 80%;
        font-weight: bolder;
        //line-height:20px;
        &.Up {
          background-color :rgba($gn-green-color, 1);
          padding: 0.25rem 0.5rem;
          border-radius: 1rem;
          color : rgba( $gn-white-color, 0.8 );
        }
        &.Down {
          background-color :rgba($gn-red-color, 1);
          padding: 0.25rem 0.5rem;
          border-radius: 1rem;
          color : rgba( $gn-white-color, 0.8 );
        }
      }

    }
}

@media screen and (max-width: 750px)  {
  .Activities-Log {
    width : 100%;
    max-width: 100%;
    .Activities-Log-Body {
      text-align: right;
      padding:.25rem .75rem;
      .Activities-Log-Name {
        display: none;
      }
      span {
        margin: 0px 0px;
      }
      .Activities-Log-Green {
        color : rgba($gn-green-color, 1);
      }
      .Activities-Log-Red {
        color : rgba($gn-red-color, 1);
      }
    }
  }
}
