@import "../../public/style.scss";

.Profile {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    //background-color: rgba($gn-frame-color, 0.8);
    //box-shadow: -1px 0px 5px rgba($gn-black-color, 0.25);
    overflow: hidden;
    //padding-bottom: 80px;
    backdrop-filter: blur(10px);
    padding-bottom: 0px;

    .UFrame {
        background: -moz-linear-gradient(180deg, rgba( $gn-frame-color , 0.75 ) 0%, rgba( $gn-accent-color ,0.25) 100%);
        background: -webkit-linear-gradient(180deg, rgba( $gn-frame-color , 0.75 ) 0%, rgba( $gn-accent-color ,0.25) 100%);
        background: linear-gradient(180deg, rgba( $gn-frame-color , 0.75 ) 0%, rgba( $gn-accent-color ,0.25) 100%);
        top:0px;
        left:0px;
        right:0px;
        bottom:0px;
        position: absolute;
        z-index:-1;
        overflow: hidden;
        filter: blur(1px);
        //backdrop-filter: blur(5px);
        .Circle {
            background-color: rgba( $gn-frame-color , 0.1 );
            border-radius: 50%;
            position: absolute;
            //backdrop-filter: blur(10px);
        }
    }

    .Holder {
        overflow: auto;
        position: relative;
        z-index : 1;
        display: flex;
        flex-direction: column;
        top:0px;
        left:0px;
        right:0px;
        bottom:0px;
        flex-grow: 1;
        .User {
            position: relative;
            display: flex;
            flex-direction: row;
            margin:0rem 0rem;
            padding:2rem 1rem;
            height:80px;
            transition: all 0.25s ease-in-out;
            box-shadow: 0px 0px 5px rgba($gn-black-color, 0);
            &:hover {
                background-color: rgba($gn-white-color, 0.25);
                box-shadow: 0px 0px 5px rgba($gn-white-color, 0.5);
                .Pic {
                    transform: scale3d(1.2, 1.2, 1.2);
                    margin-left: 5%;
                }
                .Edit {
                    opacity: 1;
                    width: fit-content;
                    left: -5px;
                }
                .Upgrade {
                    transform: scale(1.1) translate(5px,2px);
                }
            }
            .Upgrade {
                transition: all 0.25s ease-in-out;
                position: absolute;
                top: 0px;
                left:-2px;
                border-top-right-radius: 1rem;
                border-bottom-right-radius: 1rem;
                font-size: 80%;
                cursor: pointer;
                font-weight: bolder;
                background-color: rgba( $gn-accent-color ,0.9);
                backdrop-filter: blur(5px);
                color: rgba( $gn-black-color ,1);
                padding: 0.25rem 1rem;
                display: flex;
                flex-direction: row;
                z-index: 5;
                &:hover {
                    background-color: rgba( $gn-frame-color-2 , 1 );
                    color: rgba( $gn-white-color ,1);
                }
            }
            .Pic {
                flex-shrink: 0;
                width:80px;
                height:80px;
                margin: auto 0rem;
                display: flex;
                flex-direction: column;
                transition: all 0.25s ease-in-out;
                position: relative;
                .Crown {
                    position: absolute;
                    left:55px;
                    width:30px;
                    height:30px;
                    top:-16px;
                    transform: rotate(35deg);
                    color : rgba( $gn-accent-color,1 );
                }
                .Wrapper {
                    flex-grow: 1;
                    width:calc( 100% - 8px );
                    height: 100%;
                    border-radius: 50%;
                    border:4px solid rgba($gn-white-color, 1);
                    background-color: rgba($gn-white-color, 1);
                    display: flex;
                    flex-direction: column;
                    overflow: hidden;
                    .Img {
                        width : 100%;
                    }
                    .Fa {
                        width:60%;
                        height:60%;
                        margin: auto;
                        color : rgba($gn-frame-color, 1);
                    }
                    &.Professional {
                        border:4px solid rgba($gn-accent-color, 1);
                        //background-color: rgba($gn-accent-color, 1);
                    }
                }
            }
            .PDetail {
                display: flex;
                flex-direction: column;
                margin-left: 1rem;
                flex-grow: 1;
                .Name {
                    margin-top: 0.5rem;
                    display: flex;
                    flex-direction: row;
                    .First {
                        font-size: 120%;
                        color : rgba($gn-white-color, 1);
                        font-weight : bold;
                        text-align: left;
                    }
                    .Level {
                        font-size: 100%;
                        margin: auto 0rem;
                        margin-left: auto;
                        color : rgba($gn-black-color, 1);
                        text-align: left;
                        font-weight : bolder;
                        background-color: rgba($gn-white-color, 1);
                        padding:0px 1rem;
                        flex-shrink: 0;
                        border-radius: 1rem;
                    }
                }
                .Exp {
                    display: flex;
                    flex-direction: column;
                    .Wrapper {
                        margin:0.5rem 0rem;
                        border: 2px solid rgba($gn-white-color, 1);
                        background-color: rgba($gn-white-color, 1);
                        height:10px;
                        width: auto;
                        overflow: hidden;
                        position: relative;
                        border-radius: 1rem;
                        .Bar {
                            transition: all 0.25s ease-in-out;
                            background-color: rgba($gn-black-color, 0.5);
                            //filter: blur(5px);
                            position: absolute;
                            top:0px;
                            right:0px;
                            bottom: 0px;
                        }
                    }
                    .Next {
                        font-size: 80%;
                        color : rgba($gn-black-color, 0.75);
                        text-align: right;
                        //font-style: italic;
                        font-weight: lighy;
                    }
                }
            }
            .Edit {
                transition: all 0.25s ease-in-out;
                opacity: 0;
                position: absolute;
                bottom: -2.5px;
                flex-direction: row;
                display: flex;
                background-color: rgba( $gn-accent-color,1 ) !important;
                border-color: rgba( $gn-accent-color,1 ) !important;
                padding:0.25rem 0.5rem;
                //box-shadow: 0px 0px 5px rgba($gn-black-color, 0.5);
                width:fit-content;
                border-radius: 1rem;
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
                //overflow: hidden;
                left:-100px;
                .Fa {
                    width:15px;
                    height:15px;
                    padding: 0rem 0.5rem;
                    color : rgba( $gn-black-color , 1 )
                }
                .Text {
                    font-size: 80%;
                    padding: 0rem 1rem;
                    font-weight: bolder;
                    color : rgba( $gn-black-color , 1 )
                }
            }
        }
        
        .Tab {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            .Control {
                display: flex;
                flex-direction: row;
                margin-bottom: 0.5rem;
                .Select {
                    padding: 0.25rem 1rem;
                    font-size: 85%;
                    color :rgba( $gn-black-color ,0.25);
                    cursor: pointer;
                    font-weight: bold;
                    &.Selected {
                        border-bottom: 2px solid rgba( $gn-accent-color,1 );
                        color :rgba( $gn-black-color ,1);
                    }
                }
            }
            .Cont {
                display: flex;
                flex-direction: column;
                &.Performance {
                    .Onhold {
                        display: flex;
                        flex-direction: column;
                        height: auto;
                        background-color: rgba( $gn-white-color, 0.5 );
                        border-radius: 1rem;
                        margin: 1rem 1.5rem;
                        padding:1rem 0rem;
                        cursor: default;     
                        .Header {
                            background-color: rgba($gn-frame-color, 1);
                            color : rgba($gn-white-color, 1);
                            font-size: 100%;
                            font-weight: bolder;
                            padding: 0.25rem 1.5rem;
                            margin: auto;
                            margin-bottom: 0.5rem;
                            border-radius: 1rem;
                            width: fit-content;
                        }
                        .Holder{
                            display: flex;
                            flex-direction: column;    
                            &.Row-Holder {
                                flex-direction: row;
                                padding-bottom: 0.5rem;
                                flex-wrap: wrap;
                            }
                            .Wrapper{
                                display : flex;
                                flex-direction: column;
                                padding: 0.5rem 0rem;
                                &:Hover{
                                    .Title {
                                        transform: scale3d(1.25, 1.25, 1.25);
                                        color : rgba($gn-black-color, 0.75);
                                    }
                                }
                                .Title {
                                    transition: all 0.25s ease-in-out;
                                    font-size: 80%;
                                    color : rgba($gn-black-color, 0.5);
                                    font-weight: bolder;
                                }
                                .Value {
                                    font-size: 300%;
                                    color : rgba($gn-black-color, 0.75);
                                    font-weight: bolder;
                                    margin-top: 0.5rem;
                                    line-height: initial;
                                    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                                }
                                .Change {
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: center;
                                    text-align: center;
                                    //font-size: 120%;
                                    color : rgba($gn-black-color, 0.5);
                                    margin:auto;
                                    margin-top:-0.0rem;
                                    padding:0rem 1rem;
                                    padding-bottom: 0.25rem;
                                    font-weight: border;
                                    width: 80%;
                                    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                                    //font-weight: bold;
                                    &.Gain {
                                        color : rgba($gn-green-color, 1);
                                        .Amount  {
                                            //background-color : rgba($gn-green-color, 1);
                                            //color : rgba($gn-green-color, 1);
                                        }
                                        .Percent {
                                            background-color : rgba($gn-green-color, 1);
                                            color : rgba( $gn-white-color, 1 );
                                        }
                                    }
                                    &.Lost {
                                        color : rgba($gn-red-color, 1);
                                        .Percent, .Amount   {
                                            //background-color : rgba($gn-red-color, 1);
                                            //color : rgba($gn-red-color, 1);
                                        }
                                        .Percent {
                                            background-color : rgba($gn-red-color, 1);
                                            color : rgba( $gn-white-color, 1 );
                                        }
                                    }
                                    .Amount {
                                        font-size: 100%;
                                        margin-left: auto;
                                        margin-right: 0rem ;
                                        font-weight: bolder;
                                        padding: 0rem 0.25rem;
                                        padding-right: 0.25rem;
                                        color : rgba($gn-black-color, 1);
                                    }
                                    .Percent {
                                        font-size: 100%;
                                        //margin: auto;
                                        margin-left: 0.5rem;
                                        margin-right: auto ;
                                        //opacity: 0.8;
                                        height: auto;
                                        padding:0rem 1rem;
                                        font-weight: bolder;
                                        //padding-right: 1rem;
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: center;
                                        border-radius : 1rem;
                                    }
                                }
                                &.Half {
                                    width: calc( 50% - 1rem);
                                    margin: 0px 0.5rem;
                                    .Value {
                                        font-size: 150%;
                                        background-color: rgba($gn-black-color, 0.5) ;
                                        color : rgba($gn-white-color, 1);
                                        border-radius: 0.25rem;
                                        padding: 0.25rem 0rem;
                                        margin-bottom: 0.25rem;
                                        border-radius: 1rem;
                                        //border: 2px solid rgba($gn-white-color, 1) ;
                                        &.Gain {
                                            //color : rgba($gn-green-color, 1);
                                            background-color: rgba($gn-green-color,0.8) ;
                                        }
                                        &.Lost {
                                            //color : rgba($gn-red-color, 1);
                                            background-color: rgba($gn-red-color, 0.8) ;
                                        }
                                    }
                                    .Change {
                                        width: 100%;
                                        .Amount {
                                            margin : auto;
                                            padding:0px;
                                            color : rgba($gn-black-color, 0.5)!important;
                                            font-size: 80%;
                                            font-weight: bolder;
                                            text-transform: lowercase;
                                        }
                                    
                                    }
                                }
                                &:first-child {

                                }
                            }
                        }
                    }
                    .Botfolio {
                        display: flex;
                        flex-direction: column;
                        display: none;
                        .Header {
                            background-color: rgba($gn-white-color, 1);
                            color : rgba($gn-frame-color, 1);
                            font-size: 125%;
                            font-weight: bolder;
                            padding: 0.25rem 1rem;
                            margin-bottom: 0.5rem;
                        }
                        .Holder{
                            display: flex;
                            flex-direction: row;
                            cursor: default;
                            .Wrapper {
                                flex-basis: 33.3333%;
                                display: flex;
                                flex-direction: column;
                                flex-shrink: 0;
                                &:Hover{
                                    .Title {
                                        transform: scale3d(2, 2, 2);
                                        color : rgba($gn-white-color, 1);
                                    }
                                }
                                .Title {
                                    transition: all 0.25s ease-in-out;
                                    font-size: 70%;
                                    color : rgba($gn-white-color, 0.5);
                                    font-weight: bold;
                                }
                                .Value {
                                    font-size: 200%;
                                    color : rgba($gn-white-color, 1);
                                    font-weight: bold;
                                    margin-top: 0.5rem;
                                }
                            }
                        }
                    }
                }
                &.Onhold, &.Automate {
                    flex-grow: 1;
                    .Title {
                        flex-shrink: 0;
                        //background-color: rgba($gn-frame-color, 1);
                        
                        //border-radius: 1rem;
                        //width: fit-content;
                        width: 100%;
                        cursor: default;
                        display: flex;
                        flex-direction: row;
                        .Text {
                            color : rgba($gn-black-color, 1);
                            font-size: 150%;
                            font-weight: bolder;
                            //padding: 0.25rem 1.5rem;
                            &.Middle {
                                margin: 1rem auto;
                                //justify-content: center;
                            }
                        }
                        .Btn {
                            background-color: transparent;
                            color : rgba($gn-frame-color-2, 1);
                            border: 1px solid rgba( $gn-frame-color-2,1 );
                            transition: all 0.25s ease-in-out;
                            border-radius: 0.5rem;
                            cursor: pointer;
                            margin:auto;
                            margin-right: 1rem;
                            margin-left: 0rem;
                            padding:0.25rem 1rem;

                            &:hover {
                                background-color : rgba($gn-frame-color-2, 1);
                                color : rgba($gn-white-color, 1);
                            }
                        }
                    }
                    
                    .Container {
                        display: flex;
                        flex-direction: column;
                        flex-grow: 1;
                        background-color: rgba( $gn-white-color , 0.5 );
                        border-radius: 1rem;
                        margin : 0rem 1rem;
                        width :calc( 100% - 2rem);
                        margin-bottom: 1rem;
                        //padding: 0.5rem 0rem;
                        padding: 0px;
                        
                    }
                }

                &.Onhold {
                    .Container {
                        padding: 0.5rem 0rem;
                    }
                }
                &.Automate {
                    .Container {
                        //overflow: auto;
                        max-height: calc(  100vh - 136px - 50px - 50px - 130px);
                        .Frame {
                            //background-color: rgba( $gn-black-color , 0.05 );
                            border-radius: 1rem;
                            height: 100%;
                            //padding: 1rem 0rem;
                            //flex-grow: 1;
                        }
                        .Select-Box {
                            background-color: transparent;
                            border-radius: 1rem;
                            border-width: 0px;
                            &.select__control {
                                height: 100%;
                                overflow: hidden;
                            }
                            &.select__value-container {
                                max-height: 100%;
                                overflow: auto;
                                align-self: flex-start;
                                padding: 1rem 0.25rem;
                            }
                            &.select__multi-value {
                                        background-color: rgba( $gn-frame-color , 0.75 );
                                        border-radius: 0.25rem;
                                        color : rgba( $gn-white-color , 1 );
                                        backdrop-filter: blur(5px);
                                        transition: all 0.25s ease-in-out;
                                        border-radius: 0.5rem;
                                        &:hover {
                                            transform: scale(1.05);
                                            background-color: rgba( $gn-frame-color , 1 );
                                        }
                            }
                            &.select__multi-value__label {
                                color : rgba( $gn-white-color , 1 );
                                font-weight: bolder;
                            }
                            &.select__control--menu-is-open {
                                background-color: rgba( $gn-black-color , 0.5 );
                                backdrop-filter: blur(4px);
                            }
                            &.select__menu {
                                background-color: rgba( $gn-black-color , 0.5 );
                                backdrop-filter: blur(5px);
                                transition: all 0.25s ease-in-out;
                                div { 
                                    div{
                                        color : rgba( $gn-white-color , 1 );
                                        font-size: 80%;
                                        border-bottom: 1px solid rgba( $gn-white-color, 0.05 );
                                        transition: all 0.25s ease-in-out;
                                        border-radius: 0rem;
                                        &:hover {
                                            background-color: rgba( $gn-accent-color ,0.25);   
                                            
                                        }
                                    }
                                }
                            }
                            &.select__multi-value {
                                height: fit-content;
                            }
                            &.select__indicators {
                                border-radius: 0rem;
                                background-color: rgba( $gn-black-color , 0.1 );   
                                display: flex;
                                flex-direction: row;
                                height: 100%;
                                //flex-direction: column;
                                //background-color: rgba( $gn-black-color , 0.05 );
                                //position: sticky;
                                //top:0px;
                                .select__indicator {
                                    color : rgba( $gn-white-color , 1 );
                                    flex-grow: 1;
                                    transition: all 0.25s ease-in-out;
                                    //position: sticky;
                                    display: flex;
                                    //flex-direction: column;
                                    height: calc( 100% - 16px);
                                    justify-content: center;
                                    flex-direction: column;
                                    border-radius: 0rem;
                                    cursor: pointer;
                                    &:first-child {
                                        top : 0px;
                                        background-color : rgba( $gn-red-color , 0.5 );
                                        &:hover {
                                            background-color : rgba( $gn-red-color , 1 );
                                        }
                                    }
                                    &:last-child {
                                        top : 50px;
                                        bottom:20px;
                                        background-color : rgba( $gn-frame-color , 0.5 );
                                        &:hover {
                                            background-color : rgba( $gn-frame-color , 1 );
                                        }
                                    }
                                    
                                }
                                .select__indicator-separator {
                                    background-color: rgba( $gn-white-color , 0.1 );
                                }
                            }
                        }
                    }
                }
            }
        }


        .Manager {
            position: sticky;
            max-height: calc( 30px + 2rem );
            width: 100%;
            bottom:0px;
            //background-color: rgba( $gn-white-color, 1 );
            padding : 1rem 0rem;
            margin-top: auto;
            //background-color: rgba( $gn-frame-color , 0.9 );
            .Btn {
                margin : auto 2rem;
                height:20px;
                background-color: rgba($gn-frame-color, 1) !important;
                border : 2px solid rgba($gn-frame-color, 1) !important;
                color : rgba($gn-white-color, 1) !important;
                text-transform: uppercase;
                display : flex;
                flex-direction: column;
                justify-content: center;
                border-radius: 1.5rem;
                &:hover {
                    border : 2px solid rgba($gn-accent-color, 1) !important;
                    background-color : rgba($gn-accent-color, 1) !important; 
                    color : rgba($gn-black-color, 1) !important;
                }
            }
        }

        

    }
    &.Login-Holder {
        display: flex;
        flex-direction: column;
        padding:0rem 1rem;
        background-color: rgba($gn-white-color, 1);
        border-left: 1px solid rgba( $gn-black-color, 0.05 );
        overflow: auto;
        position: relative;
        z-index : 1;
        top:0px;
        left:0px;
        right:0px;
        bottom:0px;
        flex-grow: 1;
        .Login-Body {
            margin: auto;
            display: flex;
            flex-direction: column;
            .Logo-Wrapper {
                padding: 0.25rem;
                margin: 2rem auto;
                margin-bottom: 1rem;
                display: flex;
                flex-direction: row;
                cursor: default;
                .Logo-Img {
                    width : 50px;
                }
                .Logo-Fa {
                    color : rgba($gn-white-color, 1);
                    font-size: 50px;
                }
                .Logo-Title {
                    color : rgba($gn-white-color, 1);
                    margin: auto 1rem;
                    font-size: 30px;
                }
            }
            .LBody {
                .Holder {
                    .Wrapper {
                        margin : 1.5rem auto;
                        //width: calc( 100% - 2rem);
                        &:hover {
                            .Label {
                                color : rgba( $gn-white-color , 1 ) !important;
                            }
                        }
                        .Label {
                            color : rgba( $gn-white-color , 0.8 );
                            font-weight: bolder;
                            cursor: default;
                        }
                        .Value {
                            flex-grow: 1;
                            width: calc( 250px - 2rem);
                            border-bottom: 1px solid rgba( $gn-white-color , 1 );
                        }
                    }   
                }
                .Login-Stm-Frame, .Login-Btn-Frame, .Login-Link {
                    width: calc( 250px - 0rem);
                    margin:auto;
                    
                }
                .Login-Link {
                    .Login-Link-a {
                        color: rgba( $gn-black-color, 0.5 );
                        font-size: 80%;
                    }
                }
            }
        }
    }
}