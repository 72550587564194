@import "../../../public/style.scss";

.Simple {
    width: 100%;
    position: relative;
    //overflow: hidden;
    height:calc( 100% - 00px);
    flex-direction: column;
    display: flex;
    background-color: rgba( $gn-white-color , 0.9 );
    box-shadow: 0 0px 5px 0 rgba(33, 33, 33 , 0.37);
    //backdrop-filter: blur(2px);
    //-webkit-backdrop-filter: blur(2px);
    transition: opacity,width 0.25s ease-in;
    opacity: 1;
    z-index : 99999;

}

@media screen and (max-width: 750px)  {
  .Simple {

  }
}