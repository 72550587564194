@import "../../public/style.scss";

.trader2 {
  //z-index : 99999 !important;
    //position: relative;
    flex-grow: 1;
    z-index:1001;
    //box-shadow: 0px 0px 5px rgba($gn-dark-color, 0.5);
    //height:400px;
    //width:320px;
    //background-color: rgba($gn-white-color, 1);
    display: flex;
    flex-basis: 70%;
    flex-direction: column;
    transition: visibility,opacity 0.25s ease-in-out;
    //border: 1px solid rgba( $gn-white-color, 0.18 );
    border-radius: 0px;
    overflow: hidden;
    //box-shadow: 0 8px 32px 0 rgba( $gn-dark-color, 0.37 );
    backdrop-filter: blur( 5px );
    -webkit-backdrop-filter: blur( 2px );
    background-color: rgba($gn-white-color, 0.8);
    //background-color: rgba($gn-frame-color-2, 1.0);
    //padding: 0.5rem 0.5rem;
    &.draggable {
      position: absolute;
      top:0px;
      right:0px;
      .content {
        &.content-full {
          margin: 5px;
        }
        &.content-half {
          margin: 5px;
        }
      }
    }
    &.trader-full {
      width:100%;
      height:100%;
    }
    &.trader-show {     
      visibility: visible;
      opacity: 1;
      
    }
    &.trader-hide {
      visibility: hidden;
      opacity: 0;
      height:0px!important;
      width:0px!important;
    }

    .handle {
      cursor: grabbing;
      width: 100%;
      height:40px;
      background-color: rgba($gn-black-color, 0.4);
      //border-bottom: 1px solid $gn-light-color;
      line-height: 40px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      position: relative;
      z-index:10;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
      .hframe {
        position: absolute;
        top:0px;
        right:0px;
        left: 0px;
        bottom: 0px;
        background-color: transparent;
        z-index : 5;
      }
      .icon {
        cursor: pointer;
        color : rgba($gn-white-color, 0.75);
        background-color : transparent;
        opacity: 1 !important;
        width:12px;
        height:12px;
        z-index: 10;
        //margin:14px 0px;
        padding : 14px 12px;
        //margin-right: 1.5rem;
        transition: background-color 0.25s ease-in-out;
        &:hover {
          background-color : rgba($gn-frame-color, 0.5);
        }
        &.close {
          margin-left:auto;
          //margin-right: 1rem;
        }
        &.clone {
          margin-left:auto;
          //margin-right: 1.5rem;
        }
      }


      .logo {
        margin:10px 0.5rem;
        width:20px;
        height: 20px;
        color : rgba($gn-frame-color, 1);
      }
      .name {
        font-weight: bolder;
        color : rgba($gn-white-color, 1);
      }
      
    }
    .react-resizable-handle {
      margin-top: auto;
      /*height: 10px;*/
      cursor: se-resize;
      //background-color: rgba($gn-dark-color, 0.8);
      position: absolute;
      top:0px;
      height:100%;
      left:0px;
      right:0px;
      border-radius: 0.25rem;
      border: 1px solid rgba( $gn-black-color , 0.05 );
      /* width: 20px; */
      //height: 20px;
      /*background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=);
      background-position: bottom right;
      padding: 0 3px 3px 0;
      background-repeat: no-repeat;
      background-origin: content-box;
      box-sizing: border-box;
      cursor: se-resize;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;*/
    }

    .content {
      position: relative;
      z-index: 5;
      &.content-half {
        margin: 0rem;
        margin-top: 0px;
        padding-top:0rem;
        flex-grow: 1;
        position: relative;
        z-index:10;
        //background-color: rgba($gn-black-color, 0.25);
        color : rgba($gn-light-color, 1);
        display: flex;
        flex-direction: row-reverse;
        height: calc( 100% - 40px - 0.5rem );
        transition: visibility 0.25s ease-in;
        .record-nav {
          flex-grow: 1;
        }
      }
      &.content-full {
        margin: 0rem;
        margin-top: 0px;
        padding-top:0rem;
        flex-grow: 1;
        position: relative;
        z-index:10;
        background-color: rgba($gn-black-color, 0);
        color : rgba($gn-light-color, 1);
        display: flex;
        flex-direction: column;
        //flex-direction: column-reverse;
        height: calc( 100% - 40px - 0.5rem );
        overflow: hidden;
        transition: visibility 0.25s ease-in;
      }
      .load {
        visibility: 1;
        position: absolute;
        top:0px;
        left:0px;
        right:0px;
        bottom:0px;
        background-color: rgba($gn-black-color, 0.25);
        z-index : 5;
      }
      .unload {
        visibility: 0;
        position: absolute;
        top:0px;
        left:0px;
        width:0px;
        height:0px;
        z-index : 5
      }
      .record-nav {
        padding-top:0rem;
        background-color: transparent;
        display: flex;
        //display: none;
        flex-direction: column;
        overflow: hidden;
        .nav-tabs {
          border-width : 0px;
          border-bottom : 1px solid rgba($gn-black-color, 0.25);
          height: 50px;
          .nav-link {
            background-color: rgba($gn-frame-color, 0.5);
            color : rgba($gn-white-color, 0.5);
            padding:0.5rem !important;
            font-weight: bolder;
            transition: all 0.25s ease-in-out;
            border-width : 0px;
            display: flex;
            flex-direction: row;
            border-radius: 0rem;
            &.active {
              background-color: rgba($gn-frame-color, 0.75);
              color : rgba($gn-white-color, 1.0);
              border-color: rgba($gn-dark-color, 0.25);
            }
            &:hover:not(.active) {
              border-radius: 0px;
              background-color: rgba($gn-frame-color, 0.8);
              //border-color: rgba($gn-white-color, 0.5);
              border-bottom-width: 0px;
            }
            .Title-Wrapper {
              display: flex;
              flex-direction: row;
              .Icon {
                margin: auto;
                width:25px;
                height:25px;
                padding:0rem 0.5rem;
              }
              .Name {
                margin: auto;
                margin-left: 0.5rem;
                font-size: 90%;
                width:150px;
                
                font-weight: bolder;
              }
            }
          }
        }
        .tab-content{
          overflow: hidden;
          flex-grow: 1;
        }
        .tab-pane {
          overflow: hidden;
          width: auto;
          height: 100%;
          .record-content {
            display: flex;
            flex-direction: column;
            overflow: auto;
            padding-bottom: 100px;
            height: 100%;
            .panel {
              background-color: rgba($gn-black-color, 0.9);
              backdrop-filter: blur( 5px );
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              position: sticky;
              top: 50px;
              z-index: 999;
              .switch {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                height: 50px;
                .btn {
                  margin : 0rem 0px;
                  padding : 0rem 1rem;
                  //padding-top: 2.5px;
                  //padding-bottom: 2.5px;
                  margin-right: auto;
                  
                  border-width: 0px;
                  color : rgba($gn-white-color, 1);
                  font-size: 80%;
                  display: flex;
                  flex-direction: row;
                  border-radius: 0rem;
                  height: 100%;
                  transition: all 0.25s ease-in-out;
                  .Fa{
                    margin: auto 0px;
                    margin-right: 0.5rem;
                  }
                  .Name {
                    margin: auto 0px;
                  }
                  &:first-child {
                    border-right: 1px solid rgba($gn-white-color, 0.1) !important;
                    background-color: rgba($gn-black-color, 0.8) !important;
                  }
                  &:last-child {
                    margin-left: auto;
                    margin-right: 0rem;
                    border-left: 1px solid rgba($gn-white-color, 0.1) !important;
                  }
                  &:hover {
                    background-color: rgba($gn-white-color, 0.1) !important; 
                    transform: scale(1.1);
                  }
                }
              }
            }

          }
        }
      }
      .order {
        display: flex;
        flex-direction: column;
        //padding-top:0.5rem;
        background-color: rgba($gn-white-color, 0.05);
        //flex-basis: 100%;
        //overflow: auto;
        //flex-grow: 1;
        //min-height: 500px;
        

      }

      .record-half {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        height: 100%;
        overflow: hidden;
        .Element {
          display: flex;
          flex-direction: column;
          flex-basis: 50%;
          width:50%;
          overflow: hidden;
          height: 100%;
          &:first-child {
            border-right: 1px solid rgba( $gn-black-color , 0.1 );
          }
        }
      }
      
    }

 


}

@media screen and (max-width: 960px)  {
  .trader2 {
    .content{
      .record-nav {
        .nav-tabs {
          .nav-link {
            color : rgba($gn-frame-color, 1);
            .Title-Wrapper {

            }
          }
        }
      }
    }
  }
}