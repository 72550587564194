@import "../../public/style.scss";

.CreateBackdrop {
  background-color: rgba( $gn-black-color , 0.75 );
  backdrop-filter: blur( 5px );
}

.create-app {
  z-index: 1041 !important;
  background-color: rgba( $gn-black-color , 0.25);
  position: absolute;
  width:100%;
  margin: auto;
  .CreatePanel {
    width: fit-content;
    //max-width :none !important;
    //width: calc( 60% -  0px);
    //width: 600px;
  }
  .modal-content {
    height: calc( 100vh - 100px );
    width: fit-content;
    //width : calc( 100% - 100px );
    background-color: rgba( $gn-white-color, 1 ) !important;
    backdrop-filter: blur(5px) !important;
    border : 1px solid rgba( $gn-black-color, 0.1 );
    border-radius:0.5rem;
    //height: calc( 100% - 2rem); 
    /*background-color: transparent;
    border-color : transparent;
    top: calc( 50vh - 150px );*/
  }
  .app-Selection-Header {
    position: relative;
    width : calc( 100% - 2rem);
    line-height: 40px;
    text-align: center;
    margin-bottom: 20px;
    border-bottom : 1px solid rgba( $gn-black-color, 0.1 );
    .close {
      line-height: 50px;
      color : rgba( $gn-black-color, 0.9 );
    }
    .app-Selection-Header-Frame {
      float:left;
      .app-Selection-Header-Logo {
        float:left;
        width:35px;
        height:35px;
        /*padding : 2px 0px;*/
        margin: 7.5px 5px;
        border: 0px solid rgba($gn-white-color, 1);
        line-height: 35px;
        font-weight: 600;
        font-size: 30px;
      }
      .app-Selection-Header-Fa {
        float:left;
        width:25px;
        height:25px;
        margin: 10px 0.5rem;
        border: 0px solid rgba($gn-white-color, 1);
        color :rgba( $gn-frame-color , 1 );
        font-weight: 600;
        font-size: 30px;
        cursor:default;
      }
      .app-Selection-Header-Title {
        float:left;
        height:50px;
        /*padding : 2px 0px;*/
        margin: 0px 2.5px;
        color: rgba($gn-black-color, 0.9);
        border-radius: 7.5px;
        -moz-border-radius:7.5px;
        -webkit-border-radius:7.5px;
        line-height: 50px;
        font-weight: bolder;
        font-size: 150%;
      }
    }
  }
  .modal-body {
    display: flex;
    flex-direction: row;
    height: calc( 100% - 140px );
    .Left {
      display: flex;
      flex-direction: column;
      width: 400px;
      overflow: auto;
      flex-shrink: 0;
      .Partition {
        padding: 0rem 1.5rem;
        padding-bottom: 1rem;
        display: flex;
        flex-direction: column;
        position: relative;
        .Title {
          font-size: 120%;
          color: rgba( $gn-frame-color, 0.8 );
          margin: 10px;
          font-weight: bold;
        }
        .Content {
          margin-top: 0rem;
          padding: 0.5rem;
          .Input {
            border: 2px solid rgba( $gn-frame-color , 1 );
            //background-color: rgba( $gn-black-color , 0.05);
            color : rgba( $gn-frame-color , 1);
            padding:0.5rem 1rem;
            margin-left: 1.5rem;
            flex-basis: calc( 100% - 3.5rem);
            width : calc( 100% - 3.5rem);
            max-width :calc( 600px - 3.5rem);
            font-size: large;
          }
          .Regions {
            position: relative;
            //margin-right:0px !important;
            border-radius: 0.5rem !important;
            margin: 0rem 0px;
            margin-bottom: 0px;
            margin-left : 1.5rem;
            margin-right : auto;
            background-color : transparent !important;
            //background-color: rgba($gn-badge-color, 0.5) !important;
            color : rgba($gn-white-color, 1);
            border: 0px solid rgba($gn-badge-color, 0.5) !important;
            font-weight: bolder;
            .btn {
              border-radius: 0.5rem !important;
              padding:0.375rem 1rem;
              font-weight: bolder;
              border-width: 0px;       
              display: flex;
              flex-direction: row;           
              .Scope {
                display: flex;
                flex-direction: row;
                font-size: 100%;
                font-weight: bolder;
                .Region {
                  margin:auto 0px;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  background-color: rgba( $gn-frame-color , 0);
                  height : fit-content;
                  .react-emojis {
                    font-size: 20px;
                    font-weight: 400;
                    margin: auto 0.5rem;
                    //margin-top: 0.5rem;
                  }
                }
              }
              &::after {
                margin: auto;
                margin-left: 0.5rem;
              }
              //display: flex;
              //flex-direction: row;
              //justify-content: center;
            }
            .dropdown-menu {
              .Line {
                display: flex;
                flex-direction: row;
                font-size: 100%;
                font-weight: bolder;
                padding : 1rem 0.5rem;
                width : calc( 100% - 1rem);
                border-bottom: 1px solid rgba( $gn-black-color, 0.1 );

                .Region {
                  margin:auto 0px;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  background-color: rgba( $gn-frame-color , 0);
                  height : fit-content;
                  .react-emojis {
                    font-size: 20px;
                    font-weight: 400;
                    margin: auto 0.5rem;
                    //margin-top: 0.5rem;
                  }
                }
              }
            }

          }
        }
        .Strategy {
          display: flex;
          flex-direction: row;
          .Box {
            width:calc( 40% - 4rem);
            display: flex;
            flex-direction: column;
            margin: 0rem auto;
            border-radius: 0.5rem;
            margin-top:0.5rem;
            border: 2px solid rgba( $gn-frame-color, 1 );
            padding:2rem 2rem;
            transition: all 0.25s ease-in-out;
            cursor: pointer;
            .Fa {
              margin: auto;
              color : rgba( $gn-frame-color, 1 );
              font-size: 150%;
              margin-bottom: 0.25rem;
              transition: all 0.25s ease-in-out;
            }
            .Text {
              transition: all 0.25s ease-in-out;
              font-size: 100%;
              color : rgba( $gn-frame-color, 1 );
              text-align: center;
            }
            &:hover {
              background-color: rgba( $gn-frame-color , 0.8 );
              .Fa, .Text {
                color : rgba( $gn-white-color, 1 );
              }
            }
            &.selected {
              background-color: rgba( $gn-frame-color , 1 );
              transform: scale(1.1);
              .Fa, .Text {
                color : rgba( $gn-white-color, 1 );
              }
            }
            &:first-child {
              margin-right : 0.5rem; 
            }
            &:last-child {
              margin-left : 0.5rem; 
            }
          }
          
        }
        .Generate {
          margin-top :0rem;
          margin-left: 1rem;
          height:40px;
          padding:0.25rem 2rem;
          background-color: rgba( $gn-frame-color, 1 ) !important;
          border-style: none;
          font-weight: bolder;
          border-radius: 0.5rem;

          .fa {
            color : rgba($gn-white-color, 1);
            width:0.75rem;
            margin-right:0.25rem;
          }
          &:hover {
            background-color: rgba( $gn-badge-color , 1 ) !important;
          }
        }

        &.Gen {
          position: sticky;
          bottom: 0px;
          z-index: 10;
          background-color: rgba( $gn-white-color, 0.9);
          backdrop-filter: blur(5px);
        }

      }
    }

    .Right {
      width: 0px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      position: relative;
      transition: all 0.25s ease-in-out;
      flex-shrink: 0;
      &.Show {
        width:320px;
        border-left: 1px solid rgba( $gn-black-color , 0.1);
      }
    }

  }

}

@media screen and (max-width: 750px)  {
  .create-app {
    .CreatePanel {
      max-width :none !important;
      width: calc( 100% - 0rem);
    }
    .modal-content {
      overflow: hidden;
      .modal-body {
        overflow: auto;
        flex-direction: row;
        height: calc( 100% - 140px );

      }
    }
  }
}