@import "./public/style.scss";

.App {
  text-align: center;
}

.App-Container {
  position: absolute;
  top:00px;
  left:0px;
  right:0px;
  bottom:0px;
  z-index:5;
  overflow: hidden;
  background-color: rgba($gn-white-color, 1);
  display: flex;
  flex-direction: column;
  .app-holder {
    //position: absolute;
    /*position: relative;*/
    //margin-top: 40px;
    flex-grow: 1;
    position: relative;
    left:0px;
    right:0px;
    top:0px;
    bottom:0px;
    /*height : auto;*/
    z-index:5;
    overflow: hidden;
    background-color: rgba($gn-white-color,1);
    //padding-bottom: 80px;
  }
  .app-holder-garlican {
    margin-top: 0px;
    //height: calc(100vh - 0px);
    left:0px;
    right:0px;
    bottom:0px;
    z-index:5;
    overflow: hidden;
    background-color: rgba( $gn-white-color , 0.5 );
    position: relative;
    padding-bottom: 100px;
    //margin-bottom: 80px;
    flex-grow: 1;
  }  
}

.btn {
  background-color: rgba( $gn-frame-color, 1) !important;
  border-color: rgba($gn-frame-color, 1) !important;
  height: fit-content;
  border-radius: 1rem;
  &:hover {
    background-color: rgba($gn-badge-color,1) !important;
    border-color: rgba($gn-badge-color,1) !important;
    color : rgba($gn-black-color,1) !important;
  }
}

.btn-outline-primary {
  color: rgba($gn-frame-color,1) !important;
  border-color: rgba($gn-frame-color,1) !important;
  background-color: transparent!important;
  &:hover {
    background-color: rgba($gn-green-color,1) !important;
    border-color: rgba($gn-green-color, 1) !important;
    color : rgba($gn-white-color, 1) !important;
  }
}

.app-tri-holder {
  margin: auto 0px;
  cursor: pointer;
  transition: all 0.4s ease-in-out 0.1s;
  margin-right: 5px;
  display: -webkit-box;
  flex-direction: row;
  &:hover {
    .app-btn {
      background-color: rgba($gn-green-color, 1);
      border-color : rgba($gn-green-color, 1);
    }
    .app-tri {
      border-color: transparent rgba($gn-green-color,1) transparent transparent;
    }
  }
  .app-tri {
    float:left;
    position: relative;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 16.25px 12px 16.25px 0;
    border-color: transparent rgba($gn-frame-color,1) transparent transparent;
    line-height: 0px;
    margin-right: -1px;
    z-index : 10;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }
  .app-btn{
    float: left;
    background-color: rgba($gn-frame-color, 1);
    z-index: 5;
    width: calc( 75px - 1.5rem);
    position: relative;
    border-color : rgba($gn-frame-color, 1);
  }
}

.modal-content {
  background-color: rgba($gn-white-color, 1) !important;
}

.TP {
  position: absolute;
  left: 0px;
  transform: translate3d(51px, 0px, 0px);
  .arrow {
    background-color: rgba( $gn-badge-color , 0.8 );
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem;
    backdrop-filter: blur(5px);
  }
  .TP-inner {
    background-color: rgba( $gn-badge-color , 0.8 );
    padding: 0rem 0.5rem;
    border-radius: 0.25rem;
    backdrop-filter: blur(5px);
    strong {
      color : rgba( $gn-black-color , 1 );
      white-space: break-spaces;
    }
  }
}



@media screen and (min-width: 960px)  {
  .App-Container {
    .app-holder, .app-holder-garlican {
      //left : calc( 50% - 480px );
      //right : calc( 50% - 480px );
      //width : 960px;
    }
    
  } 
}

@media screen and (max-width: 992px)  {
  .App-Container {
    .app-holder {
      margin-top: 0px;
      //margin-top: 0px;
    }
    .app-holder-garlican {
      margin-top: 0px;
      overflow: auto;
    }
  } 
}


.VictoryContainer {
  user-select: auto !important;
  /*pointer-events: auto !important;*/
  touch-action: auto !important;
}

.modal {
  //background : #21252999 !important;
  background-color: rgba( $gn-black-color , 0.5 );
  backdrop-filter: blur(10px);
}
