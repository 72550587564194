@import "../../../public/style.scss";

.Code {
    width:0%;
    position: relative;
    overflow: hidden;
    height:100%;
    flex-direction: column;
    display: flex;
    background-color: rgba( $gn-black-color , 0.9 );
    box-shadow: 0 0px 5px 0 rgba(33, 33, 33 , 0.37);
    //backdrop-filter: blur(2px);
    //-webkit-backdrop-filter: blur(2px);
    transition: opacity,width 0.25s ease-in;
    opacity: 0;
    &.show {
      opacity: 1;
      width:100%;
      //height:100%;
    }
    .Title {
      height:40px;
      flex-direction: column;
      display: flex;
      font-size: 100%;
      font-weight: bolder;
      color : rgba($gn-white-color, 1);
      justify-content: center;
      cursor: default;
      background-color: rgba( $gn-frame-color, 1 );
      padding:0rem 1.5rem;
      &.buy {
        background-color: rgba( $gn-green-color, 0.25 );
      }
      &.sell {
        background-color: rgba( $gn-red-color, 0.25 );
      }
      //width: fit-content;
      //margin: 0px auto;
    }
    .CPanel {
      height:60px;
      width: 100%;
      background-color: rgba( $gn-white-color , 1 );
      flex-direction: row;
      display: flex;
      justify-content: flex-start;
      position: relative;
      flex-shrink: 0;
      .btn {
        width:fit-content;
        margin: auto 0px;
        height:30px;
        //margin: 5px;
        padding:0px 0.5rem;
        border-radius: 0px;
        background-color: rgba( $gn-white-color, 0 ) !important;
        border: 2px solid rgba( $gn-frame-color, 1 ) !important;;
        color : rgba( $gn-frame-color, 1 ) !important;
        border-style : none;
        font-size: 100%;
        border-radius: 0.5rem;;
        &:first-child {
          margin-left: 0.5rem;
          //display: none;
        }
        &:hover {
          background-color: rgba( $gn-frame-color, 1 ) !important;
          .fa, span {
            color : rgba( $gn-white-color, 1 ) !important;
          }
        }
        .fa, span {
          transition: all 0.25s ease-in-out;
          color : rgba($gn-frame-color, 1);
          font-size: 100%;
          margin: 0px 0.25rem;
          font-weight: bold;
        }
        .fa {
          //margin: 0px 0.25rem;
        }
        span {
          font-size: 90%;
        }
        &.pdf {
          margin-left: auto;
          margin-right: 0.5rem;
        }
      }
    }
    .Error {
      height:auto;
      z-index : 999;
      width : calc( 100% - 1rem);
      background-color: rgba( $gn-red-color, 0.9 );
      cursor: pointer;
      padding:0.5rem 0.5rem;
      .Message{
        color : rgba( $gn-white-color, 1 );
        font-size: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
    .message{
      flex-grow: 1;
      flex-direction: column;
      display: flex;
      font-size: 110%;
      font-weight: bolder;
      color : rgba($gn-white-color, 1);
      justify-content: center;
      cursor: default;
      background-color: rgba( $gn-white-color, 0.05 );
    }
}

@media screen and (max-width: 750px)  {
  .Code {

  }
}