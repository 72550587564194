@import "../../public/style.scss";

.R-Backdrop {
  background-color: transparent;
  //background-color: rgba( $gn-black-color, 0.75 ) !important;
  //backdrop-filter: blur(5px);
}

.Garlican-Report {
  /*position : absolute;*/
  top:0px;
  left:0px;
  right:0px;
  bottom: 0px;
  overflow: auto;
  z-index : 10000;
  .reading {
    position: absolute;
    top:0px;
    left : 0px;
    right:0px;
    bottom:0px;
    background-color: rgba($gn-dark-color, 0.3);
    flex-direction: column;
    display: flex;
    justify-content: center;
    text-align: center;
    .spin {

    }
    .text {
      color : rgba($gn-white-color, 1);
    }
  }
  .R-Dialog {
    max-width: none !important;
    position: fixed;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    margin: 0px;
    border: 0px;
    border-radius: .0rem;
    //overflow: hidden;
    .modal-content {
      /*overflow: auto;*/
      position: absolute;
      top: 0px;
      bottom: 0px;
      left:0px;
      right:0px;
      overflow: auto;
      border: 0px;
      border-radius: .0rem;
      background-color: rgba( $gn-black-color, 0.75 ) !important;
      backdrop-filter: blur(5px);
      .Header {
        position: sticky;
        width : calc( 100% - 2rem);
        line-height: 60px;
        text-align: center;
        padding : 0.5rem 1rem;
        //padding-bottom: 40px;
        top:0px;
        z-index:100;
        background-color: rgba( $gn-black-color, 0.8 );
        backdrop-filter: blur(5px);
        border-bottom-color: rgba( $gn-white-color, 0.1 );
        .close {
          line-height: 50px;
        }
        .Frame {
          display: flex;
          flex-direction: row;
          .app-tri-holder {
            transition: all 0.25s ease-in-out;
            &:hover {
              .app-tri {
                border-color: transparent rgba( $gn-frame-color, 0.5 ) transparent transparent;
              }
              .app-btn {
                border-color: rgba( $gn-frame-color, 0.5 ) !important;
                border-left-width: 0px;
                //border-style: none;
                background-color: rgba( $gn-frame-color, 0.5 ) !important;
                color : rgba( $gn-white-color, 1 ) !important;
              }
            }
            .app-tri {
              border-color: transparent rgba( $gn-badge-color, 0.5 ) transparent transparent;
            }
            .app-btn {
              border-color: rgba( $gn-badge-color, 0.5 ) !important;
              border-left-width: 0px;
              background-color: transparent !important;
              color : rgba( $gn-badge-color, 1 ) !important;
              width:auto;
            }
          }
          .Logo {
            float:left;
            width:35px;
            height:35px;
            margin: 7.5px 0.5rem;
            border: 0px solid rgba($gn-white-color, 1);
            line-height: 35px;
            font-weight: 600;
            font-size: 30px;
          }
          .Title {
            float:left;
            height:50px;
            /*padding : 2px 0px;*/
            margin: 0px 0.5rem;
            color: rgba($gn-white-color, 1);
            border-radius: 7.5px;
            -moz-border-radius:7.5px;
            -webkit-border-radius:7.5px;
            line-height: 50px;
            font-size: 15px;
            border: 0px solid rgba( $gn-dark-color, 0.05 );
            border-radius: 0px;
            /*width: 250px;*/
            width : auto;
            .Name {
              font-weight: 600;
              font-size: 18px;
              color :rgba( $gn-badge-color, 1 );
              margin-right:0.25rem
            }
          }
          .Icon {
            float:left;
            height:15px;
            width:auto;
            color : rgba($gn-dark-color, 1);
            margin : 17.5px 10px;
            &:hover {
              color : rgba($gn-green-color, 1);
            }
          }
        }
      }

      .Content  {
        display: flex;
        flex-direction: column;
        padding:0px;
        &.Lock {
          overflow: hidden;
        }
        .Panel {
          height:40px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          top: 65px;
          position: sticky;
          background-color: rgba( $gn-black-color, 0.8 ) ;
          backdrop-filter: blur(5px);
          z-index : 100;
          .Btn {
            background-color: rgba( $gn-white-color, 0.25 ) !important;
            color : rgba( $gn-white-color, 1 ) !important;
            border-radius: 0px;
            height:30px;
            margin: auto 0.25rem;
            padding:0px 1rem;
            border-style: none;
            transition: all 0.25s ease-in-out;
            &:hover {
              background-color: rgba( $gn-frame-color, 0.5 ) !important;
            }
            &.selected {
              background-color: rgba( $gn-badge-color, 0.5 ) !important;
              transform: scale3d(1.1, 1.1, 1.1);
            }
            &:first-child {
              margin-left: 1.5rem;
            }
          }
          .Log-Button-Group {
            margin-left: auto;
            display: flex;
            flex-direction: row;
            .Log-Dropbox {
              margin-left: 0px;
              margin-right: 0.5rem;
              .dropdown-toggle {
                background-color: transparent !important;
                border: 2px solid rgba( $gn-badge-color, 1  ) !important;
                color: rgba( $gn-badge-color, 1  ) !important;
                &:hover {
                  background-color: rgba( $gn-badge-color, 0.5  ) !important;
                  border: 2px solid rgba( $gn-badge-color, 1  ) !important;
                  color: rgba( $gn-white-color, 1  ) !important;
                }
              }
            }
            .Log-Button {
              margin-left: 0px;
              margin-right: 0.5rem;
              background-color: transparent !important;
              border: 2px solid rgba( $gn-badge-color, 1  ) !important;
              color: rgba( $gn-badge-color, 1  ) !important;
              &:hover {
                background-color: rgba( $gn-badge-color, 0.5  ) !important;
                border: 2px solid rgba( $gn-badge-color, 1  ) !important;
                color: rgba( $gn-white-color, 1  ) !important;
              }
            }
          }
        }
        .Body {
          display: flex;
          flex-direction: row;
          z-index : 1;
          opacity: 0;
          transition: all 0.25s ease-in-out;
          overflow: hidden;
          height:0px;
          .Left {
            flex-shrink: 0;
            width:300px;
            margin:0.5rem;
            background-color: rgba( $gn-white-color, 0.05 );
            border-radius: 0.25rem;
            flex-direction: column;
            display: flex;
            height: fit-content;
            padding-bottom: 5rem;
            opacity: 1;
            transition: all 0.25s ease-in-out;
            .Valid {
              width:80px;
              height:fit-content;
              margin : 1rem 0.5rem;
              margin-left: auto;
              color: rgba($gn-white-color, 1);
              text-align: center;
              display: flex;
              flex-direction: column;
              .V-Title {
                border-top-left-radius : 0.25rem;
                border-top-right-radius : 0.25rem;
                //height:20px;
                margin-bottom: 0.25rem;
                background-color: rgba( $gn-white-color, 0.1 );
                color : rgba($gn-white-color, 1);
                font-size: 80%;
              }
              .V-Holder {
                height:40px;
                border-bottom-left-radius : 0.25rem;
                border-bottom-right-radius : 0.25rem;
                display: flex;
                flex-direction: column;
                &.Pass {
                  background-color: rgba($gn-green-color, 1);
                }
                &.Fail {
                  background-color: rgba($gn-red-color, 1);
                }
                .Fa {
                  height:80%;
                  font-weight: bolder;
                  width:20px;
                  margin: auto;
                }
              }
            }
            .Section {
              display: flex;
              flex-direction: column;
              margin-bottom: 1.5rem;
              .S-Title {
                color :rgba( $gn-badge-color, 1 );
                font-size: 120%;
                font-weight: bolder;
                //background-color: rgba( $gn-black-color, 0.5 );
                height:40px;
                line-height: 40px;
                flex-shrink: 0;
                //margin-bottom: 0.5rem;
                text-align: center;
              }
              .Docker {
                margin : 0.5rem;
                margin-bottom : 0rem;
                &:last-child {
                  margin-bottom : 0.5rem;
                }
                flex-direction: row;
                display: flex;
                .Item {
                  flex-direction: row;
                  display: flex;
                  flex-basis: 100%;
                  cursor: default;
                  &.Two {
                    flex-basis: 50%;
                    &:first-child {
                      margin-right: 0.5rem;
                    }
                  }
                  .D-Holder {
                    flex-direction: column;
                    display: flex;
                    flex-grow: 1;
                    position: relative;
                    .D-Title {
                      background-color: rgba( $gn-white-color, 0.05 );
                      border-top-left-radius: 0.25rem;
                      border-top-right-radius: 0.25rem;
                      height:fit-content;
                      color : rgba($gn-badge-color, 1) !important;
                      font-size: 80%;
                      padding:0.25rem 0.5rem; 
                      font-weight: bold;
                    }
                    .D-Value, .D-Input, .D-Mask, .D-Sub {
                      padding:0.25rem 0.5rem; 
                      background-color: rgba( $gn-white-color, 0.05 );
                      height:fit-content;
                      color : rgba($gn-white-color, 1);
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      text-align: center;
                      font-size: 120%;
                      &:last-child {
                        border-bottom-left-radius: 0.25rem;
                        border-bottom-right-radius: 0.25rem;
                      }
                    }
                    .D-Mask {
                      background-color: rgba( $gn-frame-color, 0.1 );
                    }
                    .D-Input {
                      border-top-left-radius: 0rem;
                      border-top-right-radius: 0rem;
                      border-style: none;
                      //padding: 0.5rem 0px;
                      padding-left: 0px;
                      padding-right: 0px;
                      background-color: rgba( $gn-frame-color, 0.1 );
                      position: absolute;
                      z-index : 1;
                      display: none;
                      //transition: opacity 0.25s ease-in-out;
                      cursor:text;
                      bottom:0px;
                    }
                    &:hover {
                      .D-Input {
                        z-index : 99;
                        display: block;
                        background-color: rgba( $gn-frame-color, 0.5 );
                      }
                      .D-Mask {
                        opacity: 0;
                      }

                    }
                    .D-Sub {
                      padding:0.25rem 0.5rem; 
                      background-color: rgba( $gn-white-color, 0.05 );
                      height:fit-content;
                      color : rgba( $gn-white-color , 0.5);
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      text-align: center;
                      font-size: 60%;
                      text-align: right;
                    }
                  }
                }
              }
            }
            &.hide {
              width : 0px !important;
              overflow: hidden;
              opacity: 0;
            }
          }
          .Middle-Panel {
            width: 10px;
            display: flex;
            flex-direction: column;
            //justify-content: center;
            //height: calc( 100vh - 105px - 1rem);
            position: relative;
            //top: calc( 105px + 0.5rem);
            top:0.5rem;
            background-color: rgba( $gn-dark-color, 0.25 );
            padding:0px 0.25rem;
            transition: background-color 0.25s ease-in-out;
            .Fa {
              color : rgba( $gn-white-color , 0.8 );
              position: sticky;
              top : calc( 50vh - 0.5rem  );
              width:6px;
              font-weight: bolder;
            }
            &:hover {
              background-color: rgba( $gn-frame-color, 0.25 );
            }
          }
          .Right {
            flex-grow: 1;
            margin:0.5rem;
            display: flex;
            flex-direction: column;
            .Upper {
              display: flex;
              flex-direction: column;
              height:300px;
              position: relative;
              z-index: 5;
              .C-Title {
                height: 30px;
                font-weight: bolder;
                color : rgba($gn-badge-color, 1);
                font-size: 100%;
                line-height: 30px;
                //text-align: center;
                background-color: rgba( $gn-white-color, 0.05  );
                padding: 0px 1rem;
                border-top-left-radius: 0.25rem;
                border-top-right-radius: 0.25rem;
              }
              .Back {
                position: absolute;
                right:0.25rem;
                top : 0.25rem;
                height: fit-content;
                padding: 0.1rem 1rem;
                font-size: 90%;
              }
              .C-Holder {
                flex-grow: 1;
                background-color: rgba( $gn-white-color, 0.05  );
                position: relative;
                //overflow: hidden;
                .Legends {
                  position: absolute;
                  bottom:0.5rem;
                  left : 0.5rem;
                  display: flex;
                  flex-direction: row;
                  background-color: rgba( $gn-black-color, 0.5);
                  padding: 0.25rem 0.5rem;
                  .L-Dropdown {
                    background: transparent !important;
                    .btn {
                      background: transparent !important;
                      padding : 0px;
                      border-color: transparent !important;
                      display: flex;
                      flex-direction: row;
                      margin: auto 0px;
                      &:after {
                        margin: auto 0px;
                      }
                    }
                    .dropdown-menu {
                      width : auto;
                      .dropdown-item {
                        padding:0.5rem 1rem;
                        width : calc( 100% - 2rem);
                      }
                    }
                  }
                  .L-Holder {
                    display: flex;
                    flex-direction: row;
                    cursor: pointer;
                    &:first-child {
                      margin-right: 1.5rem;
                    }
                    .L-Circle {
                      width: 0.8rem;
                      height:0.8rem;
                      margin: auto 0rem;
                      margin-right: 0.25rem;
                      border-radius: 0.4rem;
                      &.Brenchmark {
                        background-color: rgba( $gn-frame-color, 1  );
                      }
                      &.Botfolio {
                        background-color: rgba( $gn-badge-color, 1  );
                      }
                    }
                    .L-Name {
                      color: rgba($gn-white-color, 1);
                      font-size: 80%;
                    }
                  }
                }
              }
            }
            .Middle {
              display: flex;
              flex-direction: row;
              height:150px;
              margin-top: 0.5rem;
              position: relative;
              z-index: 3;
              //height : 0px;
              .ST-Holder {
                flex-basis: 33%;
                margin-right: 0.5rem;
                background-color: rgba($gn-white-color,0.05);
                position: relative;
                display: flex;
                flex-direction: column;
                .ST-Title {
                  height: 30px;
                  font-weight: bolder;
                  color: rgba( $gn-badge-color, 1 );
                  font-size: 100%;
                  line-height: 30px;
                  //background-color: rgba(255, 255, 255, 0.05);
                  padding: 0px 1rem;
                }
                .ST-Wrapper {
                  flex-grow: 1;
                  overflow: auto;
                  z-index:5;
                  overflow: auto;
                  position: relative;
                  .ST-Bar-Holder {
                    position: absolute;
                    top: 0.625rem;
                    left: 2.5rem;
                    bottom: calc(1rem + 30px);
                    right: 2.5rem;
                    flex-direction: row;
                    display: flex;
                    justify-content: center;
                    .ST-Line {
                        position: absolute;
                        width : 100%;
                        left:0px;
                        z-index : 99;
                        height:1px;
                        background-color: rgba($gn-white-color, 0.5);
                    }
                    .ST-Bar {
                        //border-top-left-radius: 0.25rem;
                        //border-top-right-radius: 0.25rem;
                        position: relative;
                        margin : 0px 0.3125rem;
                        float:left;
                        max-width: 150px;
                        transition: color .15s ease-in-out,background-color .15s ease-in-out, transform .15s ease-in-out;
                        &:hover {
                          background-color: rgba($gn-frame-color, 1) !important;
                          transform: scale3d(1.05, 1.05, 1.05);
                          z-index: 100;
                        }
                    }
                  }
                  .ST-Group {
                    position: absolute;
                    left: 2.5rem;
                    bottom: 0.625rem;
                    right: 2.5rem;
                    flex-direction: row;
                    display: flex;
                    height: 30px;
                    justify-content: center;
                    .ST-Group-Name {
                      position: relative;
                      margin : 0px 0.3125rem;
                      float:left;
                      background-color: rgba($gn-dark-color, 1);
                      color : rgba($gn-white-color, 1);
                      font-size: 70%;
                      height:30px;
                      line-height: 30px;
                      text-align: center;
                      cursor: default;
                      overflow: hidden;
                      max-width: 150px;
                      border-bottom-left-radius: 0.25rem;
                      border-bottom-right-radius: 0.25rem;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    }
                  }
                }
              }
              .Flow-Holder {
                flex-basis: 67%;
                height: 100%;
                background-color: rgba($gn-white-color,0.05);
                display: flex;
                flex-direction: column;
                .H-Title {
                  height:25px;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  font-weight: bolder;
                  color: rgba( $gn-badge-color, 1 );
                  font-size: 100%;
                  line-height: 30px;
                  padding: 0px 1rem; 
                }
                .Chart {
                  height : 100px;
                  width : 100%;
                }
                .Legend {
                  height:25px;
                  display: flex;
                  flex-direction: row;
                  background-color: rgba( $gn-black-color, 0.5);
                  padding: 0.25rem 0.5rem;
                  .Legend-Holder {
                    display: flex;
                    flex-direction: row;
                    &:first-child {
                      margin-right: 1.5rem;
                    }
                    .Legend-Circle {
                      width: 0.8rem;
                      height:0.8rem;
                      margin: auto 0rem;
                      margin-right: 0.25rem;
                      border-radius: 0.4rem;
                      &.Cash {
                        background-color: rgba( $gn-badge-color, 1  );
                      }
                      &.Onhold {
                        background-color: rgba( $gn-accent-color-3, 1  );
                      }
                    }
                    .Legend-Name {
                      color: rgba($gn-white-color, 1);
                      font-size: 80%;
                      cursor: default;
                    }
                  }
                }
              }
            }
            .Lower {
              margin-top: 0.5rem;
              display: flex;
              flex-direction: row;
              height:fit-content;              
              .SHolder {
                flex-basis: 50%;
                padding: 1rem;
                background-color:  rgba( $gn-white-color, 0.05  );
                &:first-child {
                  margin-right: 0.5rem;
                  border-bottom-left-radius: 0.25rem;
                }
                &:last-child{
                  border-bottom-right-radius: 0.25rem;
                }
                .STitle {
                  height: 30px;
                  font-weight: bolder;
                  color : rgba($gn-badge-color, 1);
                  font-size: 100%;
                  line-height: 30px;
                  //text-align: center;
                  //background-color: rgba( $gn-white-color, 0.05  );
                  padding: 0px 1rem;
                  margin-bottom: 0.5rem;
                }
                .B-Holder {
                  height:35px;
                  float:left;
                  width : calc( 100% - 1.25rem);
                  margin: 2.5px 0.625rem;
                  position: relative;
                  transition: all 0.25s ease-in-out;
                  &:hover {
                    transform: scale3d(1.05, 1.05, 1.05);
                  }
                  cursor: pointer;
                    .B-Symbol {
                      border-top-left-radius: 0.25rem;
                      border-bottom-left-radius: 0.25rem;
                      font-size: 100%;
                      width : 80px;
                      color :rgba( $gn-white-color, 1);
                      background-color: rgba($gn-dark-color, 1);
                      height:35px;
                      //line-height: 30px;
                      text-align: center;
                      position: absolute;
                      transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                      display: flex;
                      flex-direction: column;
                      &.Neg {
                        border-top-left-radius: 0rem;
                        border-bottom-left-radius: 0rem;
                        border-top-right-radius: 0.25rem;
                        border-bottom-right-radius: 0.25rem;
                      }
                      .B-Name {
                        font-size: 65%;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        color : rgba($gn-white-color,0.8);
                      }
                      &:hover {
                        background-color: rgba($gn-frame-color, 1) !important;
                        box-shadow: 0px 0px 5px rgba($gn-dark-color, 1);
                      }
                    }
                    .B-Bar-Holder {
                      width : calc(100% - 82px);
                      top:0px;
                      bottom:0px;
                      position: absolute;
                      left: 82px;
                      &.Neg {
                        right : 82px;
                        left : auto;
                        .B-Bar {
                          border-bottom-right-radius: 0rem;
                          border-top-right-radius: 0rem;
                          border-bottom-Left-radius: 0.25rem;
                          border-top-Left-radius: 0.25rem;
                        }
                      }
                      .B-Bar {
                        position : absolute;
                        background-color: rgba($gn-grey-color, 1);
                        top:0px;
                        bottom:0px;
                        color : rgba($gn-white-color, 1);
                        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                        border-bottom-right-radius: 0.25rem;
                        border-top-right-radius: 0.25rem;
                        &:hover {
                          background-color: rgba($gn-frame-color,1) !important;
                          box-shadow: 0px 0px 5px rgba($gn-dark-color, 1);
                        }
                      }
                    }
                }
              }
            }
          }
          .Table {
            //float:left;
            //width:100%;
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            border-top-color: rgba($gn-white-color, 0.1) ;
            .table-responsive {
              border-top-color: rgba($gn-white-color, 0.1) ;
            }
            table {
              //border-top-color: rgba($gn-white-color, 0.1) ;
              thead {
                tr {
                  background-color: rgba($gn-white-color, 0.1);
                  font-size: 110%;
                  font-weight: bold;
                }
              }
              tbody , thead {
                tr {
                  font-size: 90%;
                  height:25px;
                  color : rgba( $gn-white-color , 0.8);
                  transition: background-color 0.25s ease-in-out;
                  td {
                    padding:0.5rem 1rem;
                    border-top-color: rgba($gn-white-color, 0.1) ;
                    cursor: pointer;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                  }
                  .detail {
                    //background-color: rgba( $gn-white-color, 0.1 ) !important;
                    background-color: rgba( $gn-white-color, 0.1 );
                    color : rgba( $gn-white-color, 0.8 )
                  }
                  &:hover {
                    background-color: rgba( $gn-frame-color, 0.25 );
                  }
                  &.selected {
                    font-weight: bolder;
                    background-color:  rgba( $gn-frame-color, 0.5) ;
                    //margin-bottom: 0.5rem;
                    border-bottom: 2px solid rgba($gn-black-color, 0.5) ;
                    //box-shadow: 0px 0px 5px rgba($gn-black-color, 0.5) ;
                  }
                }
                .D-Record {
                  height:0px;
                  visibility: collapse;
                  transition: all 0.25s ease-in-out;
                  border-bottom: 0px solid rgba( $gn-white-color , 0.5);
                  opacity: 0;
                  td {
                    border-top-color: rgba($gn-white-color, 0) ;
                  }
                  &.show {
                    height:25px;
                    visibility: visible;
                    border-bottom: 1px solid rgba( $gn-white-color , 0.5);
                    opacity: 1;
                    td {
                      border-top-color: rgba($gn-white-color, .05) ;
                    }
                    &:last-child {
                      border-bottom: 2px solid rgba($gn-white-color, 0.5) ;
                    }
                  }
                  
                }
              }
            }
        }
          &.Selected {
            flex-grow: 1;
            z-index : 99;
            opacity: 1;
            overflow: initial;
            height:auto;
            
          }
        }
      }
    }
    .modal {
      display: block;
      &.Confirm-Frame {
        position: fixed;
        .Confirm {
          
          .modal-content {
            top: auto;
            bottom: auto;
            left: auto;
            right : auto;
            border-radius: .3rem;
            border: 1px solid rgba(0,0,0,.2);
            color : rgba($gn-white-color, 1);
            .error {
              color : rgba($gn-red-color, 1);
            }
          } 
        }
        .backdrop {
          background-color: rgba( $gn-white-color, 0.05 );
          //opacity: 0.5;
          backdrop-filter: blur(5px);
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 751px)  {
  .Garlican-Report {
      .R-Dialog {
        .modal-content {
          .Header {
            .Frame {
              .Title {
                .Name {
                  display: none;
                }
              }
            }
          }
          .Content {
            .Body {
              flex-direction: column;
              .Left {
                width :auto;
                &.hide {
                  width : auto !important;
                  overflow: initial;
                }
              }
              .Middle-Panel {
                display: none;
              }
              .Right { 
                .Middle {
                  //height:300px;
                  height:auto;
                  flex-direction: column;
                  .ST-Holder, .Flow-Holder {
                    margin: 0.5rem 0rem;
                    flex-basis: auto;
                    height:150px;
                    flex-shrink: 0;
                    
                  }
                }
              }
            }
          }
        }
      }
    }
}

.bs-tooltip-left, .bs-tooltip-right, .bs-tooltip-top, .bs-tooltip-bottom {
  z-index: 10001;
}