@import "../public/style.scss";

.Footer {
  display: none;
  width : 100%;
  float:left;
  height : 32.5px;
  position: absolute;
  bottom:0px;
  border-top: 2px solid rgba($gn-light-color, 1);
  z-index: 99;
  background-color: rgba($gn-white-color, 1);
  &.dark {
    background-color: rgba( $gn-white-color , 0.25 );
    box-shadow: 0 2px -5px 0 rgba( 255, 255, 255, 0.1 );
    backdrop-filter: blur( 2px );
    -webkit-backdrop-filter: blur( 2px );
    border-top: 0px solid rgba($gn-light-color, 1);
  }
  .Footer-Frame {
    //height : calc( 100px - 50px );
    height: 30px;
    width:100%;
    position: relative;
    top:0px;
    cursor:default;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .Footer-Logo {
      //float:left;
      width:15px;
      height:15px;
      /*padding : 2px 0px;*/
      margin: 7.5px 5px;
      margin-left: auto;
      /*border: 0px solid rgba($gn-white-color, 1);
      background-color: rgba($gn-frame-color, 1);
      color : rgba($gn-white-color, 1);
      border-radius: 7.5px;
      -moz-border-radius:7.5px;
      -webkit-border-radius:7.5px;*/
      line-height: 15px;
      font-weight: 600;
      font-size: 30px;
    }
    .Footer-Title {
      //@at-root:left;
      height:30px;
      /*padding : 2px 0px;*/
      margin: 0px 2.5px;
      //margin-left: auto;
      margin-right: 0.5rem;
      color: rgba($gn-dark-color, 1);
      border-radius: 7.5px;
      -moz-border-radius:7.5px;
      -webkit-border-radius:7.5px;
      line-height: 30px;
      font-size: 0.8rem;
      font-weight: 600;
      &.dark {
        color: rgba($gn-white-color, 1);
      }
    }
    .Footer-Frame-Control {
      float:right;
      height:40px;
      margin: 0px 5px;
      .Footer-Frame-Control-a {
        padding: 5px;
        text-decoration: none;
        line-height: 40px;
        color: rgba($gn-black-color, 1);
        font-size:0.8rem;
        transition: color 0.15s ease-in-out;
        cursor: pointer;
      }
      .Footer-Frame-Control-a:hover {
        color : rgba($gn-frame-color, 1);
      }
    }
    .Footer-Trader {
      margin-left: 5px;
      margin-right: auto;
      
      //margin-top: -5px;
      height:15px;
      line-height: 15px;
      //border-radius: 0px;
      padding-left:1.5rem;
      padding-right:1.5rem;
      margin-top: 4px;
      border : 0px solid rgba($gn-white-color, 1) !important;
      //border-top-left-radius : 25% 50%;
      //border-top: 2px solid rgba($gn-light-color, 1) !important;
      //border-left: 2px solid rgba($gn-light-color,1) !important;
      font-weight: bolder;
      transition: transform, box-shadow 0.25s ease-in-out;
      &.dark {
        border : 0px solid rgba($gn-white-color,1) !important;
      }
      .fa {
        font-size: 15px;
        //visibility: visible;
      }
      .text {
        font-size: 12.5px;
        font-weight: bolder;
        //visibility: hidden;
      }
      &:hover {
        transform: scale3d(1.1, 1.1, 1.1);
        box-shadow: 0px 0px 5px rgba($gn-black-color, 0.5);
        .fa {
          //visibility: hidden;
        }
        .text {
          //visibility: visible;
        }
      }
      //border-top-left-radius: 0.25rem;
      //border: 5px solid rgba($gn-white-color, 1);
    }
  }
}

@media (orientation: portrait) {
  .Footer {
 
  }
}

@media screen and (min-width: 960px)  {
  .Footer {
      //left : calc( 50% - 480px );
      //right : calc( 50% - 480px );
      //width : 960px;    
  } 
}

@media screen and (max-width: 750px)  {
  .Footer {
    height:30px;
    .Footer-Frame {
      height:30px;
      top:0px;
      .Footer-Logo {
        margin: 7.5px 5px;
        display: none;
      }
      .Footer-Title {
        margin-left:5px;
        line-height: 30px;
        height:30px;
        font-size: 0.75rem;
        /*width:100px;*/
      }
      .Footer-Frame-Control {
        height:30px;
        
        .Footer-Frame-Control-a {
          font-size: 0.75rem;
          line-height: 30px;
        }
      }
    }
  }
}