@import "../../public/style.scss";

.Stripe-Dialog {
  width: fit-content;
  .Stripe-Modal {
    background-color: transparent !important;
    border-width: 0px;
    width: fit-content;
    cursor: default;
    .Stripe-Header {
      display: none;
      border-bottom: 0px solid rgba( $gn-black-color, 0.05 );
      .modal-title {
        color : rgba( $gn-white-color, 1 );
      }
    }

    .Stripe-Wrapper {
      display: flex;
      flex-direction: row;
      min-height: 50vh;
      background-color: rgba( $gn-white-color, 0.9 );
      backdrop-filter: blur(5px);
      border-radius: 1rem;
      
      .Stripe-Holder {
        display: flex;
        flex-direction: column;
        width :300px;
        min-height: 450px;
        margin:auto;
        border : 1px solid rgba( $gn-black-color, 0.05 );
        transition: all 0.25s ease-in-out;
        border-radius: 0.25rem;
        height: fit-content;
        overflow: hidden;
        margin-right: 1rem;
        .Stripe-Price {
          display: flex;
          flex-direction: row;
          margin: 0px 0rem;
          padding:0.5rem 0rem;
          text-align: center;
          background-color: rgba( $gn-frame-color, 1);
          transition: all 0.25s ease-in-out;
          cursor: pointer;
          &:hover {
            transform: scale(1.025);
            background-color: rgba($gn-badge-color, 1);
            .Text, .Currency, .Smaller{
              color : rgba($gn-black-color, 1) !important;
            }
          }
          &.Free {
            background-color: rgba( $gn-frame-color-2, 1);
            cursor: default;
            .Text {
              margin: auto;
            }
            &:hover {
              background-color: rgba( $gn-frame-color-2, 1);
              color : rgba($gn-white-color, 1);
            }
          }
          .Currency {
            margin: auto 0px;
            margin-left: auto;
            font-size: 15px;
            color : rgba( $gn-white-color, 0.8 );
            font-weight: bold;
          }
          .Text {
            margin: auto 0.5rem;
            margin-right: 0px;
            font-size: 40px;
            color : rgba( $gn-white-color, 1 );
            font-weight: bolder;
            
          }
          .Smaller {
            margin: auto 0px;
            margin-right: auto;
            font-size: 15px;
            color : rgba( $gn-white-color, 0.8 );
            font-weight: bold;
          }
        }
        .Stripe-Scheme {
          display: flex;
          flex-direction: column;
          .Title {
            display: flex;
            flex-direction: row;
            margin: 0px auto;
            padding: 1rem 0rem;
            color : rgba( $gn-black-color, 0.9 );
            font-weight: bold;
            font-size: 120%;
            text-align: center;
          }
          .Plan {
            display: flex;
            flex-direction: column;
            .Section {
              display: flex;
              flex-direction: column;
              padding: 1rem 0.25rem;
              .Stitle {
                color : rgba( $gn-black-color, 0.9 ); 
                margin: 0px auto;
                padding: 0.5rem 0rem;
                font-weight: bold;
                border-bottom: 1px solid rgba( $gn-frame-color, 0.05);
                transition: all 0.25s ease-in-out;
                cursor: pointer;
                &:hover {
                  transform: scale(1.025);
                  color:rgba( $gn-frame-color, 0.9 );
                }
                &.Free {
                  border-bottom: 1px solid rgba( $gn-frame-color-2, 0.05);
                }
              }
              .List {
                display: flex;
                flex-direction: row;
                padding: 0rem 0.5rem;
                .Desc {
                  flex-basis: 70%;
                  text-align: left;
                  color : rgba( $gn-black-color, 0.8 ); 
                  margin: auto 0rem;
                }
                .Val {
                  flex-basis: 30%;
                  text-align: right;
                  color : rgba( $gn-frame-color, 0.9 ); 
                  font-weight: bold;
                  margin: auto 0rem;
                  &.Free {
                    color : rgba( $gn-frame-color-2, 0.9 ); 
                  }
                }
              }
            }
          }
        }
        .Pay-btn {
          margin-top: auto;
          &.Free {
            background-color: rgba( $gn-frame-color-2, 1) !important;
            border: 1px solid rgba( $gn-frame-color-2, 1) !important;
            &:hover {
              background-color: rgba( $gn-frame-color-2, 1) !important;
              color : rgba( $gn-white-color, 1) !important;
            }
          }
        }
        &:hover {
          transform: scale(1.025);
          box-shadow: 0px 0px 10px rgba( $gn-white-color, 0.1 );
        }
        &:first-child {
          border-top-left-radius: 1rem;
          border-bottom-left-radius: 1rem;
        }
        &:last-child {
          margin-right: 0rem;
          border-top-right-radius: 1rem;
          border-bottom-right-radius: 1rem;
        }
      }
    }
  }
}

@media screen and (max-width: 950px)  {
  .Stripe-Dialog {
    width:100%;
    margin: 4rem auto;
    flex-direction: column;
    .Stripe-Modal {
      .Stripe-Wrapper {
        flex-direction: column-reverse;
        .Stripe-Holder {
          margin-right: 0rem;
          &:last-child {
            border-radius: 0px;
            margin-bottom: 1rem;
            border-top-left-radius: 1rem;
            border-top-right-radius: 1rem;
          }
          &:first-child {
            margin-right: 0rem;
            border-radius: 0px;
            border-bottom-left-radius: 1rem;
            border-bottom-right-radius: 1rem;
          }
        }
      }
    }
  }
}