@import "../../public/style.scss";

.terms-container {
  float:left;
  position: relative;
  top:0px;
  left:0px;
  right:0px;
  //bottom:0px;
  height:calc( 100vh - 40px);
  overflow: auto;
}

.terms-controller {
  background-color: rgba($gn-dark-color, 1);
  padding-top:10px;
  padding-bottom:10px;
  margin-bottom: 0px;
  width : 100%;
  line-height: 40px;
  height: 40px;
  text-align: left;
}

.terms-button {
  float : left;
  height:40px;
  line-height : 40px;
  padding : 0px 20px;
  margin-left: 10px;
  cursor: pointer;
  text-decoration: none;
  background-color: rgba($gn-frame-color, 1);
  color : rgba($gn-white-color, 1);
  border-radius: 2.5px;
}

.terms-button:hover {
  text-decoration: none;
  background-color: rgba($gn-green-color, 1);
  color : rgba($gn-white-color, 1);
}


.terms-header {
  font-size:25px;
  padding-top:40px;
  padding-bottom:40px;
  margin-bottom: 20px;
  text-align: center;
  background-color: rgba($gn-frame-color, 1);
  color : rgba($gn-white-color, 1);
}

.terms-sub-header {
  font-size: 20px;
  padding: 25px 10%;
  width:80%;
  text-align: center;
  color : rgba($gn-black-color, 1); 
}

.terms-content {
  font-size: 15px;
  padding: 25px 10%;
  text-align: left;
  white-space: pre-line;
  color : rgba($gn-black-color, 1);
  &.red-color-text {
    color : rgba($gn-red-color, 1);
  }
  &:last-child {
    //padding-bottom: 10rem;
  }
}
.terms-content2 {
  font-size: 12.5px;
  padding: 25px 10%;
  padding-top: 0px;
  text-align: left;
  white-space: pre-line;
  margin-top: -25px;
  color : rgba($gn-green-color, 1);
}


.terms-sub-content {
  font-size: 15px;
  padding: 5px 15%;
  text-align: left;
  white-space: pre-line;
  color : rgba($gn-black-color, 1);
}

