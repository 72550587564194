@import "../../public/style.scss";

.tooltip {
  //background-color: rgba( $gn-black-color , 0.8 );
  &.hint {
    .Msg {
      color: rgba( $gn-white-color, 1 );
    }
    .Sub {
      margin-top: 0.5rem;
      color: rgba( $gn-white-color, 0.75 );
      font-size: 75%;
      text-align: left;
    }
  } 
}


.Filter {
    //flex-grow: 1;
    width:100%;
    position: relative;
    overflow: hidden;
    height: calc( 100% - 0px);
    top: 0px;
    flex-direction: column;
    display: flex;

    .navigator {
      height: 150px;
      //background-color: red;
      flex-shrink: 0;
      border-bottom: 1px solid rgba( $gn-black-color ,0.05 );
    }

    .main{
      flex-grow: 1;
      overflow: hidden;
      position: absolute;
      left: 0px;
      right: 0px;
      bottom: 0px;
      top: 151px;
      z-index: 5;
      flex-shrink: 0;
      display: flex;
      flex-direction: row;
      padding-top:00px;
      //height: calc( 100vh - 100px);
      .Wrapper {
        flex-grow: 1;
        overflow: hidden;
        position: relative;
        z-index: 5;
        flex-shrink: 0;
        display: flex;
        flex-direction: row;
        padding-top:00px;
        .Panel {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          position: sticky;
          top:0px;
          left:0rem;
          width: 642px;
          bottom: 0px;
          height: auto;
          z-index:20;
          overflow:hidden;
          //overflow-y:auto;
          //background-color: rgba(0, 0, 0, 1.0);
          //box-shadow: 0 0px 5px 0 rgba(33, 33, 33 , 0.37);
          background-color:  rgba( $gn-light-color , 1.0);
          backdrop-filter: blur(2px);
          -webkit-backdrop-filter: blur(2px);
          flex-shrink: 0;
          border-right: 1px solid rgba( $gn-frame-color , 0.1 );
          transition: all 0.25s ease-in-out;
          &.Hide {
            width : 0px;
            overflow: hidden;
          }
          .Lists {
            width: 320px;
            border-right: 1px solid rgba( $gn-frame-color , 0.1 );
            display: flex;
            flex-direction: column;
            height:100%;
            position: relative;
            z-index : 10;
            .Top {
              display: flex;
              flex-direction: row;
              height:59px;
              flex-shrink: 0;
              background-color: rgba( $gn-white-color , 1  );
              border-bottom: 1px solid rgba( $gn-black-color , 0.1 );
              .Simple {
                margin: auto 0.5rem;
                background-color: rgba( $gn-frame-color, 1 );
                color : rgba($gn-white-color, 1) ;
                cursor: pointer;
                border-radius: 0.5rem;
                height:48px;
                padding: 0rem 0.5rem;
                opacity: 1;
                transition: all 0.25s ease-in-out;
                display: flex;
                flex-direction: column;
                justify-content: center;
                &.Selected {
                  opacity: 0.5;
                }
              }
              .Search {
                border-radius: 0rem;
                border-top-left-radius: 0.5rem;
                border-bottom-left-radius: 0.5rem;
                border: 2px solid rgba( $gn-frame-color, 1 );
                color : rgba( $gn-black-color , 0.8 );
                flex-grow: 1;
                margin: auto;
              }
              .Fa {
                width:15px;
                height: 48px;
                background-color : rgba( $gn-frame-color, 1 );
                color : rgba( $gn-white-color, 1 );
                font-size: 120%;
                transition: all 0.25s ease-in-out;
                padding:0px 1rem;
                margin: auto 0rem;
                margin-right: 0.5rem;
                border-top-right-radius: 0.5rem;
                border-bottom-right-radius: 0.5rem;
                &:hover {
                  background-color : rgba( $gn-badge-color, 1 );
                }
              }
            }
            .Bottom {
              display: flex;
              flex-direction: column;
              flex-grow: 1;
              flex-shrink: 0;
              height: calc( 100% - 60px);
              .LHolder {
                display: flex;
                flex-direction: column;
                border-bottom: 1px solid rgba( $gn-black-color , 0.1 );
                .Title {
                  background-color: rgba($gn-white-color, 1);
                  color :rgba( $gn-black-color , 0.8 );
                  //height:40px;
                  padding: 1rem 1.5rem;
                  cursor: pointer;
                  transition: all 0.25s ease-in-out;
                  display: flex;
                  flex-direction: row;
                  flex-shrink: 0;
                  .Fa {
                    transition: all 0.25s ease-in-out;
                    color : rgba( $gn-black-color , 0.5 );
                    margin: auto  0.5rem;
                    margin-left: auto;
                    font-size: 80%;;
                  }

                  &:hover {
                    background-color: rgba($gn-badge-color, 0.25); 
                  }
                }
                .Container {
                  height: 0px;
                  flex-grow: 0;
                  overflow: hidden;
                  display : flex;
                  flex-direction: row;
                  flex-wrap: wrap;
                  transition: all 0.25s ease-in-out;
                  opacity: 0;
                  gap : 0rem 0rem;
                  .Bgn {
                    //display: flex;
                    //flex-direction: column;
                    //justify-content: center;
                    padding:0.25rem 1rem;
                    background-color: rgba( $gn-white-color , 1 );
                    border: 2px solid rgba( $gn-frame-color , 1 );
                    color : rgba( $gn-frame-color , 1 );
                    border-radius: 1rem;
                    margin: 0.25rem 0.25rem;
                    //height:25px;
                    transition: all 0.25s ease-in-out;
                    font-size: 90%;
                    text-overflow: ellipsis;
                    white-space: pre;
                    max-width: 100%;
                    overflow: hidden;
                    cursor: pointer;
                    &:hover {
                      background-color: rgba( $gn-frame-color , 1 );
                      color : rgba( $gn-white-color , 1 );
                    }
                  }
                }
                &.Selected {
                  flex-grow: 1;
                  .Title {
                    background-color: rgba($gn-frame-color, 1); 
                    color :rgba( $gn-white-color , 1 );
                    .Fa {
                      color :rgba( $gn-white-color , 1 );
                    }
                  }
                  .Container {
                    //height: auto;
                    flex-grow: 1;
                    overflow: auto;
                    background-color: rgba( $gn-light-color , 1 );
                    opacity: 1;
                    
                    padding:1rem;
                    align-content: flex-start;
                    
                    
                  }
                }
              }
            }
          }
          .Query {
            width: 320px;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            height:100%;
            position: relative;
            z-index : 5;
            .Top {
              display: flex;
              flex-direction: row;
              height:59px;
              flex-shrink: 0;
              background-color: rgba( $gn-white-color , 1  );
              border-bottom: 1px solid rgba( $gn-black-color , 0.1 );
              cursor: default;
              .Msg {
                color :rgba( $gn-black-color , 0.8 );
                margin: auto 0.5rem;
                flex-grow: 1;
                text-align: left;
                //text-align: left;
              }
              .Search {
                padding:0px 1rem;
                background-color : rgba( $gn-frame-color, 1 );
                color : rgba( $gn-white-color, 1 );
                display: flex;
                flex-direction: row;
                //height: 48px;
                margin:0.5rem 0.5rem;
                border-radius: 0.5rem;
                cursor: pointer;
                transition: all 0.25s ease-in-out;  
                .Text {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  
                }
                .Fa {
                  width:15px;
                  font-size: 120%;
                  
                  margin: auto 0.5rem;
                }
                &:hover {
                  background-color : rgba( $gn-badge-color, 1 );
                }
              }
            }
            .Bottom {
              display: flex;
              flex-direction: column;
              flex-grow: 1;
              flex-shrink: 0;
              height: calc( 100% - 60px);
              overflow: auto;
              .List {
                display: flex;
                flex-direction: column;
                background-color: rgba( $gn-white-color , 1  );
                border-bottom: 1px solid rgba( $gn-black-color ,0.1 );
                padding:1rem 0.5rem;
                z-index :1;
                position: relative;
                &:hover {
                  z-index: 5;
                }
                .Upper {
                  display: flex;
                  flex-direction: row;
                  position: relative;
                  z-index : 1;
                  .Title {
                    border-radius: 1rem;
                    padding : 0.25rem 0.5rem;
                    margin : 0.25rem 0.25rem;
                    border: 2px solid rgba( $gn-frame-color , 1 );
                    color : rgba( $gn-frame-color , 1 );
                    cursor: default;
                    text-align: left;
                    .Fa {
                      color : rgba( $gn-frame-color , 1 );
                      font-size: 100%;
                      margin: auto 0.5rem;
                    
                    }
                  }
                  .Remove {
                    margin: auto 0.5rem;
                    margin-left: auto;
                    background-color: rgba( $gn-frame-color , 1 );
                    color : rgba( $gn-white-color , 1 );
                    opacity: 0.5;
                    padding:0.25rem;
                    transition: all 0.25s ease-in-out;
                    &:hover {
                      opacity: 1;
                    }
                  }
                }
                .Middle {
                  display: flex;
                  flex-direction: row;
                  width:100%;
                  position: relative;
                  z-index : 5;
                  overflow: initial;
                  .Opr {
                    margin: 0.5rem 0.5rem;
                    margin-right: auto;
                    .dropdown-menu {
                      width: fit-content;
                      .dropdown-item {
                        width : auto;
                      }
                    }
                  }
                }
                .Lower {
                  display: flex;
                  flex-direction: row;
                  width:100%;
                  position: relative;
                  z-index : 1;
                  .Box1, .Box2 {
                    margin: 0.5rem 0.5rem;
                    margin-top:0.25rem;
                    width : calc( 100% - 1rem - 30px);
                    .form-control {
                      width: calc( 100% - 1rem );
                      padding:0.5rem 0.5rem;
                      border : 2px solid rgba($gn-frame-color, 1);
                      border-radius: 0.5rem;
                    }
                  }
                  .Box2 {
                    border : 2px solid rgba($gn-frame-color, 1);
                    border-radius: 0.5rem;
                    width : calc( 100% - 2.5rem - 30px);
                    padding:0.25rem 0.5rem;
                  }
                }
              }
            }
          }
        }
        .Result {
          display: flex;
          flex-direction: column;
          width:calc( 100% - 642px );
          position: relative;
          border-width: 0px;
          transition: all 0.25s ease-in-out;
          &.Full {
            width : 100%;
          }
          .Control {
            height:59px;
            border-bottom: 1px solid rgba($gn-black-color, 0.1);
            background-color: rgba( $gn-white-color , 1  );
            display: flex;
            flex-direction: row;
            .Fa {
              color : rgba( $gn-frame-color , 1 );
              border-right: 1px solid rgba( $gn-black-color , 0.1 );
              transition: all 0.25s ease-in-out;
              padding : 20px 20px;
              height : 19px;
              width : 20px;
              //opacity: 0.5;
              &:hover {
                color : rgba( $gn-white-color , 1 );
                background-color: rgba( $gn-frame-color , 1 );
                //opacity: 1;
              }
            }
            .Count {
              margin: auto 0rem;
              margin-left: 1rem;
            }
          }
          .Cont {
            position: absolute;
            bottom:00px;
            left:0px;
            right:0px;
            
            top:60px;
            height : auto;
            //height:100vh;
            //overflow: hidden;
            overflow: auto;
            //overflow-y: hidden;
            //overflow-x: auto;
            flex-direction: column;
            display: flex;
            flex-wrap: nowrap;
            //padding-top: 155px;
            //padding-bottom: 100px;
            z-index: 1;
            .list {
              width: max-content;
              background-color: rgba($gn-white-color, 1);
              //box-shadow: 0 0px 5px 0 rgb(33, 33, 33 / 37%);
              //backdrop-filter: blur(1px);
              //-webkit-backdrop-filter: blur(1px);
              //border-bottom: 0px solid #000000;
              //width: calc( 100% - 1rem);
              margin: 0px 0.5rem;
              margin-bottom: 5px;
              margin-left: 0.5rem;
              transition: all 0.25s ease-in-out;
              display: flex;
              flex-direction: row;
              justify-content: center;
              cursor: pointer;
              position: relative;
              z-index: 5;
              flex-wrap: nowrap;
              //height:40px;
              z-index : 1;
              &.title {
                position: sticky;
                z-index:99;
                top:0px;
                background-color: rgba( $gn-frame-color , 0.8 );
                height:auto !important;
                box-shadow: 0 0px 5px 0 rgba(33, 33, 33 , 0.37);
                backdrop-filter: blur(1px);
                -webkit-backdrop-filter: blur(1px);
                padding : 5px 0px;
                .box {
                  position: relative;
                  height:auto !important;
                  transition: all 0.25s ease-in-out;
                  flex-direction: column;
                  
                  color: rgba( $gn-white-color , 1 );
                  font-weight: bold;
                  &:hover{
                    background-color: rgba( $gn-frame-color , 0.25 );
                  }
                  
                }
                .fa {
                  font-size: 80%;
                  padding:2.5px;
                  color : rgba($gn-white-color, 1);
                  background-color: rgba( $gn-badge-color, 0.5);
                  margin: auto 0px;
                  //margin-right: auto;
                  margin-left: 0.5rem;
                  border-radius: 0.25rem;
                }
              }
              &:hover:not(.title) {
                background-color: rgba( $gn-frame-color , 0.25 );
              }
              &.show {
                z-index: 9;
              }
              .box {
                color : rgba( $gn-black-color ,1 );
                //flex-basis: 25%;
                //line-height: 40px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                font-size: 90%;
                width : 150px;
                text-align: center;
                //height: 30px;
                height: 35px;
                //overflow: hidden;
                position: relative;
                z-index : 1;
                &:not(.fa) {
                  span {
                    //margin-right: 0px;
                  }
                }
                span {
                  //margin-left: auto;
                  //margin-right: auto;
                }
                //float:left;
                &.symbol {
                  position: sticky;
                  left:0.25rem;
                  z-index:99;
                  background-color: rgba($gn-frame-color, 0.8);
                  color : rgba( $gn-white-color ,1 );
                  box-shadow: 0 0px 5px 0 rgba(33, 33, 33 , 0.37);
                  backdrop-filter: blur(2px);
                  -webkit-backdrop-filter: blur(2px);
                }
                &.action {
                  //width:39px;
                  margin-right: 1px;
                  position: sticky;
                  left:0px;
                  z-index:99;
                  width:0px;
                  background-color: rgba($gn-white-color, 0.8);
                  display: none;
                  box-shadow: 0 0px 5px 0 rgba(33, 33, 33 , 0.37);
                  backdrop-filter: blur(2px);
                  -webkit-backdrop-filter: blur(2px);
                  .buy {
                    background-color: rgba( $gn-green-color , 0.25);
                    color : rgba($gn-white-color, 1);
                    padding:5px 0px;
                    height:100%;
                  }
                }
                .menu {
                  position: absolute;
                  top: 20px;
                  left: 40%;
                  width:auto;
                  height:auto;
                  background-color: rgba( $gn-black-color , 0.8 );
                  backdrop-filter: blur(2px);
                  opacity: 0;
                  transition: opacity 0.25s ease-in-out;
                  box-shadow: 0px 0px 5px rgba($gn-black-color, 0.5);
                  flex-direction: column;
                  display: flex;
                  justify-content: space-evenly;
                  .title {
                    height:25px;
                    line-height: 25px;
                    border-bottom: 1px solid rgba( $gn-white-color , 0.25 );
                    font-size: 90%;
                    padding:0.25rem 0.5rem;
                    //font-weight: bolder;
                    background-color: rgba( $gn-black-color ,1 );
                    min-width: max-content;
                    width: calc( 100% - 1rem);
                    color : rgba($gn-badge-color , 0.8);
                  }
                  .item {
                    height:25px;
                    line-height: 25px;
                    border-bottom: 1px solid rgba( $gn-white-color , 0.25 );
                    padding:0.25rem 1rem;
                    min-width: max-content;
                    //width: calc( 100% - 1rem);
                    transition: background-color 0.25s ease-in-out;
                    font-size: 90%;
                    &:last-child {
                      border-bottom-style: none;
                    }
                    &:hover {
                      background-color: rgba( $gn-badge-color , 0.25 );
                    }
                  }
                  &.show {
                    opacity : 1;
                  }
                }
              }
            }
            &.cont_no {
              padding-top: 103px;
            }
          }
        }
        &.appear {
          width : 100%;
          z-index : 10;
        }
        &.disappear {
          width : 0%;
          overflow: hidden;
          z-index : 1;
          flex-grow: initial;
          flex-shrink: 1;
        }

      }


    }
    .controller {
      display: none;
      background-color: rgba( $gn-black-color , 1 );
      padding : 0px 0px;
      padding-top:0px;
      width:40px;
      border-left: 1px solid rgba( $gn-light-color , 0.2 );
      position: relative;
      top:100px;
      //bottom:30px;
      height : calc( 100vh - 62px - 40px);
      .icon {
        width:15px;
        height:15px;
        padding:12.5px;
        color:rgba( $gn-white-color, 0.5  );
        background-color: rgba( $gn-white-color, 0.1  );
        transition: transform, background-color, color 0.25s ease-in-out;
        box-shadow: 0 0px 5px 0 rgba(33, 33, 33 , 0.37);
        backdrop-filter: blur(2px);
        -webkit-backdrop-filter: blur(2px);
        z-index:999;
        &:hover {
          background-color: rgba( $gn-badge-color, 0.25  ) !important;
          transform: scale3d(0.9, 0.9, 0.9) !important;
          color:rgba( $gn-white-color, 1  );
        }
      }
    }
    
    .pboard {
      display: none;
      width:0px;
      position: relative;
      top: 70px;
      height: calc( 100vh - 105px);
      transition: width 0.25s ease-in-out;
      overflow: hidden;
      z-index: 1000;
      margin-right: -1px;
      flex-grow: 0;
      flex-shrink: 0;
      box-shadow: 0px 0px 5px rgba( $gn-black-color, 0.5);
      border-left : 1px solid rgba( $gn-white-color, 0.05 );
      border-top : 1px solid rgba( $gn-white-color, 0.05 );
      border-bottom: 1px solid rgba( $gn-white-color, 0.05 );
      &.appear {
        //width : calc( 50vw - 15px );
        width : 100%;
        &.smaller {
          width : calc( 330px - 15px );
          //width : 100%;
        }
        //max-width: 300px;
        //width:300px;
        //visibility:visible;
        //opacity: 1;
      }
    }
}

@media screen and (max-width: 750px)  {
  .Filter {
    .panel {
      position: relative;
    }
    .pboard {
      &.appear {
        width :100%;
        position:absolute;
      }
    }
    .main {
      .Wrapper {
        overflow: auto hidden;
        scroll-behavior: smooth;
        .Panel {
          position: relative;
        }
        .Result {
          min-width: 320px;
        }
      }
    }
  }
}