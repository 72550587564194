@import "../public/style.scss";



.hidden {
  display : none;
}

.Portfolio-Holder.fade {
  .modal-dialog {
    transform: translate(100%,0px);
  }
}

.Portfolio-Holder.show {
  .modal-dialog {
    transform: none;
  }
}

.Portfolio-Holder {
  &.modal {
    background: transparent;
  }
  //background-color: rgba($gn-white-color, 1) !important;
  //box-shadow: 0px 0px 10px rgba($gn-dark-color, 0.5);
  border-left: 1px solid rgba( $gn-black-color, 0.1 );
  border-bottom: 1px solid rgba( $gn-black-color, 0.1 );
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: 0px 0px 10px rgba($gn-white-color, 0.5);
  backdrop-filter: blur( 10px );
  -webkit-backdrop-filter: blur( 10px );
  background-color: rgba($gn-white-color, 0.95) !important;;
  

  position : fixed;
  top:2px;
  right:0px;
  bottom:2px;
  width : 30%;
  max-width: 400px;
  min-width:320px;
  height:auto;
  /*max-width: 400px;*/
  left: auto;
  display: flex !important;
  flex-direction: row;
  justify-content: center;

    .modal-95w {
        /*width: 100%;
        max-width: none!important;
        margin : 0px;
        border: 0px;
        border-radius: 0px;
        position: absolute;
        top:0px;
        left:0px;
        right:0px;
        bottom: 0px;*/
        position : absolute;
        top:0px;
        //right:0px;
        bottom:0px;
        width:100%;
        max-width: 400px;
        
        margin: 0px !important;
        overflow: hidden;
        max-width: 400px;
        transition: transform .3s ease-in-out;
        .modal-content {
          /*border-radius: 0px;
          position: absolute;
          top:0px;
          left:0px;
          right:0px;
          bottom: 0px;
          border : 0px;*/
          height : 100%;
          //border-top:none !important;
          //border-right:none !important;
          //border-bottom:none !important;
          border : none !important;
          border-radius: 0px !important;
          display: flex;
          flex-direction: column;
          position: relative;
          overflow: hidden;
          background-color: transparent !important;
          
        }
    }
    .Portfolio-Header {
        position: relative;
        width : calc( 100% - 1rem);
        //line-height: 60px;
        text-align: center;
        margin-bottom: 5px;
        display: flex;
        flex-direction: column;
        border-bottom: 0px solid transparent!important;
        padding-bottom: 0rem;
        padding-right: 0rem;
        .close {
          line-height: 50px;
        }
        .Portfolio-Header-Frame {
          flex-direction: row;
          display: flex;
          justify-content : flex-start;
          width:100%;
          height:40px;
          .share {
            background-color: transparent!important;
            border: 0px solid rgba( $gn-frame-color , 1);
            
          }
          .share , .remove {
            margin: auto 0px;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            margin-left: 0.5rem !important;
            .Fa {
              color :rgba( $gn-frame-color , 1);
              transition: all 0.25s ease-in-out;
              &:hover {
                color :rgba( $gn-accent-color , 1);
              }
            }
          }
          .remove {
            background-color: rgba($gn-white-color, 0) !important;
            border-color: rgba($gn-red-color,1) !important;
            border-radius: 1rem;
            .Fa {
              color : rgba($gn-red-color,1) !important;
            }
            
          }
          .onhold {
            margin-left: auto;
            height : 40px;
            color : rgba($gn-white-color, 1);
            display: flex;
            flex-direction: column;
            border-top-left-radius: 1rem;
            border-bottom-left-radius: 1rem;
            overflow: hidden;
            
            cursor: default;
            span {
              line-height: 15px;
              font-size: 80%;
              background-color: rgba($gn-frame-color, 1);
              padding:0px 0.5rem;
              padding-top:0px;
              padding-left: 1rem;
              width:60px;
              font-weight: bolder;
              &:last-child {
                line-height: 25px;
                font-size: 110%;
                background-color: rgba($gn-frame-color-2, 1);
                padding-bottom:2.5px;
                padding-left: 1rem;
              }
            }
          }
          .nohold {
            span {
              background-color: rgba($gn-frame-color, 1) !important;
              &:last-child {
                line-height: 25px;
                font-size: 110%;
                background-color: rgba( $gn-frame-color-2, 1) !important;
                padding:0px 0.5rem;
                padding-bottom:2.5px;
                padding-left: 1rem;
              }
            }
          }
        }
        .Portfolio-Header-Title {
          height:40px;
          /*padding : 2px 0px;*/
          margin: 5px 2.5px 0px 2.5px;
          color: rgba($gn-frame-color, 1);
          border-radius: 7.5px;
          -moz-border-radius:7.5px;
          -webkit-border-radius:7.5px;
          line-height: 40px;
          font-weight: bolder;
          font-size: 100%;
          cursor: default;
          overflow: hidden;
          display: flex;
          flex-direction: row;
          position: relative;
          .Badge {
            //font-size: 90%;
            margin: auto 0rem;
            color : rgba( $gn-frame-color-3, 1 );
            border: 1px solid rgba( $gn-frame-color-3, 1 );
            //color:rgba( $gn-white-color, 1 );
            width: calc( 25px - 0.5rem);
            height: calc( 25px - 0rem);
            border-radius: 50%;
            //padding:0.25rem 0.5rem;
            padding-left: 0.5rem;
            //margin-left: 0.5rem;
            font-size: 100%;
            //width: fit-content;
            white-space: nowrap;
            //height: fit-content;
          }
          .Region {
            margin:auto 0px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            background-color: rgba( $gn-frame-color , 0);
            height : fit-content;
            //position: absolute;
            //top: 0.75rem;
            //margin-left: 0.25rem;
            .react-emojis {
              font-size: 20px;
              font-weight: 400;
              margin: auto 0.5rem;
              //margin-top: 0.5rem;
            }
          }
        }
        .share-board {
          transition: height,opacity 0.25s linear;
          position : absolute;
          top:60px;
          left:0px;
          right:0px;
          padding:5px;
          background-color: rgba($gn-white-color, 1);
          border-bottom: 1px solid rgba($gn-dark-color, 0.2);
          z-index: 10;
          overflow: hidden;
          button {
            border-top-left-radius : 0px;
            border-bottom-left-radius : 0px;
            height: 33px;
          }
          &.show{
            opacity: 1;
            height: 60px;
          }
          &.hide{
            opacity: 0;
            height : 0px;
          }
        }
        .app-tri-holder {
          .app-btn {
            width: auto;
          }
          .Fa {
            color : rgba( $gn-frame-color, 1 );
            transition: all 0.25s ease-in-out;
            &:hover {
              transform: scale3d(1.2, 1.2, 1.2);
                color :rgba( $gn-accent-color , 1);
            }
          }
        }
    }
    
    .Portfolio-User-Holder {
      float: left;
      position: relative;
      width: calc( 100% - 1.25rem);
      margin:0rem 0rem;
      margin-top: -0.5rem;
      background-color: transparent;
      display: flex;
      flex-direction: column;
      .container {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        padding:0px;    
        .Portfolio-User-Holder-Left {
          float:left;
          margin-left:auto;
          margin-right: 0rem;
          //line-height: 40px;
          //height:40px;
          .Portfolio-User-Pic {
            float:left;
            width:15px;
            height: 15px;
            margin: 10px 0px;
            margin-left:7.5px;
            color : rgba($gn-white-color, 1);
            background-color: rgba($gn-frame-color, 1);
            padding:2.5px;
          }
          .Portfolio-User-Name {
            float:left;
            height:40px;
            line-height: 40px;
            margin-left: 5px;
            cursor: pointer;
          }
        }
        .Portfolio-User-Holder-Right {
          //float:right;
          height:auto;
          line-height: 15px;
          color : rgba($gn-black-color, 0.4);
          font-size: 10px;
          margin-left: 1.5rem;
          display: flex;
          flex-direction: row;
          text-transform: uppercase;
          font-weight: light;
          span {
            &:last-child {
              margin-left: 0.5rem;
            }
          }
        }
      }
      
    }

    .Portfolio-Control {
      position: relative;
      //height:40px;
      //line-height: 40px;
      display: flex;
      flex-direction: row;
      padding : 1rem 0.75rem;
      justify-content:space-evenly;
      height: auto;
      .Portfolio-Jumbotron-Button, .Portfolio-Jumbotron-Button-forward {
        position: relative;
        margin-right:0px !important;
        border-radius: 0px;
        background-color: transparent !important;
        color : rgba($gn-frame-color, 1);
        height: fit-content;
        flex-basis: 50%;
        width:50%;
        height: 40px;
        border-radius: 1rem;
        font-weight: bolder;
        &:hover {
          color : rgba($gn-accent-color, 1);
          border-color : rgba($gn-accent-color, 1) !important;
        }
      }

      .Portfolio-Jumbotron-Fa {
        //margin-left: 0rem;
        //width:40px;
        height : fit-content;
        width:20px;
        margin: auto 0px;
        margin-right:0.5rem;
        color: rgba( $gn-black-color , 0.25 );
        transition: all 0.25s ease-in-out;
        display: none;
        &:hover {
          color: rgba( $gn-frame-color , 0.5 );
        }
        &.bookmarked {
          color: rgba( $gn-frame-color , 1 );
          //display: none;
        }
      }
      
      .Portfolio-Jumbotron-Button-forward {
        //margin-right: auto;
        margin-left: 0.5rem !important;
        background-color: rgba($gn-green-color, 1) !important;
        color : rgba($gn-white-color, 1);
          &:hover {
            background-color: rgba($gn-accent-color, 1) !important;
            color : rgba($gn-black-color, 1);
            border-color : rgba($gn-accent-color, 1) !important;;
          }
      }

      .Portfolio-Jumbotron-Botton {
        float: left;
        position: relative;
        width: calc( 100% - 4rem);
        flex-basis: 50%;
        width:50%;
        &:first-child {
          //margin-left: auto;
          //margin-right: 0.5rem !important;
        }
        &:last-child {
          //margin-right: auto;
          //margin-left: 0.5rem !important;
        }
      }

      .holder {
        width : 100%;
      }      
    }

    .Portfolio-Report-Type-Swith {
      //float:right;
      background-color: rgba(  $gn-black-color, 0.025  );
      padding: 1rem 0rem;
      margin: 1rem 0rem;
      height:40px;
      line-height: 40px;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      .btn {
        min-height: calc(40px - 0.75rem);
        background-color: transparent !important;
        color :rgba( $gn-frame-color , 1);
        opacity: 1;
        border-width: 0px;
        border-bottom: 2px solid rgba($gn-frame-color, 1);
        transform: scale(1.2);
        font-size: 90%;
        &.noselect {
          opacity: 0.25;
          border-bottom: 0px solid rgba($gn-frame-color, 1);
          transform: scale(1);
        }
      }
    }

    .Portfolio-Remove {
      .Btn {
        color : rgba($gn-white-color, 1) !important;
        &:first-child {
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
          margin-right: 0.25rem;
        }
        &:last-child {
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
        }
        &.Remove {
          background-color: rgba($gn-red-color, 1) !important;
          border-color: rgba($gn-red-color, 1) !important;
          border-radius: 1rem;;
        }
      }     
    }

    .Portfolio-Jumbotron {
      float: left;
      position: relative;
      width: calc( 100% - 2rem);
      background-color: rgba( $gn-white-color, 0.5 ) !important;
      
      &.Symbol-Wrapper {
              padding: 1rem 1rem;
              position: sticky;
              margin-bottom: 0px;
              //top : 0rem;
              bottom:0rem;
              //height:50px;
              background-color: rgba( $gn-white-color, 1 ) !important;
              border-top: 1px solid rgba($gn-black-color, 0.1 );
              border-bottom: 1px solid rgba($gn-black-color, 0.1 );
              border-radius: 0px;
              z-index: 10;
      }
      .Symbol {
        display: flex;
        flex-direction: column;
        //padding : 1rem 0rem;
        float: left;
        width:100%;
        
        .STitle {
          //color : rgba($gn-white-color, 1);
          color : rgba($gn-frame-color, 1);
          font-size: 120%;
          font-weight: bolder;
          //background-color: rgba(  $gn-frame-color, 1  );
          flex-direction: row;
          display: flex;
          padding : 0.25rem 0.5rem;
          padding-bottom: 0.75rem;
          justify-content: flex-start;
          transition : all 0.25s ease-in-out;
          border-bottom: 1px solid rgba(  $gn-black-color, 0.05  );
          cursor: pointer;
          .SFa {
            margin: auto;
            margin-right: 0px;
            width:20px;
          }
          &:hover {
            //background-color: rgba(  $gn-frame-color, 0.8  );
            color : rgba($gn-frame-color-2, 1);
          }
        }
        .Docker {
          transition: all 0.25s ease-in-out;
          display: flex;
          flex-direction: column;
          padding-top:0.25rem;
          padding-bottom: 0.5rem;
          background-color: rgba($gn-white-color, 0 );
          
          .SLoading {
            flex-direction: row;
            display: flex;
            justify-content: center;
            .Spinner {
              border-color: rgba( $gn-frame-color, 0.8 );
              border-right-color: transparent;
              margin: 0.5rem;
            }
            span {
              margin: auto;
              margin-left: 1rem;
            }
          }
          .Symbol-Holder {
            //background-color: rgba( $gn-frame-color, 0.1 );
            margin: 0.25rem 0px;
            padding:0.5rem;
            display: flex;
            flex-direction: row;
            cursor: default;
            border-bottom: 2px solid rgba( $gn-frame-color, 0.25 );
            .Symbol-Name {
              margin: auto 0.25rem;
              width:60px;
              font-size: 100%;
              color : rgba(  $gn-white-color, 1  );
              background-color: rgba( $gn-frame-color, 1 );
              padding:0.25rem 0.5rem;
              text-align: center;
            }
            .Symbol-Company {
              margin: auto 0.25rem;
              font-size: 80%;
              color : rgba(  $gn-black-color, 0.8  );
            }
          }
        }
        &.Show {
          .Docker {
            height: 180px;
            overflow: auto;
            opacity: 1;
            padding:0.5rem;
          }
        }
        &.Hide {
          .Docker {
            height: 0px;
            overflow: hidden;
            opacity: 0;
            padding:0px;
          }
        }
      }
    }

    .Portfolio-Body {
        //float:left;
        //position: absolute;
        //top:150px;
        //left:0px;
        //right:0px;
        //bottom:0px;
        overflow: auto;
        flex-direction: column;
        display: flex;
        //padding-bottom: 100px;
        .Portfolio-Jumbotron {
            float: left;
            position: relative;
            width: calc( 100% - 4rem);
            background-color: rgba( $gn-white-color, 0.5 ) !important;
            &.Symbol-Wrapper {
              position: sticky;
              bottom : -1rem;
              top : 0rem;
              //height:50px;
              background-color: rgba( $gn-white-color, 1 ) !important;
              border-top: 1px solid rgba($gn-black-color, 0.1 );
              border-bottom: 1px solid rgba($gn-black-color, 0.1 );
              border-radius: 0px;
              z-index: 10;
            }
            .VictoryContainer {
              height:300px !important;
              margin-bottom: 4rem;
            }
            .Portfolio-Radar-Holder {
                width : 50%;
                height : 300px;
                float:left;
                position: relative;
                .Portfolio-Radar-Title {
                  position: absolute;
                  width: 100%;
                  top:-20px;
                  left:0px;
                  right:10px;
                  line-height: 20px;
                  height:20px;
                  color : rgba($gn-black-color, 1);
                  z-index : 5;
                  text-align: center;
                  font-size: 110%;
                }
            }
            .Portfolio-Chart-Holder {
              width : 100%;
              //height : 250px;
              //float:left;
              position: relative;
              //margin-top: 4rem;
              display: flex;
              flex-direction: column;
              .Portfolio-Chart-Title {
                //position: absolute;
                //top:0px;
                //left:0px;
                //right:10px;
                //line-height: 20px;
                //height:20px;
                cursor: default;
                color : rgba($gn-white-color, 1);
                z-index : 5;
                text-align: center;
                margin: 1rem 0px;
                font-size: 110%;;
                background-color: rgba(  $gn-frame-color, 1  );
                border-radius: 1rem;
                padding:0.25rem 1rem;
                width: fit-content;
                margin-bottom: 1rem;
                font-weight: bolder;
              }
              .Portfolio-Chart-Holder-Billboard {
                //position :absolute;
                //top:0px;
                //left:0px;
                //right:0px;
                //bottom:0px;
                height:250px;
                flex-direction: row;
                display: flex;
                border-radius: 1rem;
                overflow: hidden;
                border: 1px solid rgba($gn-black-color, 0.1 );
                .col {
                  height:100%;
                  padding:0px;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-evenly;
                  .row {
                    width : 100%;
                    margin : 0px;
                    .title {
                      color : rgba($gn-frame-color, 1);
                      position:absolute;
                      top:0px;
                      left:10px;
                      right:0px;
                      height: 25px;
                      line-height: 25px;
                      font-size: 0.8rem;
                      text-align: left;
                    }
                    .value {
                      color : rgba($gn-dark-color, 1);
                      position:absolute;
                      top:25px;
                      left:0px;
                      right:0px;
                      bottom:0px;
                      line-height: 100%;
                      font-size: 1.4rem;
                      flex-direction: column;
                      display: flex;
                      justify-content: center;
                      text-align: center;
                      span {
                        margin: 0px 0.5rem;
                      }
                    }
                    .capital {
                      font-size: 1.4rem;
                    }
                    .sub {
                      position: absolute;
                      bottom:0px;
                      right:5px;
                      line-height: 20px;
                      height: 20px;
                      font-size: 0.7rem;
                      color : rgba($gn-white-color, 1);
                      opacity: 0.9;
                      background-color: transparent!important;
                      span {
                        margin: 0px 0.1rem;
                      }
                    }
                  }
                  .two {
                    height : calc( 50% - 2px );
                    width : calc( 100% - 2px);
                    border-right : 1px solid rgba($gn-black-color, 0.1 );
                    border-bottom : 1px solid rgba($gn-black-color, 0.1 );

                    //margin-bottom: 2px;
                    //margin-right: 2px;
                    background-color: rgba( $gn-grey-color , 0.0);
                    position: relative;
                    &:last-child {
                      border-bottom : 0px solid rgba($gn-black-color, 0.1 );
                    }
                  }
                  .three {
                    height : calc( 100% / 3 - 2px );
                    /*width : calc( 100% - 1px);
                    border-bottom : 1px solid transparent;*/
                    border-bottom : 1px solid rgba($gn-black-color, 0.1 );
                    //margin-bottom: 2px;
                    background-color: rgba( $gn-grey-color , 0.0);
                    position: relative;
                    &:last-child {
                      border-bottom : 0px solid rgba($gn-black-color, 0.1 );
                    }
                  }
                }
              }
              .empty {
                background-color: #F9F9F9;
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: center;
              }
            }
            .Portfolio-Chart-Supporter {
              width :300px;
              padding : 0px calc( 25% - 150px);
              height : 75px;
              margin-top: -0px;
              float:left;
              position: relative;
              display: none;
              .Portfolio-Chart-Supporter-Holder {
                float:left;
                position : relative;
                height:50px;
                width : 50%;
                text-align: center;
                .Portfolio-Chart-Supporter-Title {
                  float:left;
                  position : relative;
                  height:20px;
                  width : 100%;
                  line-height: 20px;
                  color : rgba($gn-black-color, 0.4);
                  font-size: 10px;
                }
                .Portfolio-Chart-Supporter-Content {
                  float:left;
                  position : relative;
                  height:20px;
                  width : 100%;
                  line-height: 30px;
                  font-size: 20px;
                  color : rgba($gn-black-color, 1);
                }
              }
            }
            .Feature {
              display: flex;
              flex-direction: column;
              padding : 0rem 0rem;
              padding-top: 4rem;
              float: left;
              width:100%;
              .FTitle {
                cursor: default;
                color : rgba($gn-white-color, 1);
                font-size: 110%;
                font-weight: bolder;
                background-color: rgba(  $gn-frame-color, 1  );
                width: fit-content;
                flex-direction: row;
                display: flex;
                padding : 0.25rem 1rem;
                justify-content: flex-start;
                border-radius: 1rem;;
              }
              .Docker {
                  margin-top: 1rem;
                  display: flex;
                  flex-direction: column;
                  padding-top:0.25rem;
                  padding-bottom: 0.5rem;
                  .DTitle {
                    color : rgba($gn-white-color, 1);
                    font-size: 75%;
                    //background-color: rgba(  $gn-black-color, 0.75  );
                    flex-direction: row;
                    display: flex;
                    padding : 0rem 1rem;
                    justify-content: flex-start;
                    background-color: rgba(  $gn-frame-color-2, 0.8  );
                    //border-radius: 1rem;;
                    width: calc( 100% - 3rem);
                    margin-bottom: 0rem;
                    margin-left: 1rem;
                    //margin-left: auto;
                  }
                  .DHolder {
                    display: flex;
                    flex-direction: column;
                    padding: 1rem 0rem;
                    border-bottom: 1px solid rgba($gn-black-color, 0.1);
                    .HTitle {
                      color : rgba( $gn-frame-color-3, 0.8 );
                      font-size: 100%;
                      font-weight: bold;
                      //background-color: rgba(  $gn-white-color, 0.25  );
                      flex-direction: row;
                      display: flex;
                      justify-content: flex-start;
                      padding : 0.25rem 0.5rem;
                    }
                    .HList, .Statement {
                      padding : 0rem 0.75rem;
                      color : rgba( $gn-black-color , 0.75 );
                      font-size: 90%;
                      margin-left: 1rem;
                      text-align: left;
                      //list-style-type: ;
                      //flex-direction: row;
                      //display: flex;
                      //justify-content: flex-start;
                    }
                  }
              }
            }

            .Symbol {
              display: flex;
              flex-direction: column;
              //padding : 1rem 0rem;
              float: left;
              width:100%;
              
              .STitle {
                color : rgba($gn-white-color, 1);
                //color : rgba($gn-badge-color, 1);
                font-size: 110%;
                font-weight: bolder;
                background-color: rgba(  $gn-badge-color, 0.8  );
                flex-direction: row;
                display: flex;
                padding : 0.25rem 0.5rem;
                justify-content: flex-start;
                transition : all 0.25s ease-in-out;
                cursor: pointer;
                .SFa {
                  margin: auto;
                  margin-right: 0px;
                  width:20px;
                }
                &:hover {
                  background-color: rgba(  $gn-frame-color, 0.8  );
                }
              }
              .Docker {
                transition: all 0.25s ease-in-out;
                display: flex;
                flex-direction: column;
                padding-top:0.25rem;
                padding-bottom: 0.5rem;
                background-color: rgba($gn-white-color, 0 );
                
                .SLoading {
                  flex-direction: row;
                  display: flex;
                  justify-content: center;
                  .Spinner {
                    border-color: rgba( $gn-frame-color, 0.8 );
                    border-right-color: transparent;
                    margin: 0.5rem;
                  }
                  span {
                    margin: auto;
                    margin-left: 1rem;
                  }
                }
                .Symbol-Holder {
                  //background-color: rgba( $gn-frame-color, 0.1 );
                  margin: 0.25rem 0px;
                  padding:0.5rem;
                  display: flex;
                  flex-direction: row;
                  cursor: default;
                  border-bottom: 2px solid rgba( $gn-frame-color, 0.25 );
                  .Symbol-Name {
                    margin: auto 0.25rem;
                    font-size: 100%;
                    color : rgba(  $gn-white-color, 1  );
                    background-color: rgba( $gn-frame-color, 1 );
                    padding:0.25rem 0.5rem;
                  }
                  .Symbol-Company {
                    margin: auto 0.25rem;
                    font-size: 80%;
                    color : rgba(  $gn-black-color, 0.8  );
                  }
                }
              }
              &.Show {
                .Docker {
                  height: 180px;
                  overflow: auto;
                  opacity: 1;
                  padding:0.5rem;
                }
              }
              &.Hide {
                .Docker {
                  height: 0px;
                  overflow: hidden;
                  opacity: 0;
                  padding:0px;
                }
              }
            }

        }
        
        .Portfolio-Pie-Holder {
          width: calc(50% - 2.5rem);
          position: relative;
          float: left;
          margin : 0px 1.25rem;
          margin-top: 50px;
          height: 330px;
          flex-direction: row;
          display: flex;
          justify-content: center;
          z-index : 5;
          svg {
            width : 100%;
            height: 330px;
            g {
              width : 100%;
              height: 330px;
            }
          }
          .Portfolio-Pie-Title {
            position: absolute;
            width: 100%;
            top:-30px;
            left:0px;
            right:10px;
            line-height: 20px;
            height:20px;
            color : rgba($gn-black-color, 1);
            z-index : 5;
            text-align: center;
            font-size: 100%;
          }
        }
        .Portfolio-Activities-Holder {
            width:calc( 50% - 2.5rem );
            position: relative;
            z-index : 5;
            float:left;
            background-color: rgba($gn-white-color, 1);
            min-height: 460px;
            padding: 20px 1.25rem;
            margin-bottom: 100px;
            .Portfolio-Activities-Header {
              float:left;
              cursor: pointer;
              height: 20px;
              .Portfolio-Activities-Header-Partition {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 40px;
                bottom: 0px;
                line-height: 40px;
                text-align: center;
                color : rgba($gn-white-color, 1);
              }
              .Portfolio-Activities-Header-Name {
                position: absolute;
                top: 0px;
                right: 0px;
                left: 40px;
                bottom: 0px;
                line-height: 40px;
                text-align: left;
                padding:0px 10px;
              }

            }
            .Portfolio-Activities-Title {
              width : 100%;
              position: absolute;
              left:0px;
              right:0px;
              height: 25px;
              line-height: 25px;
              top : 0px;
              background-color: rgba($gn-frame-color-2, 1);
              color : rgba($gn-white-color, 1);
              text-align: left;
            }
            .Portfolio-Activities-Desc {
                margin-top: -15px;
                padding: 5px;
                width: calc( 100% - 30px );
                float: left;
                /* background-color: #3F95F7; */
                color: #354149;
                position: relative;
                /* top: 0px; */
                /* bottom: 0px; */
                right: 10px;
                left: 30px;
                white-space: pre-line;
            }
            .Portfolio-Activities-Actitivies-Holder {
              position: relative;
              float:left;
              width: calc( 100% - 30px );
              right: 10px;
              left: 30px;
              padding-bottom: 20px;
              margin-top: 20px;
            }
        }

    }

}



@media screen and (max-width: 9999999px)  {
  .Portfolio-Holder {
    .modal-dialog {
      .modal-content {
        .Portfolio-User-Holder{
          .Portfolio-User-Holder-Left{
            .Portfolio-User-Name{
              font-size: 80%;
            }
          }
          .Portfolio-User-Type-Swith {
            button {
              padding: .375rem 10px;
              font-size: 100%;
              line-height: 90%;
              //height: auto;
            }
          }
        }

        .Portfolio-Body {
          //top: 190px;
          .Portfolio-Jumbotron {
            width: 100%;
            margin-bottom: 0rem;
            padding: 1rem 0rem;
            .Portfolio-Radar-Holder {
              width : 100%;
            }
            .Portfolio-Chart-Holder {
              width : 100%;
              .Portfolio-Chart-Title {
                top:0px;
              }
              .Portfolio-Chart-Holder-Billboard {
                top:50px;
              }
            }
            .Portfolio-Jumbotron-Button {
              position: fixed;
              bottom: 10px;
              left: 10px;
              z-index: 99;
            }

            .Portfolio-Jumbotron-Button-Edit {
              left: 10px;
              bottom : 50px;
            }

            .Portfolio-Jumbotron-Button-forward {
              position: fixed;
              bottom: 10px;
              right: 10px;
              z-index: 99;
              margin-right: 0px;
            }
            .Portfolio-Chart-Supporter {
              width : 100%;
            }

          }
          .Portfolio-Jumbotron-Bottom {
            display: flex;
            flex-direction: column;
            background-color : rgba( $gn-grey-color , 0.0);
            padding-left:0px;
            padding-right:0px;
            padding-top: 0px;
            margin-top: 3rem;
            width:100%;
            .Desc-Title {
              cursor: default;
                color : rgba($gn-white-color, 1);
                font-size: 110%;
                font-weight: bolder;
                background-color: rgba(  $gn-frame-color, 1  );
                width: fit-content;
                flex-direction: row;
                display: flex;
                padding : 0.25rem 1rem;
                justify-content: flex-start;
                border-radius: 1rem;
                margin-bottom: 1rem;
            }
            .desc {
              background-color : rgba( $gn-grey-color , 0.10);
              width : calc(100% - 2rem);
              padding : 1rem 1rem;
              font-size: 100%;
              font-weight: bolder;
              white-space: pre-line;
              border-radius: 1rem;
              
            }
          }
          .Portfolio-Pie-Holder {
            width: 100%;
            margin-left:0px;
            margin-right:0px;
            //height:400px;
          }
          .Portfolio-Activities-Holder {
            width : 100%;
            padding-top: 0px;
            min-height: auto;
            padding-bottom:50px;
            padding-left:0px;
            padding-right:0px;
            .activities {
              //border:1px solid rgba($gn-grey-color, 1);
              padding: 0.5rem 0rem;
            }
            .activities-title {
              width: fit-content;
              //line-height: 20px;
              //height: 20px;
              background-color: rgba( $gn-frame-color , 1);
              color: rgba($gn-white-color, 1);
              text-align: center;
              margin: 10px 0px;
              font-size: 110%;
              padding : 0.25rem 1rem;
              border-radius: 1rem;
              .Value {
                font-size: 120%;
                font-weight: bolder;
              }
            }
          }
        }
      }
    }
  }
}


@media screen and (max-width: 950px)  {
  .Portfolio-Holder {
    width:50%;
    .modal-95w {
      width:100%;
    }
  }
}

@media screen and (max-width: 750px)  {
  .Portfolio-Holder {
    width:100%;
    max-width: none;
    min-width: none;
    .modal-95w {
      width:100%;
    }
    .Portfolio-User-Holder {
      .Portfolio-User-Type-Swith {
        .btn {
          line-height: 100%;
        }
      }
    }
  }
}