@import "../../public/style.scss";

.Detail {
  //border-left: 1px solid rgba( $gn-black-color, 0.1 );
  //border-bottom: 1px solid rgba( $gn-black-color, 0.1 );
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  //box-shadow: 0px 0px 10px rgba($gn-white-color, 0.5);
  backdrop-filter: blur( 2px );
  -webkit-backdrop-filter: blur( 2px );
  position : absolute;
  top:0px;
  right:0px;
  bottom:0px;
  left:0px;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
    .Detail-Control {
      position: relative;
      //height:40px;
      //line-height: 40px;
      display: flex;
      flex-direction: row;
      padding : 10px 0rem;
      justify-content: flex-start;
      .Detail-Jumbotron-Button, .Detail-Jumbotron-Button-forward {
        position: relative;
        margin-right:0px !important;
        border-radius: 0px;
        background-color: rgba($gn-frame-color, 1) !important;
        color : rgba($gn-white-color, 1);
        border: 0px solid transparent;
        &.config {
          background-color: transparent !important;
          color : rgba( $gn-white-color, 1 );
          background-color: rgba($gn-frame-color, 1 ) !important;
          border-radius: 0.5rem;
          font-weight: bolder;
          //font-size: 110%;
          &:hover {
            background-color: rgba($gn-badge-color, 1 ) !important;
            color : rgba( $gn-white-color, 1 )!important;
          }
        }
        .Fa {
          width:10px;
          margin-right: 5px;
        }
        &:hover {
          background-color: rgba($gn-frame-color, 0.5) !important;
          //color : rgba($gn-white-color, 1);
        }
        &.left {
          margin-left: 1rem;
        }
        &.right {
          margin-left:auto;
          margin-right: 1rem !important;
        }
        &.disappear {
          visibility: hidden;
        }
      }
      .Detail-Jumbotron-Button-forward {
        margin-left: auto;
        margin-right: 0px !important;
          //position: relative;
          //float: right;
          //margin-right: calc(50% + 10px);
      }

      .Detail-Jumbotron-Botton {
        float: left;
        position: relative;
        width: calc( 100% - 4rem);
      }

      .holder {
        width : 100%;
      }      
    }
    .Detail-Body {
        overflow: hidden;
        height: calc( 100% - 50px);
        margin-top: 11px;
        //padding-bottom: 100px;
        .nav-tabs {
          border-bottom: 1px solid rgba($gn-black-color, 0.1);
          .nav-link {
            border-color: rgba($gn-frame-color, 0)!important;
            border-width: 1px;
            color: rgba($gn-black-color, 0.25);
            //padding: 0.25rem 1rem;
            transition: all 0.25s ease-in-out;
            &.active {
              background-color: transparent;
              color: rgba($gn-black-color, 1);
              font-size: 120%;
              border-color: rgba($gn-black-color, 0.1)!important;
              border-bottom-style: none;
            }
            &:hover {
              //background-color: rgba($gn-badge-color, 0.5)!important;
              //color: rgba($gn-white-color, 1); 
              color: rgba($gn-black-color, 0.75);
            }
            .Tab-Title {
              font-size: 90%;
              .Fa {
                width:10px;
                margin-right: 0.5rem;
              }
            }
            &:last-child {
              margin-left: auto;
            }
          }
        }
        .tab-content {
          overflow: auto;
          height: calc(100% - 40px);
          .tab-pane {
            position: relative;
            z-index:1;
            &.active {
              z-index:10;
            }
          }
          .jumbotron {
            background-color: transparent;
          }
          .Options {
            width:calc( 100% - 2rem - 6px);
            margin: 1rem auto ;
            .btn-secondary {
              border-radius: 0px;
              border: 0px solid transparent;
              margin:0px 0px;
              margin-right: 2px;
              background-color: rgba(  $gn-frame-color, 1 ) !important;
              transition: background-color 0.25s ease-in;
              opacity : 0.5;
              font-size: 85%;
              z-index:1;
              position: relative;
              transition: all 0.25s ease-in-out;
              &:hover {
                opacity : 0.8;
              }
              &:focus{
                border: 0px solid rgba($gn-black-color, 1);
                box-shadow : 0px 0px 0px black;
              }
              &.selected {
                opacity : 1;
                transform: scale(1.1);
                z-index:5;
                //background-color: rgba(  $gn-frame-color, 1 ) !important;
              }
              &:first-child {
                border-top-left-radius: 0.5rem;
                border-bottom-left-radius: 0.5rem;
              }
              &:last-child {
                margin-right: 0px;
                border-top-right-radius: 0.5rem;
                border-bottom-right-radius: 0.5rem;
              }
            }
          }
          .Detail-Jumbotron {
              background-color: rgba( $gn-white-color, 0.1 ) !important;
              padding : 4rem 1rem;
              display: flex;
              flex-direction: column;
              flex-grow: 1;
              .Detail-Radar-Holder {
                  width : 100%;
                  height : 300px;
                  float:left;
                  position: relative;
                  margin-bottom: 2rem;
                  .Detail-Radar-Title {
                    position: absolute;
                    width: 100%;
                    top:-40px;
                    left:0px;
                    right:10px;
                    line-height: 20px;
                    height:auto;
                    padding:0.5rem 0px;
                    color : rgba($gn-white-color, 1);
                    z-index : 5;
                    text-align: center;
                    font-size: 110%;
                    background-color: rgba(  $gn-frame-color, 1 );
                    border-radius: 0.5rem;
                  }
              }
              .Detail-Chart-Holder {
                width : 100%;
                height : 250px;
                float:left;
                position: relative;
                margin-top: 10px;
                //overflow: hidden;
                .Detail-Chart-Title {
                  position: absolute;
                  width: 100%;
                  top:-40px;
                  left:0px;
                  right:10px;
                  line-height: 30px;
                  height:30px;
                  color : rgba($gn-white-color, 1);
                  z-index : 5;
                  text-align: center;
                  margin: 10px 0px;
                  font-size: 110%;
                  font-weight: bolder;
                  background-color: rgba(  $gn-frame-color, 1 );
                  border-top-left-radius: 0.5rem;
                  border-top-right-radius: 0.5rem;
                }
                .Detail-Chart-Holder-Billboard {
                  position :absolute;
                  top:0px;
                  left:0px;
                  right:0px;
                  bottom:0px;
                  flex-direction: row;
                  display: flex;
                  border-bottom-left-radius: 0.25rem;
                  border-bottom-right-radius: 0.25rem;
                  .col {
                    height:100%;
                    padding:0px;
                    flex-direction: column;
                    justify-content: space-evenly;
                    .row {
                      width : 100%;
                      margin : 0px;
                      .title {
                        color : rgba($gn-black-color, 0.5);
                        position:absolute;
                        top:0px;
                        left:10px;
                        right:0px;
                        height: 25px;
                        line-height: 25px;
                        font-size: 0.8rem;
                        text-align: left;
                        
                      }
                      .value {
                        color : rgba($gn-black-color, 1);
                        position:absolute;
                        top:25px;
                        left:0px;
                        right:0px;
                        bottom:0px;
                        line-height: 100%;
                        font-size: 1.4rem;
                        flex-direction: column;
                        display: flex;
                        justify-content: center;
                        text-align: center;
                        .Fa {

                        }
                        span {
                          margin: 0px 0.5rem;
                        }
                        &.up {
                          background-color: transparent !important;
                          color : rgba( $gn-green-color, 1 ) !important;
                          .Fa {
                            color :rgba( $gn-green-color, 1 ) !important;
                          }
                        }
                        &.down {
                          background-color: transparent !important;
                          color : rgba( $gn-red-color, 1 ) !important;
                          .Fa  {
                            color : rgba( $gn-red-color, 1 ) !important;
                          }
                        }
                      }
                      .capital {
                        font-size: 1.4rem;
                      }
                      .sub {
                        position: absolute;
                        bottom:0px;
                        right:5px;
                        line-height: 20px;
                        height: 20px;
                        font-size: 0.7rem;
                        color : rgba($gn-black-color, 1) !important;
                        opacity: 0.9;
                        background-color: transparent!important;
                        span {
                          margin: 0px 0.1rem;
                        }
                        &.up {
                          color :rgba( $gn-green-color, 1 ) !important;
                        }
                        &.down {
                          color : rgba( $gn-red-color, 1 ) !important;
                        }
                      }
                    }
                    .two {
                      height : calc( 50% - 2px );
                      width : calc( 100% - 2px);
                      /*border-right : 1px solid transparent;
                      border-bottom : 1px solid transparent;*/
                      margin-bottom: 2px;
                      margin-right: 2px;
                      background-color: rgba( $gn-grey-color , 0.10);
                      position: relative;
                    }
                    .three {
                      height : calc( 100% / 3 - 2px );
                      /*width : calc( 100% - 1px);
                      border-bottom : 1px solid transparent;*/
                      margin-bottom: 2px;
                      background-color: rgba( $gn-grey-color , 0.10);
                      position: relative;
                    }
                  }
                }
                .empty {
                  background-color: rgba( $gn-white-color, 0.1 );
                  color : rgba($gn-white-color, 1);
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  text-align: center;
                }
              }
              .Detail-Chart-Supporter {
                display: none;
                width :calc( 100% - 0rem);
                padding : 10px 0rem;
                height : 75px;
                margin-top: -0px;
                float:left;
                position: relative;
                .Detail-Chart-Supporter-Holder {
                  float:left;
                  position : relative;
                  height:50px;
                  width : 50%;
                  text-align: center;
                  .Detail-Chart-Supporter-Title {
                    float:left;
                    position : relative;
                    height:20px;
                    width : 100%;
                    line-height: 20px;
                    color : rgba($gn-white-color, 0.4);
                    font-size: 10px;
                  }
                  .Detail-Chart-Supporter-Content {
                    float:left;
                    position : relative;
                    height:20px;
                    width : 100%;
                    line-height: 30px;
                    font-size: 20px;
                    color : rgba($gn-white-color, 1);
                  }
                }
              }

              .Feature {
                display: flex;
                flex-direction: column;
                padding : 1rem 0rem;
                .FTitle {
                  color : rgba($gn-white-color, 1);
                  font-size: 110%;
                  font-weight: bolder;
                  background-color: rgba(  $gn-frame-color, 1  );
                  flex-direction: row;
                  display: flex;
                  padding : 0.25rem 0.5rem;
                  justify-content: flex-start;
                  border-top-left-radius: 0.5rem;
                  border-top-right-radius: 0.5rem;
                  justify-content: center;
                }
                .Docker {
                    display: flex;
                    flex-direction: column;
                    padding-top:0.25rem;
                    padding-bottom: 0.5rem;
                    .DTitle {
                      color : rgba($gn-white-color, 1);
                      font-size: 100%;
                      background-color: rgba(  $gn-frame-color, 1  );
                      flex-direction: row;
                      display: flex;
                      padding : 0.25rem 0.5rem;
                      justify-content: flex-start;
                      filter: brightness(0.8);
                    }
                    .DHolder {
                      display: flex;
                      flex-direction: column;
                      padding-bottom: 0.5rem;
                      .HTitle {
                        color : rgba( $gn-black-color , 0.8 );
                        font-size: 100%;
                        font-weight: bold;
                        //background-color: rgba(  $gn-white-color, 0.25  );
                        flex-direction: row;
                        display: flex;
                        justify-content: flex-start;
                        padding : 0.25rem 0.5rem;
                      }
                      .HList, .Statement {
                        padding : 0rem 0.75rem;
                        color : rgba( $gn-black-color , 0.75 );
                        font-size: 90%;
                        margin-left: 1rem;
                        text-align: left;
                        //list-style-type: ;
                        //flex-direction: row;
                        //display: flex;
                        //justify-content: flex-start;
                      }
                    }
                }
              }

          }      

          .Holder {
            display: flex;
            flex-direction: column;
            //opacity: 0;
            z-index : 1;
            transition: opacity 0.25s ease-in-out;
            position: absolute;
            background-color: rgba($gn-black-color, 0.0) !important;
            padding-bottom: 50px;
            .Docker {
              display: flex;
              flex-direction: column;
              &.Strategy {
                margin-top: 2rem;
              }
              .Mode {
                margin: 2rem 1.5rem;
                width : calc( 100% - 3rem);
                .btn {
                  background-color: rgba(  $gn-frame-color, 1  ) !important;
                  color : rgba(  $gn-white-color, 1 );
                  border:0px solid rgba($gn-white-color, 0.1) !important;
                  font-size: 100%;
                  transition: all 0.25s ease-in-out;
                  transform: scale(1.1);
                  z-index: 5;
                  position: relative;
                  border-radius: 0.5rem;
                  &.Disabled {
                    opacity: 0.25;
                    transform: scale(1);
                    z-index: 1;
                  }
                  &:first-child {
                    border-top-right-radius: 0rem ;
                    border-bottom-right-radius: 0rem ;
                  }
                  &:last-child {
                    margin-left:1px;
                    border-top-left-radius: 0rem ;
                    border-bottom-left-radius: 0rem ;
                  }
                  &:hover {
                    background-color: rgba(  $gn-frame-color, 1  ) !important;
                  }
                }

              }
              .Title {
                background-color: rgba(  $gn-frame-color, 1  );
                color : rgba( $gn-white-color, 1 );
                padding : 0.25rem 0rem;
                cursor: default;
                width: calc( 100% - 3rem );
                margin:0px auto;
                border-top-left-radius: 0.5rem;
                border-top-right-radius: 0.5rem;
              }
              .Value {
                margin:0rem 1.5rem;
                margin-top: 1px;
                margin-bottom: 2rem;
                background-color: rgba( $gn-black-color, 0.05 );
                color : rgba( $gn-black-color, 1 );
                font-size: 100%;
                border-style: none;
                width : calc( 100% - 4.5rem);
                border-radius: 0px;
                border-bottom-left-radius: 0.5rem;
                border-bottom-right-radius: 0.5rem;
                transition: background-color 0.25s ease-in-out;
                &:hover {
                  background-color: rgba( $gn-frame-color, 0.1 );
                }
              }
              .Text {
                margin:0rem 1.5rem;
                margin-top: 1px;
                margin-bottom: 2rem;
                background-color: rgba( $gn-black-color, 0.05 );
                color : rgba( $gn-black-color, 1 );
                font-size: 100%;
                border-style: none;
                width : calc( 100% - 4.5rem);
                border-radius: 0px;
                min-height: 250px;
                transition: background-color 0.25s ease-in-out;
                border-bottom-left-radius: 0.5rem;
                border-bottom-right-radius: 0.5rem;
                &:hover {
                  background-color: rgba( $gn-frame-color, 0.1 );
                }
              }
              .AHolder {
                margin-top: 1px;
                padding: 0rem 1.5rem;
                padding-bottom:2rem;
                .accordion {
                  .card {
                    background-color: rgba( $gn-black-color, 1 ) ;
                    position: relative;
                    margin-bottom: 1px;
                    border-radius: 0rem;
                    
                    border-width: 0px;
                    .Fa {
                      //display: none;
                      position: absolute;
                      right:0px;
                      height: 12px;
                      width:10px;
                      color :rgba(  $gn-white-color, 1 );
                      font-size: 10px;
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      padding: 15px;
                      transition: all 0.25s ease-in-out;
                      background-color: rgba($gn-frame-color, 1 );
                      border-bottom-right-radius: 0.5rem;
                      display: none;
                      &:hover {
                        background-color: rgba($gn-badge-color, 1 );
                      }
                    }
                    .card-header {
                      padding:0.75rem 0.5rem;
                      padding-right: 45px;
                      background-color: rgba( $gn-frame-color, 0.8 );
                      color : rgba($gn-white-color, 1);
                      text-align: left;
                      font-size: 100%;
                      cursor: pointer;
                      transition: ball 0.25s ease-in;
                      //filter: brightness(0.8);
                      &:hover {
                        background-color: rgba( $gn-badge-color, 1 );
                      }
                      .fa {
                        margin-right: 0.5rem;
                        font-size: 50%;
                      }
                      .Name {
                        //font-size: 90%;
                      }
                      &:first-child {
                        border-top-left-radius: 0rem;
                        border-top-right-radius: 0rem;
                      }
                    }
                    .collapse, .collapsing {
                      .card-body {
                        color : rgba($gn-white-color, 1);
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        background-color: rgba( $gn-white-color, 1 );
                        border: 1px solid rgba( $gn-black-color, 0.1 );
                        border-top-width: 0px;
                        .IHolder {
                          flex-basis: calc( 50% - 0.5rem);
                          padding : 0.5rem 0.25rem;
                          .Name {
                            font-size: 75%;
                            text-align: left;
                            word-wrap: break-word;
                            color : rgba( $gn-black-color, 1 )
                          }
                          .Input {
                            margin-top: 0.25rem;;
                            background-color: rgba( $gn-black-color, 0.05 );
                            font-size: 90%;
                            color : rgba( $gn-black-color, 1 );
                            border-style :none;
                            width: calc(  100% - 1.5rem);
                            padding:0.25rem 0.75rem;
                            height : calc( 25px - 0.5rem);
                            transition: background-color 0.25s ease-in-out;
                            &:hover, &:focus {
                              background-color: rgba( $gn-frame-color, 0.1 );
                            }
                          }
                        }
                      }
                    }
                    &:last-child {
                      border-bottom-left-radius: 0.5rem;
                      border-bottom-right-radius: 0.5rem;
                    }
                  }
                }
              }
              &.Two {
                display: flex;
                flex-direction: row;
                justify-content: center;
                margin-bottom: 2rem;
                padding: 0px 1.5rem;
                //margin-top: 2rem;
                .Input {
                  overflow: hidden;
                  flex-basis: 50%;
                  position: relative;
                  &:first-child {
                    border-top-left-radius: 0.5rem;
                    border-bottom-left-radius: 0.5rem;
                  }
                  &:last-child {
                    border-top-right-radius: 0.5rem;
                    border-bottom-right-radius: 0.5rem;
                    margin-left: 1px;
                  }
                  &:hover, &:focus {
                    .TValue {
                      opacity: 0;
                    }
                    .TInput {
                      opacity: 1 !important;
                      background-color: rgba( $gn-frame-color, 0.1 );
                    }
                  }
                  .TTitle {
                    color: rgba( $gn-white-color, 1 );
                    font-size: 100%;
                    background-color: rgba(  $gn-frame-color, 1  );
                    margin-bottom: 1px;
                    padding:0.25rem 0px;
                  }
                  .TValue {
                    color: rgba( $gn-black-color, 1 );
                    font-size: 100%;
                    background-color: rgba( $gn-black-color, 0.05 );
                    border-style: none;
                    padding:0.25rem 0px;
                    text-align: center;
                    border-radius: 0px;
                    transition: background-color 0.25s ease-in-out;
                    height : calc(1.5em + 0.75rem + 2px);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    position: relative;
                    z-index: 1;
                    &.TInput {
                      opacity: 0;
                      position: absolute;
                      z-index: 9;
                      &:focus {
                        opacity: 1 !important;
                        background-color: rgba( $gn-badge-color, 0.1 );
                      }
                    }
                    &.prior {
                      opacity: 1 !important;
                    }
                    &.noprior {
                      opacity: 0 !important;
                    }
                  }
                }
              }
              .Save {
                position: relative;
                //margin-right:0px !important;
                border-radius: 0.5rem;
                margin: 1.5rem 0px;
                margin-bottom: 0px;
                margin-left : auto;
                margin-right : 1.5rem;
                background-color : rgba($gn-frame-color, 1) !important;
                //background-color: rgba($gn-badge-color, 0.5) !important;
                color : rgba($gn-white-color, 1);
                border: 0px solid rgba($gn-badge-color, 0.5) !important;
                font-weight: bolder;
                .Fa {
                  width:10px;
                  margin-right: 5px;
                }
                &:hover {
                  //background-color: rgba($gn-frame-color, 0.5) !important;
                  background-color : rgba( $gn-badge-color, 1 ) !important;
                  //color : rgba($gn-white-color, 0.8);
                  //color : rgba($gn-white-color, 1);
                }
              }
              .Regions {
                position: relative;
                //margin-right:0px !important;
                border-radius: 0.5rem !important;
                margin: 1.5rem 0px;
                margin-bottom: 0px;
                margin-left : 1.5rem;
                margin-right : auto;
                background-color : transparent !important;
                //background-color: rgba($gn-badge-color, 0.5) !important;
                color : rgba($gn-white-color, 1);
                border: 0px solid rgba($gn-badge-color, 0.5) !important;
                font-weight: bolder;
                .btn {
                  border-radius: 0.5rem !important;
                  padding:0.375rem 1rem;
                  font-weight: bolder;
                  border-width: 0px;       
                  display: flex;
                  flex-direction: row;           
                  .Scope {
                    display: flex;
                    flex-direction: row;
                    font-size: 100%;
                    font-weight: bolder;
                    .Region {
                      margin:auto 0px;
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      background-color: rgba( $gn-frame-color , 0);
                      height : fit-content;
                      .react-emojis {
                        font-size: 20px;
                        font-weight: 400;
                        margin: auto 0.5rem;
                        //margin-top: 0.5rem;
                      }
                    }
                  }
                  &::after {
                    margin: auto;
                    margin-left: 0.5rem;
                  }
                  //display: flex;
                  //flex-direction: row;
                  //justify-content: center;
                }
                .dropdown-menu {
                  .Line {
                    display: flex;
                    flex-direction: row;
                    font-size: 100%;
                    font-weight: bolder;
                    padding : 1rem 0.5rem;
                    width : calc( 100% - 1rem);
                    border-bottom: 1px solid rgba( $gn-black-color, 0.1 );

                    .Region {
                      margin:auto 0px;
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      background-color: rgba( $gn-frame-color , 0);
                      height : fit-content;
                      .react-emojis {
                        font-size: 20px;
                        font-weight: 400;
                        margin: auto 0.5rem;
                        //margin-top: 0.5rem;
                      }
                    }
                  }
                }

              }
              &.H {
                flex-direction: row;
              } 

            }
            .Detail-Pie-Holder {
              width: calc(100% - 0rem);
              position: relative;
              float: left;
              //margin : 0px 1.25rem;
              padding:15px 0px;
              margin-top: 0px;
              height: 300px;
              flex-direction: row;
              display: flex;
              justify-content: center;
              padding-top:30px;
              svg {
                width : 100%;
                height: 300px;
                g {
                  width : 100%;
                  height: 300px;
                }
              }
              .Detail-Pie-Title {
                position: absolute;
                //width: 100%;
                top:0px;
                left:0px;
                right:0px;
                line-height: 28px;
                height:fit-content;
                color : rgba($gn-white-color, 1);
                background-color: rgba(  $gn-badge-color, 0.5  );
                z-index : 5;
                text-align: center;
                font-size: 100%;
                padding:0px 1rem;
              }
            }
            &.active {
              position: relative;
              //opacity: 1;
              z-index : 5;
            }
            .Detail-Activities-Holder {
              width:calc( 100% - 3rem );
              position: relative;
              border-top-left-radius: 0px;
              border-top-right-radius: 0px;
              //float:left;
              //background-color: rgba($gn-white-color, 0.1);
              //min-height: 460px;
              padding: 20px 1.5rem;
              .accordion {
                .card{ 
                  //margin: 2.5px 0px;
                  border: 0px solid rgba(0,0,0,.125);
                  background-color: rgba($gn-white-color, 0.1);
                  border-bottom: 1px solid rgba($gn-badge-color,0.5);
                  .Detail-Activities-Header {
                    cursor: pointer;
                    height: 40px;
                    font-size: 90%;
                    border-bottom: 1px solid rgba($gn-white-color,.05);
                    display: flex;
                    flex-direction: row;
                    padding:0px;
                    .btn {
                      transition: background-color 0.25s ease-in-out; 
                      background-color: rgba(  $gn-badge-color, 0.5 ) !important;
                      width:30px;
                      line-height: 30px;
                      height:40px;
                      border-style: none;
                      padding:0px;
                      border-radius: 0px;
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      .Fa {
                        text-align: center;
                        color : rgba($gn-white-color, 1);
                        font-size: 80%;   
                        width:7.5px;   
                        margin: 0px auto;                  
                      }
                      &:hover {
                        background-color: rgba(  $gn-frame-color, 0.5 ) !important;
                      }
                    }
                    .Detail-Activities-Header-Partition {
                      width:30px;
                      line-height: 30px;
                      height:40px;
                      text-align: center;
                      color : rgba($gn-white-color, 1);
                      padding: 0px;
                      border-style: none;
                      background-color: rgba(  $gn-badge-color, 0.25 );
                      border-radius: 0px;
                      font-size: 90%;
                    }
                    .Detail-Activities-Header-Percent {
                      width:10px;
                      line-height: 40px;
                      height:40px;
                      text-align: center;
                      color : rgba($gn-white-color, 1);
                      padding-right: 5px;
                      border-style: none;
                      background-color: rgba(  $gn-badge-color, 0.25 );
                      border-top-right-radius: 0px;
                      border-bottom-right-radius: 0px;
                      font-size: 80%;
                    }
                    .Detail-Activities-Header-Name {
                      flex-grow: 1;
                      line-height: 40px;
                      height:40px;
                      text-align: left;
                      padding:0px 0.5rem;
                      color : rgba($gn-white-color, 1);
                      background-color: rgba(  $gn-white-color, 0.05 );
                      border-style: none;
                      font-size: 90%;
                    }
                    .Remove {
                      background-color: rgba( $gn-red-color, 0.1  );
                      color: rgba($gn-white-color, 1);
                      font-size: 80%;
                      transition: background-color 0.25s ease-in-out;
                      padding : 0px 0.5rem;
                      height:100%;
                      &:hover {
                        background-color: rgba( $gn-red-color, 0.5  );
                      }
                    }
                    .Update {
                      background-color: rgba( $gn-green-color, 0.25  );
                      color: rgba($gn-white-color, 1);
                      font-size: 80%;
                      transition: background-color 0.25s ease-in-out;
                      padding : 0px 0.5rem;
                      height:100%;
                      &:hover {
                        background-color: rgba( $gn-green-color, 0.5  );
                      }
                      &.disable {
                        background-color: rgba( $gn-white-color, 0.05  );
                        width:20px;
                      }
                    }

                  }
                  .Detail-Activities-Title {
                    width : 100%;
                    position: absolute;
                    left:0px;
                    right:0px;
                    height: auto;
                    padding:0px 1rem;
                    line-height: 25px;
                    background-color: rgba($gn-frame-color-2, 1);
                    color : rgba($gn-white-color, 1);
                    text-align: left;
                  }
                  .card-body {
                    padding:0.25rem 0px;
                    display: flex;
                    flex-direction: column;
                    .badge {
                      background-color: rgba( $gn-frame-color-3, 0.75);
                      color : rgba( $gn-black-color, 1 );
                      width: fit-content;
                      margin:0.25rem 0.5rem;
                      padding:0.25rem 0.5rem;
                    }
                    .Detail-Activities-Desc {
                        padding: 0px 0.5rem;
                        width: calc( 100% - 1rem  );
                        color: rgba($gn-light-color, 1);
                        font-size: 80%;
                        text-align: left;
                        word-break: break-all;
                        background-color: rgba( $gn-light-color, 0.05 );
                        border:0px none rgba( $gn-black-color, 0.1 );
                        border-bottom : 1px solid rgba( $gn-white-color, 0.1 );
                        min-height: 80px;
                    }
                    .Detail-Activities-Actitivies-Holder {
                      position: relative;
                      float:left;
                      width: calc( 100% - 30px );
                      right: 10px;
                      left: 30px;
                      padding-bottom: 20px;
                      margin-top: 20px;
                    }
                    .Update {
                      width : fit-content;
                      background-color: rgba(  $gn-badge-color, 0.5 ) !important;
                      transition: background-color 0.25s ease-in-out;
                      border-radius: 0px;
                      border-style: none;
                      margin-left:auto;
                      margin-right: 0.25rem;
                      margin-top:15px;
                      margin-bottom:10px;
                      font-size: 90%;
                      &:hover {
                        background-color: rgba(  $gn-frame-color, 0.5 ) !important;
                      }
                      span {
                        //font-size: 90%;
                      }
                    }
                  }
                }
              }
              .Add {
                margin-bottom: 1px;
                width:100%;
                padding:0.75rem 0px;
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
                border-style: none;
                background-color: rgba(  $gn-badge-color, 0.5  ) !important;
                font-size: 80%;
                &:hover {
                  background-color: rgba(  $gn-frame-color, 0.5  ) !important;
                }
              }
            }
          }

        }

    }

}



@media screen and (max-width: 9999999px)  {
  .Detail {
    .modal-dialog {
      .modal-content {
        .Detail-User-Holder{
          .Detail-User-Holder-Left{
            .Detail-User-Name{
              font-size: 80%;
            }
          }
          .Detail-User-Type-Swith {
            button {
              padding: .375rem 10px;
              font-size: 100%;
            }
          }
        }
        .Detail-Body {
          //top: 190px;
          .Detail-Jumbotron {
            width: 100%;
            margin-bottom: 0rem;
            padding: 1rem 0rem;
            margin : 0px;
            .Detail-Radar-Holder {
              width : 100%;
            }
            .Detail-Chart-Holder {
              width : 100%;
              .Detail-Chart-Title {
                top:0px;
              }
              .Detail-Chart-Holder-Billboard {
                top:50px;
              }
            }
            .Detail-Jumbotron-Button {
              position: fixed;
              bottom: 10px;
              left: 10px;
              z-index: 99;
              
              &.left {

              }
              &.right {
                margin-left:auto;
              }
            }

            .Detail-Jumbotron-Button-Edit {
              left: 10px;
              bottom : 50px;
            }

            .Detail-Jumbotron-Button-forward {
              position: fixed;
              bottom: 10px;
              right: 10px;
              z-index: 99;
              margin-right: 0px;
            }
            .Detail-Chart-Supporter {
              width : 100%;
            }
          }
          .Detail-Jumbotron-Bottom {
            float:left;
            width : calc(100% - 2rem);
            padding : 2rem 1rem;
            background-color : rgba( $gn-grey-color , 0.10);
            .desc {
              font-size: 80%;;
            }
          }
          .Detail-Pie-Holder {
            width: 100%;
            margin-left:0px;
            margin-right:0px;
            //height:400px;
          }
          .Detail-Activities-Holder {
            width : 100%;
            padding-top: 0px;
            min-height: auto;
            padding-bottom:50px;
            padding-left:0px;
            padding-right:0px;
            .activities {
              //border:1px solid rgba($gn-grey-color, 1);
              padding: 0.5rem 0rem;
            }
            .activities-title {
              width: 100%;
              line-height: 20px;
              height: 20px;
              background-color: rgba( $gn-grey-color , 0.10);
              color: #000000;
              text-align: center;
              margin: 10px 0px;
              font-size: 110%;
            }
          }
        }
      }
    }
  }
}


@media screen and (max-width: 950px)  {
  .Detail {
    //width:50%;
  }
}

@media screen and (max-width: 750px)  {
  .Detail {
    //width:100%;
  }
}