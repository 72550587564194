@import "../../../../public/style.scss";

.trade-insert-2 {
  display: flex;
  flex-direction: column;
  height:0px;
  visibility : hidden;
  opacity : 0;
  transition: all 0.25s ease-in-out;
  width : 100%;
  //padding-bottom:10px;
  border-bottom: 1px solid rgba($gn-black-color, .1);
  padding-bottom: 0px;
  position: relative;
  z-index: 10;
  &.iShow {
    visibility: visible;
    height:auto;
    opacity : 1;
    padding-bottom: 15px;
  }
  .holder {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 1rem;
    .title {
      margin : 5px 0.5rem;
      color : rgba( $gn-black-color, 0.75 );
      font-weight: bolder;
      font-size: 100%;
      margin-bottom: 0px;
    }
    .dropdown {
      margin : 5px 0.5rem;
      margin-top:0px;
      .dropdown-toggle {
        //background-color: rgba($gn-white-color, .1 ) !important;
        color : rgba($gn-white-color, 1) ;
        border-radius: 1rem;
        margin-top: 1rem;
        font-size: 100%;
        padding:0.25rem 1rem;
        //border-style: none;
      }
      .dropdown-menu {
        max-height: calc(200px - 2rem) !important;
        overflow: auto;
        background-color: rgba( $gn-white-color, 0.95 );
        backdrop-filter: blur(5px);
        //width:100%;
        .dropdown-item {
          padding:1.0rem 1rem;
          width: calc( 100% - 2rem);
          font-size: 80%;
          border-bottom: 1px solid rgba( $gn-black-color, 0.05 );
          transition: all 0.25s ease-in-out;
          &:hover {
            background-color: rgba( $gn-frame-color, 0.5 );
          }
        }
      }
    }
    .btn {
      margin : 5px auto;
      margin-left: auto;
      margin-bottom:0px;
      border-radius: 1rem;
      padding:0.25rem 1rem;
    }
    &:first-child, &:last-child {
      margin-top: 0rem;
    }
  }
  .input { 
    //height : 30px;
    display: flex;
    flex-direction: row;
    width : 100%;
    .form-control {
      margin: 1rem auto;
      margin-left: auto;
      margin-right: auto;
      width:80%;
      height: calc(1.5em + .75rem + 2px);
      //padding: .1rem .5rem;
      background-color: rgba($gn-white-color, 0);
      //border-width:1px;
      border: 1px solid rgba($gn-frame-color, 1);;
      color : rgba($gn-frame-color, 1);
      border-radius: 1rem;
    }
    .rbt-input-hint {
      margin : 0px !important;
      padding:0px !important;
      width: calc( 100% - 1rem)!important;
    }
    .dropdown-menu {
      max-height: calc(150px - 2rem) !important;
      .dropdown-item {
        width : calc( 100% - 3rem);
        color : rgba( $gn-black-color, 0.9 );
        font-size: 90%;
        padding: 0.5rem 1.5rem;
        border-bottom: 1px solid rgba( $gn-black-color, 0.05 );
        transition: all 0.25s ease-in-out;
      }
    }
  }
}

