/*FONT FAMILY*/
//@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@400;500;600&display=swap');
//@import url('https://fonts.googleapis.com/css?family=Ubuntu:regular,bold&subset=Latin');
//@import url('https://fonts.googleapis.com/css?family=Noto+Sans+TC:400&display=swap');
//@import url('https://fonts.googleapis.com/earlyaccess/notosanssc.css');

$gn-font : ProximaNova,Arial,sans-serif;
/*$gn-font : 'Barlow', sans-serif;*/
//$gn-font : 'Ubuntu', 'Noto Sans TC', 'Noto Sans SC';
/*$gn-font : Trebuchet MS,roboto,ubuntu,sans-serif;*/
//$gn-font : 12px/1.5 Century Gothic,arial,tahoma,'Hiragino Sans GB','\5b8b\4f53',sans-serif;
/*$gn-font : -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
sans-serif;*/
//$gn-font : "Microsoft YaHei" ,'Heiti TC', 'Heiti SC', Tahoma, Verdana, STHeiTi ; 

/*FRAME COLORS*/

/*
$gn-bgcolor: #faf9f6;
$gn-black-color : #000000;
$gn-dark-color: #354149;
$gn-grey-color:#bababa;
$gn-white-color: #FFFFFF;
$gn-light-color : #F9F9F9;
$gn-green-color : #35CE8D;
$gn-red-color :  #B51700;
$gn-darker-color : #222222;
$gn-frame-color: #B51700;
$gn-frame-color-2 : #150805;
$gn-frame-color-3 : #ffcccc;
$gn-frame-color-4 : #901d1d;
$gn-accent-color : #B51700;
$gn-accent-color-2 : #6BA292;
$gn-accent-color-3 : #35CE8D;
$gn-badge-color : #48ACF0;
*/

$gn-white-color: #FFFFFF;


$gn-bgcolor: var( --gn-bgcolor );
$gn-black-color : var( --gn-black-color  );
$gn-dark-color: var( --gn-dark-color  );
$gn-grey-color:var( --gn-grey-color  );
//$gn-white-color: var(--gn-white-color) ;
$gn-light-color : var( --gn-light-color  );
$gn-green-color : var( --gn-green-color  );
$gn-red-color :  var( --gn-red-color  );
$gn-darker-color : var( --gn-darker-color  );
$gn-frame-color:  var( --gn-frame-color  );
$gn-frame-color-2 : var( --gn-frame-color-2  );
$gn-frame-color-3 : var( --gn-frame-color-3  );
$gn-frame-color-4 : var( --gn-frame-color-4  );
$gn-accent-color : var( --gn-accent-color  );
$gn-accent-color-2 : var( --gn-accent-color-2  );
$gn-accent-color-3 : var( --gn-accent-color-3  );
$gn-badge-color : var( --gn-badge-color  );

/*
:export {
    gnBlackColor: $gn-black-color;
    gnGreyColor: $gn-grey-color;
    gnLightColor: $gn-light-color;
    gnDarkColor: $gn-dark-color;
    gnWhiteColor : $gn-white-color;

    gnFrameColor: $gn-frame-color;
    gnFrameColor2: $gn-frame-color-2;
    gnFrameColor3: $gn-frame-color-3;
    gnFrameColor4: $gn-frame-color-4;

    gnGreenColor: $gn-green-color;
    gnRedColor: $gn-red-color;

    gnAccentColor: $gn-accent-color;
    gnAccentColor2: $gn-accent-color-2;
    gnAccentColor3: $gn-accent-color-3;
    gnBadgeColor: $gn-badge-color;
    gnFont : $gn-font ;
}
*/