@import "../../public/style.scss";

.Portfolio-List { 
  background-color: rgba($gn-white-color, 1);
  margin : 2.5px 0rem;
  height : 50px;
  display: flex;
  flex-direction: row;
  border: 2px solid rgba( $gn-black-color, 0.05 );
  transition : all 0.25s ease-in-out;
  cursor: pointer;
  z-index: 1;
  position: relative;
  border-radius: 0.5rem;
  &.selected {
    background-color: rgba($gn-white-color, 1) !important;
    transform: scale(1.025, 1.025);
    border: 2px solid rgba( $gn-frame-color, 0.25 );
    .Right {
      .First {
        .Upper {
          .Fa {
            opacity: 0;
            width:0px;
            margin : 0px;
          }
          .Name {
            //background-color: rgba( $gn-frame-color, 0.75 );
            //color : rgba( $gn-white-color, 1 );
            //border-bottom-right-radius : 0.5rem;
          }
          //background-color: rgba( $gn-black-color, 0.25 );
          //backdrop-filter: blur(1px);
        }
        .Lower {
          //background-color: rgba( $gn-black-color, 0.25 );
          //backdrop-filter: blur(1px);
          .Holder {
            .Original {
              .Label, .Value {
                //color : rgba( $gn-white-color , 0.8);
              }
            }
          }
        }
      }
      .Second {
        .box {
          //opacity: 0.8;
          //backdrop-filter: blur( 4px);
        }
      }
      .loop-wrapper {
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        //border-radius: 0.5rem;
        opacity: 1;
        margin: 0 auto;
        position: absolute;
        overflow: hidden;
        border-bottom: 3px solid #fff;
        color: #fff;
        top:0px;
        left:0px;
        width: 100%;
        bottom:0px;
        z-index:5;
        .galaxy {
          z-index: 0;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: #03092b;
          -webkit-transition: background 0.8s ease-out;
          -moz-transition: background 0.8s ease-out;
          -o-transition: background 0.8s ease-out;
          -ms-transition: background 0.8s ease-out;
          transition: background 0.8s ease-out;
          .bg {
            width: 100%;
            height: 100%;
          }
          * {
            position: absolute;
          }
          .bg.center {
            top: 50%;
            left: 50%;
            width: 400px;
            height: 200px;
            margin: -100px 0 0 -200px;
            opacity: 0.8;
            -webkit-border-radius: 500px;
            -moz-border-radius: 500px;
            border-radius: 500px;
          }
          &:not(.star) {
            top: -50%;
            left: -50%;
            width: 200%;
            height: 200%;
          }
          .stars-back {
            background: url('https://raw.githubusercontent.com/gurde/css3-animated-galaxy/master/img/back.png');
            -webkit-animation: orbit-clock-wise 440s infinite linear;
            -moz-animation: orbit-clock-wise 440s infinite linear;
            -o-animation: orbit-clock-wise 440s infinite linear;
            -ms-animation: orbit-clock-wise 440s infinite linear;
            animation: orbit-clock-wise 440s infinite linear;
            width:100%;
            height: 100%;
          }
          .stars-middle {
            background: url('https://raw.githubusercontent.com/gurde/css3-animated-galaxy/master/img/middle.png');
            -webkit-animation: orbit-counter-clock-wise 360s infinite linear;
            -moz-animation: orbit-counter-clock-wise 360s infinite linear;
            -o-animation: orbit-counter-clock-wise 360s infinite linear;
            -ms-animation: orbit-counter-clock-wise 360s infinite linear;
            animation: orbit-counter-clock-wise 360s infinite linear;
            width:100%;
            height: 100%;
          }
          .stars-front {
            background: url('https://raw.githubusercontent.com/gurde/css3-animated-galaxy/master/img/front.png');
            -webkit-animation: orbit-clock-wise 160s infinite linear;
            -moz-animation: orbit-clock-wise 160s infinite linear;
            -o-animation: orbit-clock-wise 160s infinite linear;
            -ms-animation: orbit-clock-wise 160s infinite linear;
            animation: orbit-clock-wise 160s infinite linear;
            width:100%;
            height: 100%;
          }
          @-webkit-keyframes orbit-clock-wise {
            0% { opacity: 0.4; -webkit-transform: rotate(0deg); }
            2% { opacity: 0.8; }
            4% { opacity: 0.2; }
            5% { opacity: 0.8; }
            100% { opacity: 0.4; -webkit-transform: rotate(360deg); }
          }
          @-webkit-keyframes orbit-counter-clock-wise {
            from { -webkit-transform: rotate(360deg); } to { -webkit-transform: rotate(0deg); }
          }
          @-moz-keyframes orbit-clock-wise {
            0% { opacity: 0.4; -moz-transform: rotate(0deg); }
            2% { opacity: 0.8; }
            4% { opacity: 0.2; }
            5% { opacity: 0.8; }
            100% { opacity: 0.4; -moz-transform: rotate(360deg); }
          }
          @-moz-keyframes orbit-counter-clock-wise {
            from { -moz-transform: rotate(360deg); } to { -moz-transform: rotate(0deg); }
          }
          @-o-keyframes orbit-clock-wise {
            0% { opacity: 0.4; -o-transform: rotate(0deg); }
            2% { opacity: 0.8; }
            4% { opacity: 0.2; }
            5% { opacity: 0.8; }
            100% { opacity: 0.4; -o-transform: rotate(360deg); }
          }
          @-o-keyframes orbit-counter-clock-wise {
            from { -o-transform: rotate(360deg); } to { -o-transform: rotate(0deg); }
          }
          @-ms-keyframes orbit-clock-wise {
            0% { opacity: 0.4; -ms-transform: rotate(0deg); }
            2% { opacity: 0.8; }
            4% { opacity: 0.2; }
            5% { opacity: 0.8; }
            100% { opacity: 0.4; -ms-transform: rotate(360deg); }
          }
          @-ms-keyframes orbit-counter-clock-wise {
            from { -ms-transform: rotate(360deg); } to { -ms-transform: rotate(0deg); }
          }
          @keyframes orbit-clock-wise {
            0% { opacity: 0.4; transform: rotate(0deg); }
            2% { opacity: 0.8; }
            4% { opacity: 0.2; }
            5% { opacity: 0.8; }
            100% { opacity: 0.4; transform: rotate(360deg); }
          }
          @keyframes orbit-counter-clock-wise {
            from { transform: rotate(360deg); } to { transform: rotate(0deg); }
          }
        }
        .sun {
          height: 100px;
          width: 100px;
          background: radial-gradient(#F5C313, #EC7E08);
          position: absolute;
          z-index: 1;
          -webkit-box-shadow: 0 0 50px #F5C91A;
                  box-shadow: 0 0 50px #F5C91A;
          top: 0px;
          left: 0%;
          border-radius: 100%;
          //margin-top: -100px;
          filter : blur(2px);
          margin-left: -50px;
          -webkit-animation: SunFire 4s linear infinite;
          animation: SunFire 4s linear infinite;  
        }
        .hill {
          position: absolute;
          z-index:2;
          right: -900px;
          bottom: -50px;
          width: 400px;
          border-radius: 50%;
          height: 20px;
          box-shadow: 
            0 0 0 50px rgba( $gn-white-color, 0.5 ),
            -20px 0 0 20px rgba( $gn-white-color, 0.5 ),
            -90px 0 0 50px rgba( $gn-white-color, 0.5 ),
            250px 0 0 50px rgba( $gn-white-color, 0.5 ),
            290px 0 0 50px rgba( $gn-white-color, 0.5 ),
            620px 0 0 50px rgba( $gn-white-color, 0.5 );
          animation: hill 1.5s 2s linear infinite;
        }
        .tree, .tree:nth-child(2), .tree:nth-child(3) {
          position: absolute;
          height: 100px; 
          width: 35px;
          bottom: 0;
          background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/130015/tree.svg) no-repeat;
        }
        .rock {
          margin-top: -17%;
          height: 2%; 
          width: 2%;
          bottom: -2px;
          border-radius: 20px;
          position: absolute;
          background: #ddd;
          z-index:3;
        }
        .Rocket {
          transition: all ease;
          width: 85px;
          margin-right: -60px;
          bottom: 20px;
          right: 50%;
          z-index:5;
          position: absolute;
          //background: #eee;
          display: flex;
          flex-direction: row-reverse;
          .Body {
            color : rgba( $gn-red-color, 1 );
            filter : blur(0.5px);
            transform: rotate(40deg);
            font-size: 40px;
          }
          .Flame {
            color : rgba( yellow, 0.5 );
            font-size: 30px;
            //transform: scale3d(1.1, 1, 1 ) rotate(275deg);
            margin-top: 5px;
            filter: blur(10px);
          }
        }
        .Rocket:before {
          content: " ";
          position: absolute;
          width: 50px;
          //transform: rotate(50deg);
          filter : blur( 5px);
          box-shadow:
            -65px 17.5px 0 1.5px rgba( $gn-white-color, 0.5 ),
             -60px 27.5px 0 1.5px rgba( $gn-white-color, 0.5 );
        }    
        .tree  { animation: tree 1.5s 0.000s linear infinite; }
        .tree:nth-child(2)  { animation: tree2 2s 0.150s linear infinite; }
        .tree:nth-child(3)  { animation: tree3 8s 0.050s linear infinite; }
        .rock  { animation: rock 0.5s   -0.530s linear infinite; }
        .Rocket  { 
          animation: rocket 2s   0.080s ease infinite; 
          .Body {
            animation: body 2s   0.080s ease infinite; 
          }
          .Flame {
            animation: flame 2s   0.080s ease infinite; 
          }
        }
        .Rocket:before { animation: wind 1.5s   0.000s ease infinite; }
        @keyframes tree {
          0%   { transform: translate(1350px); }
          50% {}
          100% { transform: translate(-50px); }
        }
        @keyframes tree2 {
          0%   { transform: translate(650px); }
          50% {}
          100% { transform: translate(-50px); }
        }
        @keyframes tree3 {
          0%   { transform: translate(2750px); }
          50% {}
          100% { transform: translate(-50px); }
        }
        
        @keyframes rock {
          0%   { right: -200px; }
          100% { right: 2000px; }
        }
        @keyframes rocket {
          0%   {  }
          10%   { 
            transform: translateY(0px); 
          }
          15%   { 
            transform: translateY(-6px);
          }
          30%   { 
            transform: translateY(0px); 
          }
          90%   { 
            transform: translateY(-1px);
          }
          95%   { 
            transform: translateY(0px);
          }
          100%   {  }
        }
        @keyframes body {
          0%   {  }
          6%   { 
            transform: rotate(40deg); 
          }
          12%   { 
            transform: rotate(45deg); 
          }
          50%   { 
              transform: rotate(50deg); 
          }
          72%   { 
              transform: rotate(45deg); 
          }
          80%   { 
              transform: rotate(40deg); 
          }
          100%   {  }
        }
        @keyframes flame{
          0%   {  }
          6%   { 
              transform: scale(1.2, 1 ) rotate(275deg); 
              //filter: blur(3px);
          }
          12%   { 
              transform: scale(1.3, 1 ) rotate(275deg); 
              //filter: blur(4px);
          }
          50%   { 
              transform: scale(1.35, 1 ) rotate(275deg); 
              //filter: blur(4px);
          }
          68%   { 
              transform: scale(1.3, 1 ) rotate(275deg); 
              //filter: blur(3px);
          }
          75%   { 
              transform: scale(1.2, 1 ) rotate(275deg); 
              //filter: blur(2px);
          }
          100%   {  }
        }
        @keyframes wind {
          0%   {  }
          30%   { transform: translate(1px, -2px) rotate(3deg); }
          50%   { transform: translate(0px, 0px) rotate(0deg); }
          70%   { transform: translate(-1px, 2px) rotate(-3deg); }
          100%   { }
        }
        @keyframes mtn {
          100% {
            transform: translateX(-2000px) rotate(130deg);
          }
        }
        @keyframes hill {
          100% {
            transform: translateX(-2000px);
          }
        }
  
        @-webkit-keyframes SunFire {
          0% {-webkit-box-shadow: 0 0 50px #F5C91A;box-shadow: 0 0 50px #F5C91A;transform: scale(0.75) translate(2000px);}
          100% {-webkit-box-shadow: 0 0 100px #F5C91A;box-shadow: 0 0 100px #F5C91A;transform: scale(1.2) translate(-200px);}	
        }
        
        @keyframes SunFire {
          0%{
            -webkit-box-shadow: 0 0 50px #F5C91A;
            box-shadow: 0 0 50px #F5C91A;
            transform: translate(800px);
            width : 0px;
            height :0px;
          }
          60% {
            -webkit-box-shadow: 0 0 100px #F5C91A;
            box-shadow: 0 0 100px #F5C91A;
            transform: translate(50px, 50px);
            width : 125px;
            height : 125px;
          }	
          100% {
            -webkit-box-shadow: 0 0 100px #F5C91A;
            box-shadow: 0 0 100px #F5C91A;
            transform: translate(-200px, -100px);
            width : 120px;
            height : 120px;
          }	
        }
      }
    }
  }
  &.noselected {
    opacity: 0.5;
  }

  &:hover {
    transform: scale(1.025, 1.025);
  }
  
  .Left{
    width: 10px;
    flex-shrink: 1;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    background-color: rgba( $gn-badge-color, 0.75 );
    &.gain {
      background-color: rgba( $gn-green-color, 0.75 );
    }
    &.lose {
      background-color: rgba( $gn-red-color, 0.75 );
    }
    &.overdraft {
      background-color: rgba( $gn-black-color, 0.5 );
    }
  }
  .Right {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    position: relative;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    .First {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      .Upper {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        position: relative;
        z-index: 10;
        width:100%;
        height:auto;
        //border-top-right-radius: 0.5rem;
        .Region {
          margin:auto 0px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          background-color: rgba( $gn-frame-color , 0);
          height : fit-content;
          .react-emojis {
            font-size: 20px;
            font-weight: 400;
            margin: auto 0.5rem;
            //margin-top: 0.5rem;
          }
        }
        .Fa {
          //transition: all 0.25s ease-in-out;
          width: 2rem;
          color : rgba( $gn-frame-color, 1 );
          margin: auto 0rem;
          margin-right: 0rem;
          font-size: 120%;
        }
        .Name {  
          padding: 0.25rem 0.5rem;
          padding-left: 0rem;
          font-size: 100%;
          font-weight: bolder;
          color : rgba( $gn-frame-color, 0.75 );
        }

      }
      .Lower {
        //border-bottom-right-radius: 0.5rem;
        margin-top: auto;
        flex-grow: 1;
        width:100%;
        position: relative;
        z-index: 10;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width : 100%;
        height: 20px;
        margin-bottom: -0px;
        .Holder {
          display: flex;
          flex-direction: column;
          width : 100%;
          margin-top: auto;
          .Current {
            padding: 0rem 0rem;
            display: flex;
            flex-direction: row;
            //justify-content: end;
            &.gain {
              .Value {
                color : rgba( $gn-green-color, 0.8 );
              }
              .Percent, .Label, .Fa {
                background-color: rgba( $gn-green-color, 0.8 );
              }
            
            }
            &.lose {
              .Value {
                color : rgba( $gn-red-color, 0.8 );
              }
              .Percent, .Label, .Fa {
                background-color: rgba( $gn-red-color, 0.8 );
              }
            }
            &.overdraft {
              text-decoration: line-through;
              color :rgba( $gn-black-color, 0.5 );
            }
            .Label {
              background-color: rgba( $gn-black-color, 0.5 );
              color : rgba( $gn-white-color, 0.8 );
              padding:0rem 1rem;
              display: flex;
              flex-direction: column;
              justify-content: center;
              margin:auto 0px;
              margin-right: 0.25rem;
              margin-bottom: 0px;
              font-size: 80%;
              font-weight: bold;
              border-top-right-radius: 1rem;
              border-bottom-right-radius: 1rem;
            }
            .Value {
              color : rgba( $gn-black-color, 0.5 );
              font-size: 100%;
              font-weight: lighter;
              flex-grow: 1;
              text-align: left;
              margin-top: auto;
              margin-bottom: 0px;
            }
            .Fa {
              font-size: 100%;
              margin: auto 0rem;
              //margin-bottom: 0.25rem;
              padding: 1.5px 0.0rem;
              padding-left: 0.5rem;
              margin-left: 1rem;
              background-color: rgba( $gn-black-color, 0.5 );
              color : rgba( $gn-white-color, 1 );
              border-top-left-radius: 1rem;
              border-bottom-left-radius: 1rem;
              margin-bottom: 0px;
            }
            .Percent {
              font-size: 80%;
              background-color: rgba( $gn-black-color, 0.5 );
              color : rgba( $gn-white-color, 1 );
              margin: auto 0rem;
              //margin-bottom: 0.25rem;
              padding: 0px 0.5rem;
              margin-right: 0.5rem;
              border-top-right-radius: 1rem;
              border-bottom-right-radius: 1rem;
              margin-bottom: 0px;
            }
          }
          .Original {
            display : flex;
            flex-direction: row;
            padding: 0rem 1rem;
            margin-left: auto;
            .Label {
              color : rgba( $gn-black-color, 0.5 );
              font-size:x-small;
              margin: auto 0px;
              margin-right: 0.5rem;
            }
            .Value {
              color : rgba( $gn-black-color, 0.5 );
              margin: auto 0px;
              font-size:small;
            }
          }
        }
      }
    }

    .Second {
      width:auto;
      position: relative;
      z-index: 10;
      .box {
        width:60px;
        margin: 1px 0px;
        margin-right: 1px;
        //height:calc( 33.333% - 2px);
        float: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color : rgba( $gn-white-color , 1 );
        font-weight: lighter;
        height:100%;
        position: relative;
        &:last-child {
          border-top-right-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
          margin-right: 0px;
        }
        .Title {
          position: absolute;
          top:5px;
          left:0px;
          right: 0px;
          text-align: center;
          font-size: x-small;
          line-height: 90%;
          color : rgba( $gn-white-color , 0.75);

        }
      }
    }

    .loop-wrapper {
      z-index: 2;
      opacity: 0;
      position : absolute;
      top:0px;
      left:0px;
      transition: all 0.5s ease-in-out;
    }
  }


}

@media screen and (max-width: 750px)  {
  .Portfolio-List { 
    width: calc(100% - 4px);
    //justify-content: space-evenly;
    .Left {
      flex-shrink: 0;
    }
    .Right {
      width: calc( 100% - 15px);
      .First {
        width : calc( 100% - 100px);
        flex-shrink: 0; 
        overflow: hidden;
        .Upper {
          .Name {
            overflow: hidden;
            white-space:pre;
            text-overflow: ellipsis;
          }
        }
        .Lower {
          
          .Holder {
            width:100%;
            justify-content: flex-end;
            .Current {
              //flex-direction: column;
              padding:0px;
              flex-wrap: wrap;
              .Label {
                width:60px;
                word-wrap: break-word;
                line-height: 90%;
              }
              .Value {
                flex-grow: 1;
                flex-basis: calc( 100% - 60px - 1.5rem);
                text-align: left;
              }
              .Fa {
                //flex-grow: 1;
                margin-left: auto;
                transform: translate(-2px, -2px);
              }
              .Percent{
                transform: translate(-2px, -2px);
              }
            }
            .Original {
              display: none;
            }
          }
        }
      }
      .Second {
        width:100px;
        flex-shrink: 1;
        .box {
          width:39px;
          margin: 1px 0px;
          height:calc( 33.333% - 2px);
          float: left;
          display: flex;
          flex-direction: column;
          justify-content: center;
          color : rgba( $gn-white-color , 1 );
          font-weight: lighter;
          .Title {
            display: none;
          }
          &:first-child {
            width:60px;
            height:calc( 100% - 3px);
            margin: 1px;
            margin-left:0px;
            font-size: 200%;
            .Title {
              display: block;
            }
          }
          &:nth-child(2) {
            margin-bottom: 0px;
            border-top-right-radius: 0.5rem;
          }
          &:last-child {
            margin-bottom: 0px;
            border-top-right-radius: 0rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
      }
    }
  }
}