@import "../../public/style.scss";

.Portfolio-Point { 
  transition: all 0.25s ease-in-out;
  width:10px;
  height:10px;
  //background-color: rgba( $gn-black-color, 0.5 );
  border: 2px solid rgba( $gn-frame-color, 0.5 );
  border-radius: 50%;
  position: absolute;
  z-index: 10;
  left: calc( 10% - 5px);
  top: calc( 10% - 5px);
  //box-shadow: 0px 0px 5px rgba($gn-black-color, 0.75);
  cursor: pointer;
  &:hover:not(.selected) {
    z-index : 15;
    background-color: rgba($gn-badge-color, 0.8) !important;
    transform: scale3d( 1.2, 1.2, 1.2 );
    border: 2px solid rgba( $gn-black-color, 0.5 );
    //border: 2px solid rgba($gn-frame-color, 0.1);
    filter: blur(0px) !important;
  }
  &:hover .box:not(.title) {
    opacity: 0.8;
  }
  &.noselected {
    opacity: 0.5;
    filter: blur(5px);
    //background: rgba( var(--gn-black-color), 0.8 );
    //.Middle {
    //  background: rgba( var(--gn-black-color), 0.8 );
    //}
  }
  &.selected {
    z-index: 5;
    background-color: rgba( $gn-frame-color, 1 ) !important;
    transform: scale3d(1.1, 1.1, 1.1);
    //box-shadow: 0px 0px 10px rgba($gn-white-color, 1);
    //position: sticky;
    //top:100px;
    //box-shadow: 0px 0px 5px rgba($gn-black-color,0.25);
    //height:50px;
    .box {
      opacity: 0.7;
      display : none;
      //line-height: 35px;
      //line-height: 30px;
      &.profit, &.name ,&.onhold {
        display : block;
        opacity: 1 !important;
        font-size: 150%;
        width:40%;
        line-height: 35px;
        //display: flex;
        //flex-direction: column;
        //justify-content: center;

      }
      &.profit {
        flex-direction: row;
        display: flex;
        justify-content: center;
        flex-shrink: 0 !important;;
        //margin:2.5px;
        .main {
          font-size: 100%;
          //display: flex;
          //flex-direction: row;
          //justify-content: center;
          span, svg {
            //display: flex;
            //flex-direction: column;
            //justify-content: center;
            //float: left;
          }
          //line-height: 40px;
        }
        .sub {
          margin-left: 1rem;
          font-size: 60%;
          opacity: 0.8;
          //display: flex;
          //flex-direction: row;
          //justify-content: center;
          span, svg {
            //float: left;
          }
          //line-height: 40px;
        }
      }
      &.initial {
        width:25%;
        flex-shrink: 0 !important;;
      }
      &.name {
        width:auto;
        font-weight: initial;
        font-size: 100%;
        white-space: nowrap;
       
      }
      &.onhold {
        width:40px;
        font-size: 100%;
        flex-shrink: 0 !important;
      }
      &.remove {
        width:40px;
        display : block;
        opacity: 1 !important;
        margin: 0px auto;
        flex-direction: row;
      }
    }
  }

}

.TP.Glance {
      display: flex;
      flex-direction: column;
      padding:0.5rem 0.25rem;
      //background-color: rgba( $gn-black-color, 0.8 );
      //backdrop-filter: blur(10px);
      //width:100px;
      .Title {
        color : rgba($gn-white-color, 0.75);
        font-size: 80%;
        width:100%;
        text-align: center;
      }
      .Name {
        padding: 0.5rem 0.5rem;
        font-weight: bolder;
        text-align: center;
      }
      .Capital {
        padding: 0rem 0.5rem;
        text-align: center;
        font-size: 120%;
        font-weight: bolder;
        color : rgba($gn-white-color, 1);
      }
      .Profit {
        padding: 0rem 0.5rem;
        padding-top: 0px;
        text-align: center;
        font-size: 90%;
        font-weight: bolder;
        color : rgba($gn-white-color, 1);
        background-color: rgba( $gn-black-color, 0.8 );
        margin-bottom: 0.5rem;
        &.Gain {
          color: rgba($gn-green-color, 1);
        }
        &.Lost {
          color: rgba($gn-red-color, 1);
        }
        &.Normal {
          color: rgba($gn-grey-color, 1);
        }
        .fa {
          margin: 0px 0.5rem;
        }
        span {
          //font-size: 120%;
        }
      }
}



@media screen and (max-width: 750px)  {
  .Portfolio-Point { 

  }
}
