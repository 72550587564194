@import "../../../../public/style.scss";

.trade-docker-2 {
    display : flex;
    flex-direction: column;
    margin: 0.25rem 0px;
    //border: 0px solid rgba($gn-frame-color, 0.5);
    //background-color: rgba( $gn-black-color, 0.5 );
    overflow: hidden; 
    position: relative;
    z-index : 5;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid rgba($gn-black-color, 0.05);
    &:hover {
        .ttitle {
            //.Holder, .Board {
            //background-color : rgba( $gn-frame-color, 0.75 ); 
            //}
        }
    }
    .ttitle {
        transition: all 0.25s ease-in-out;
        display: flex;
        flex-direction: column;
        //background-color : rgba( $gn-frame-color, 0.5 );
        .Holder {
            flex-shrink: 0;
            display : flex;
            flex-direction: row;
            height:auto;
            min-height: 30px;
            justify-content: flex-start;
            cursor: pointer;
            //background-color : rgba( $gn-frame-color, 0.5 );
            transition: all 0.25s ease-in-out;
            //&:hover {
            //    background-color : rgba( $gn-badge-color, 0.25 ); 
            //}
            .tname {
                color : rgba( $gn-frame-color, 1);
                display : flex;
                flex-direction: row; 
                justify-content: left;
                padding: 0rem 1.0rem;
                font-size: 100%;
                flex-grow: 1;
                //margin: auto;
                //white-space:nowrap;
                text-overflow: ellipsis;
                padding-left: 0rem;
                .purchased {
                    //font-size: 90%;
                    margin: auto 0rem;
                    color : rgba( $gn-frame-color-3, 1 );
                    border: 1px solid rgba( $gn-frame-color-3, 1 );
                    //color:rgba( $gn-white-color, 1 );
                    width: calc( 15px - 1rem);
                    height: calc( 15px - 0.5rem);
                    border-radius: 50%;
                    padding:0.25rem 0.5rem;
                    margin-right: 0.0rem;
                    font-size: 60%;
                    //width: fit-content;
                    white-space: nowrap;
                    //height: fit-content;
                }
                .name {
                    margin: auto 0px;
                    line-height: 20px;
                    font-weight: bolder;
                }
            }
            .tarrow {
                color : rgba( $gn-black-color, 1 );
                display : flex;
                flex-direction: column; 
                justify-content: center;
                padding: 0rem 1.0rem;
                font-size: 80%;
                font-weight: bolder;
                height: auto;
                transition: background-color, color 0.25s ease-in-out;
                &:hover {
                    background-color : rgba( $gn-frame-color, 0.5 );
                    color : rgba( $gn-white-color, 1 );
                }
            }
            .trun {
                color : rgba( $gn-frame-color, 0.5 );
                margin : 0rem 0rem;
                padding:0.5rem 1rem;
                margin-left : auto;
                display : flex;
                flex-direction: column; 
                justify-content: center;
                //padding: 0rem 1.0rem;
                //font-size: 80%;
                height: 15px;
                width: 15px;
                transition: all 0.25s ease-in-out;
                &:hover {
                    //background-color : rgba( $gn-green-color, 1 );
                    color : rgba( $gn-frame-color, 1 );
                    transform: scale3d(1.2, 1.2, 1.2);
                }
            }
            .cross {
                color : rgba( $gn-white-color, 0.5 );
                //margin-right : auto;
                display : flex;
                flex-direction: column; 
                justify-content: center;
                padding: 0rem 1.0rem;
                font-size: 80%;
                height: auto;
                transition: background-color, color 0.25s ease-in-out;
                &:hover {
                    background-color : rgba( $gn-red-color, 0.5 );
                    color : rgba( $gn-white-color, 1 );
                }
            }
            .gp {
                &:last-child {
                    //margin-right: auto;
                }
            }
        }
        .Board {
            cursor: pointer;
            transition: all 0.25s ease-in-out;
            //height:30px;
            background-color: rgba( $gn-black-color, 0 );
            display: flex;
            flex-direction: row;
            justify-content: end;
            padding:auto 1rem;
            .Wrapper {
                display: flex;
                flex-direction: column;
                width: 100px;
                //margin: auto 1rem;
                margin: 0rem 1rem;
                height: 100%;
                margin-top: auto;
                .Title {
                    color : rgba( $gn-frame-color-2, 0.5 );
                    font-size: 60%;
                    font-weight: bolder;
                    text-align: right;
                    //height:10px;
                    height: fit-content;
                    flex-shrink: 0;
                    text-transform: uppercase;
                }
                .Value {
                    color : rgba( $gn-black-color, 1 );
                    font-size: 120%;
                    text-align: right;
                    flex-grow: 1;
                    margin-top: auto;
                    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                    &.Gain {
                        color : rgba( $gn-green-color, 1 );
                    }
                    &.Lost {
                        color : rgba( $gn-red-color, 1 );
                    }
                }
            }
        }
    }
    &.selected {
        //position: absolute;
        //top:0.25rem;
        //left:0px;
        //height: fit-content;
        //right:0px;
        //z-index : 10;
        //backdrop-filter: blur(10px);
    }
    .tholder {
        
        display : flex;
        flex-direction: column;
        opacity: 0;
        transition: all 0.35s ease-out;
        max-height: 0;
        //height:fit-content;
        background-color: rgba( $gn-black-color , 0.025);
        overflow: hidden;
        //transform: scaleY(0);
        &.tshow {
            //transform: scaleY(1);
            opacity: 1;
            max-height: 3000px;
            overflow: auto;
            margin-top: 1rem;
        }
    }
    .No-Action {
        padding: 2rem 0rem;
        background-color: rgba( $gn-black-color, 0.05 );
        color : rgba( $gn-black-color , 0.5);
    }
}

