@import "../../public/style.scss";

.Login-Holder {
  /*position:absolute;
  top:0px;
  left:0px;
  right : 0px;
  bottom:0px;
  z-index: 99;
  background-color: rgba($gn-white-color, 1);*/

    .Login-Title {
      position: relative;
      width : calc( 100% - 2rem);
      line-height: 60px;
      text-align: center;
      margin-bottom: 20px;
      .close {
        line-height: 50px;
      }
      .Login-Title-Frame {
        float:left;
        .Login-Title-Logo {
          float:left;
          width:35px;
          height:35px;
          /*padding : 2px 0px;*/
          margin: 7.5px 5px;
          /*border: 0px solid rgba($gn-white-color, 1);
          background-color: rgba($gn-frame-color, 1);
          color : rgba($gn-white-color, 1);
          border-radius: 7.5px;
          -moz-border-radius:7.5px;
          -webkit-border-radius:7.5px;*/
          line-height: 35px;
          font-weight: 600;
          font-size: 30px;
          color : rgba($gn-frame-color, 1);
        }
        .Login-Title-Title {
          float:left;
          height:50px;
          /*padding : 2px 0px;*/
          margin: 0px 2.5px;
          color: rgba($gn-black-color, 1);
          border-radius: 7.5px;
          -moz-border-radius:7.5px;
          -webkit-border-radius:7.5px;
          line-height: 50px;
          font-weight: 600;
          font-size: 18px;
        }
      }
    }

    .Login-Body {
      .Login-Body-Title {
        text-align: center;
        margin-bottom: 40px;
      }
      .Login-Input {
        position: relative;
        margin : 0px calc( 50% - 150px);
        padding : 0px 5px;
        text-align: left;
        color : rgba($gn-dark-color, 1);
        font-size: 15px;;
        margin-bottom: 5px;
        height:40px;
        width : 300px;
        line-height: 40px;
        border : 0px;
        border-bottom: 1px rgba($gn-grey-color, 1) solid;
      }
      .Login-Stm-Frame {
        height:40px;
        line-height: 40px;
        margin : 0px calc( 50% - 150px);
        width : 310px;
        float:left;
        text-align: left;
        cursor: pointer;
        color : rgba($gn-frame-color, 1);
        &:hover {
          color : rgba($gn-green-color, 1);
        }
      }
      .Login-Btn-Frame {
        margin : 0px calc( 50% - 155px);
        width : 310px;
        float:left;
        padding : 0px 0px;
        .Login-Btn {
          float: right;
          position: relative;
          /*width : calc( 90% - 12px);*/
          padding : 0px 20px;
          /*color : rgba($gn-black-color, 1);  
          border: 1px solid rgba($gn-white-color, 1);*/
          height:30px;
          line-height: 30px;
          margin: 5px 0px;
          cursor: pointer;
          background-color: rgba($gn-frame-color, 1);
          color : rgba($gn-white-color, 1);
          margin-left: 10px;
          border-radius: 1rem;
        }
        .Login-Btn-Black {
          background-color: rgba($gn-frame-color, 1);
        }
        .Login-Btn:hover {
          background-color: rgba($gn-green-color, 1);
        }
      }
      .Login-Link {
        position: relative;
        float:left;
        width : 300px;
        height:40px;
        line-height: 40px;
        margin : 0px calc( 50% - 150px);
        text-align: right;
        padding : 0px 5px;
        margin-top: 10px;
        span {
          color: rgba($gn-black-color, 1);
        }
        .Login-Link-a {
          padding: 5px;
          text-decoration: none;
          color: rgba($gn-black-color, 0.75);
        }
        .Login-Link-a:hover {
          color : rgba($gn-frame-color, 1);
          text-decoration: none;
        }
      }
      .LBody {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        .Holder {
          display: flex;
          flex-direction: column;
          .Wrapper {
            position: relative;
            display: flex;
            flex-direction: column;
            margin: 1rem auto;
            width: fit-content;
            &.PWrapper {
              margin: 1.5rem 0;
              width: 100%;
              position: relative;
              z-index : 90;
              .PLabel {
                transition: all 0.25s ease-in-out;
                position: absolute;
                z-index : 5;
                font-size: smaller;
                top: -20px;
                left:0px;
                text-align: left;
                color : rgba( $gn-black-color, 0.5 ) !important;
              }
              .react-tel-input  {
                .form-control {
                  background-color: rgba( $gn-black-color, 0.05 );
                  border-color: rgba($gn-white-color, 1);
                  color : rgba( $gn-white-color, 1 );
                  font-weight: bolder;
                  //text-align: center;
                }
                .flag-dropdown  {
                  border-color: rgba($gn-white-color, 1);
                  background-color: rgba( $gn-black-color, 0.05 );
                }
              }
            }
            &:hover {
              .WLabel {
                top: -20px;
                left:0px;
                color : rgba( $gn-black-color, 1 ) !important;
                font-size: smaller;
              }
              .Value {
                background-color: rgba($gn-frame-color, 0.1) !important;
                box-shadow: 0px 0px 5px rgba($gn-frame-color, 0.2) !important;
              }
            }
            .WLabel {
              transition: all 0.25s ease-in-out;
              position: absolute;
              z-index : 5;
              color : rgba( $gn-black-color, 0 );
              font-size: 100%;
              top:1rem;
              left:0.5rem;
              &.show{
                color : rgba( $gn-black-color, 0.5 );
              }
              &.title{
                top: -20px;
                left:0px;
                color : rgba( $gn-black-color, 0.25 );
                font-size: smaller;
              }
            }
            .Value {
              position: relative;
              z-index : 9;
              transition: all 0.25s ease-in-out;
              margin : 0px auto;
              padding : 0.5rem 1rem;
              text-align: left;
              color : rgba($gn-dark-color, 1);
              font-size: 15px;;
              width : 300px;
              border : 0px;
              border-bottom: 1px rgba($gn-black-color, 0.5) solid;
              background-color: transparent !important;
              border-radius: 0px;
            }
          }
        }
      }
    }
}