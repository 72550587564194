@import "../public/style.scss";

.GManager {
  display: flex;
  position: absolute;
  top:0px;
  right:0px;
  left:0px;
  bottom:0px;
  flex-direction: row;
  background-color: rgba( $gn-black-color, 0.00);
  .Left {
    position: relative;
    top:0px;
    left:0px;
    //width:40px;
    width : 50px;
    //visibility: hidden;
    //max-width:300px;
    //height: calc( 100vh - 30px);
    //flex-basis: 33%;
    //max-width: 300px;
    //background-color: rgba( $gn-frame-color , 1 );
    //box-shadow: 0 8px 10px 0 rgba( 33, 33, 33, 0.37 );
    backdrop-filter: blur( 2px );
    -webkit-backdrop-filter: blur( 2px );
    border-right: 1px solid rgba( $gn-black-color, 0.05);
    z-index: 5;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    .Control {
      margin-left: auto;
      width:50px;
      position: relative;
      z-index: 1001;
      backdrop-filter: brightness(0.5);
      display: flex;
      flex-direction: column;
      .Break {
        background-color: rgba( $gn-white-color , 0.05);
        height:1px;
        width:100%;
        margin:0.5rem 0px;
      }
      .icon {
        width:18px;
        height:18px;
        padding:14px;
        margin:2px;
        color:rgba( $gn-black-color, 1  );
        //background-color: rgba( $gn-frame-color, 1  );
        background-color: rgba( $gn-black-color , 0.00 );
        transition: all 0.25s ease-in-out;
        //box-shadow: 0 0px 5px 0 rgba(33, 33, 33 , 0.5);
        border-top : 1px solid rgba( $gn-black-color , 0.1 );
        border-bottom : 1px solid rgba( $gn-black-color , 0.1 );
        backdrop-filter: blur(2px);
        -webkit-backdrop-filter: blur(2px);
        z-index:999;
        opacity: 0.5;
        transform: scale(0.95);
        &.Cart {
          margin-top: 0.25rem;
          color:rgba( $gn-white-color, 1  );
          background-color: rgba( $gn-frame-color-2, 0.5  );
          &:hover {
            background-color: rgba( $gn-frame-color, 0.5  ) !important;
            transform: scale(1.05) !important;
            color:rgba( $gn-white-color, 1  );
          }
        }
        &:hover {
          background-color: rgba( $gn-frame-color, 0.5  ) !important;
          transform: scale(1.05) !important;
          color:rgba( $gn-white-color, 1  );
          opacity: 0.76;
        }
        &.selected {
          background-color: rgba( $gn-frame-color, 1  ) !important;
          transform: scale(1.2) !important;
          color:rgba( $gn-white-color, 1  );
          opacity: 1;
        }
        &:last-child {
          margin-top: auto;
          margin-bottom: 1rem;
        }
      }
    }

  }
  .Middle, .Middle2, .Middle3 {
    display: flex;
    background-color: rgba( $gn-white-color , 1 );
    //box-shadow: 0 8px 10px 0 rgba( 33, 33, 33, 0.37 );
    border-width: 0px;
    backdrop-filter: blur( 2px );
    -webkit-backdrop-filter: blur( 2px );
    margin-top: 0px;
    margin-bottom:0px;
    flex-grow: 0;
    flex-shrink: 0;
    width : 0px;
    overflow: hidden;
    transition: all 0.25s ease-in-out;
    //border-right: 1px solid rgba( $gn-white-color, 0.25 );
    &.appear{
      width : 320px;
      //box-shadow: 0px 0px 5px rgba( $gn-white-color, 0.1 );
      //margin-right: 0.5px;
      //border-right: 1px solid rgba($gn-frame-color, 0.5);
      border-right: 1px solid rgba( $gn-black-color, 0.1 );
    }
  }
  .Middle {
    &.appear{
      //border-right: 1px solid rgba( $gn-badge-color , 0.5 );
    }
  }
  .Middle2 {
    position: relative;
    z-index: 99;
    //box-shadow: 0px 0px 5px rgba( $gn-badge-color, 0.5 );
  }
  .Right {
    flex-grow: 1;
    position: relative;
    overflow: hidden;
    z-index:1;
    display: flex;
    flex-direction: column;
    margin-top: 0px;
  }

  .Ext {
    position: absolute;
    top:0px;
    left:50px;
    right:0px;
    display : flex;
    flex-direction: column;
    background-color: rgba( $gn-black-color , 0.75);
    //box-shadow: 0 8px 10px 0 rgb(33, 33, 33 / 37%);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    //width: calc( 100% + 1px);
    height:70px;
    margin-left: -1px;
    z-index:999;
    display: none;
    .upper {
      height:40px;
      width : 100%;
      display : flex;
      flex-direction: row;
      .btn {
        height:30px;
        margin: 5px;
        padding:0px 0.5rem;
        border-radius: 0px;
        background-color: rgba( $gn-white-color, 0.1 ) !important;
        border-style : none;
        font-size: 85%;
        line-height: 1;

        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 250px;
        white-space: pre;
        .name {
          color : rgba($gn-badge-color, 1);
          font-size: 100%;
        }
        &:hover {
          background-color: rgba( $gn-badge-color, 0.25 ) !important;
        }
        &.selected {
          background-color: rgba( $gn-frame-color, 0.5 ) !important;
        }
        &.disable {
          color: rgba( $gn-white-color, 0.5 ) !important; 
        }
        &.contribute {
          background-color: transparent !important;
          border : 2px solid rgba( $gn-green-color, 0.5 ) !important;
          height: calc( 30px - 4px );
          line-height: calc( 30px - 4px );
          border-radius: 0.25rem;
          transition: background-color 0.25s ease-in-out;
          margin-left:auto;
          margin-right:0.5rem;
          font-size: 110%;
          span{
            color : rgba( $gn-green-color, 1 ) !important;
            transition: color 0.25s ease-in-out;
          }
          &:hover:not(.disable) {
            background-color: rgba( $gn-green-color, 0.8 ) !important;
            span {
              color : rgba( $gn-white-color, 1 ) !important;
            }
          }
          &.disable {
            display: none;
          }
        }
      }
      .dholder {
        display: flex;
        flex-direction: row;
        .dropdown {
          display: flex;
          flex-direction: row;
          .btn {
            padding-right: 0px;
            //background-color: rgba( $gn-white-color , 0.1) !important;
          }
          .title {
            margin : auto 0px;
            transition: background-color 0.25s ease-in-out;
            overflow-wrap: break-word;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 200px;
            //&:hover {
            //  background-color: rgba( $gn-badge-color, 0.5 ) !important;
            //};
          }
          .dropdown-toggle {
            display: flex;
            flex-direction: row;
            margin-right: 0px;
            &:after {
              margin: auto 0.5rem;
              transition: background-color 0.25s ease-in-out;
              &:hover {
                background-color: rgba( $gn-badge-color, 0.5 ) !important;
              };
            }
          }
          .dropdown-menu {
            //border: 1px solid rgba( $gn-white-color, 0.1 );
            border-top-width:0px;
            background-color: rgba( $gn-white-color , 0.8 );
            backdrop-filter: blur(5px);
            width:fit-content;
            display: flex;
            flex-direction: column;
            justify-content: stretch;
            padding: 0px 0rem;
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
            margin-top: -0.25rem !important;
            //box-shadow: 0px 0px 5px rgba( $gn-white-color , 0.5);
          }
          .dropdown-item {
            flex-grow: 1;
            //width:fit-content;
            padding: 0px 1rem;
            margin: 0.25rem 0rem;
            background-color: rgba( $gn-black-color , 0.0 ) !important;
            color : rgba($gn-black-color, 1) !important;
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-weight: bold;
            border-bottom: 1px solid rgba( $gn-white-color, 0.1 ) !important;
            width: calc( 100% - 2rem);
            &:first-child {
              //border-top-left-radius: 0px;
              //border-top-right-radius: 0px;
            }
            &:last-child {
              border-bottom: 0px solid rgba( $gn-white-color, 0.1 ) !important;
            }
            &:hover {
              background-color: rgba( $gn-badge-color , 0.5 ) !important;
            }
          }
        }
        .fa {
          margin: auto 0rem;
          color : rgba($gn-white-color, 1);
          background-color: rgba( $gn-white-color , 0.1);
          height: 30px;
          padding : 0px 0.5rem;
          transition: background-color 0.25s ease-in-out;
          &.disable {
            color: rgba( $gn-white-color , 0.5);
          }
          &:hover:not(.disable) {
            background-color: rgba( $gn-frame-color , 0.5);
          }
        }
      }
    }
    .lower {
      height:30px;
      width : 100%;
      display : flex;
      flex-direction: row;
      justify-content: space-between;
      position: relative;
      .title {
        //flex-grow: 1;
        strong {
          cursor: default;
          color : rgba( $gn-white-color , 1);
          background-color: rgba( $gn-white-color, 0.05  );
          //width: fit-content;
          font-size: 120%;
          display: flex;
          flex-direction: column;
          text-align: center;
          justify-content: center;
          height: calc( 20px - 0px );
          margin:4px 0px;
          padding:0px 1rem;
          border-radius: 0.25rem;
          border: 1px solid rgba( $gn-white-color, 0.25 );
          //border-bottom-left-radius: 0.5rem;
          //border-bottom-right-radius: 0.5rem;
          //border-left :1px solid rgba( $gn-white-color, 0.25 );
          //border-right :1px solid rgba( $gn-white-color, 0.25 );
          //border-bottom :1px solid rgba( $gn-white-color, 0.25 );
        }
        position: absolute;
        z-index : 1;
        top:0px;
        left:50px;
        right:50px;
        bottom:0px;
        font-weight: bolder;
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
      .btn {
        position: relative;
        z-index : 5;
        height:20px;
        margin: 5px;
        padding:0px 0.5rem;
        border-radius: 0px;
        background-color: rgba( $gn-white-color, 0.1 ) !important;
        border-style : none;
        &.disable {
          &.fa, span {
            color : rgba( $gn-light-color , 0.5);
          }
        }
        &.next {
          margin-left: auto;
        }
        &.back {
          margin-right: auto;
        }
        &:hover {
          background-color: rgba( $gn-badge-color, 0.25 ) !important;
        }
        &.contribute {
          display: none;
          background-color: transparent !important;
          border : 2px solid rgba( $gn-green-color, 0.5 ) !important;
          height: calc( 20px - 2px );
          line-height: calc( 20px - 2px );
          border-radius: 0.25rem;
          transition: background-color 0.25s ease-in-out;
          font-size: 110%;
          span{
            color : rgba( $gn-green-color, 0.8 ) !important;
            transition: color 0.25s ease-in-out;
          }
          &:hover {
            background-color: rgba( $gn-green-color, 0.8 ) !important;
            span {
              color : rgba( $gn-white-color, 0.8 ) !important;
            }
          }
        }
        .fa, span {
          color : rgba( $gn-light-color , 0.8);
          font-size: 90%;
        }
        .fa {
          font-size: 70%;
        }
        span {
          margin: 0px 0.5rem;
          font-weight: bolder;
        }
      }
    }

  }

}



//@media screen and (max-width: 750px)  {
@media screen and (max-width: 900px)  {
  .GManager {
    width: fit-content;
    min-width: 100%;
    .Left {
      position: sticky;
      left:0px;
      z-index : 9999;
    }
    .Middle {
      //position: absolute;
      //width:300px;
      //left:40px;
      //top:70px;
      //bottom: 30px;
      //z-index: 99;
    }
    .Ext {
      .upper {
        flex-wrap: wrap;
        flex-shrink: 0;
      }
    }
  }
}