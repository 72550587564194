@import "../../public/style.scss";

.TemplateDetail {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width:100%;
  .Control {
    position: relative;
    display: flex;
    flex-direction: row;
    padding : 2.5rem 0.5rem;
    justify-content: flex-start;
    .Btn {
      position: relative;
      margin-right:0px !important;
      border-radius: 0.5rem;
      background-color: rgba($gn-frame-color, 1) !important;
      color : rgba($gn-white-color, 1);
      border: 0px solid transparent;
      font-weight: bolder;
      &.config {
        //background-color: transparent !important;
        //color : rgba( $gn-badge-color, 0.8 );
        //border: 2px solid rgba( $gn-badge-color, 0.5 ) !important;
        //border-radius: 0.25rem;
        //font-weight: bolder;
        //font-size: 110%;
        &:hover {
          background-color: rgba($gn-badge-color, 1 ) !important;
          color : rgba( $gn-white-color, 1 )!important;
          .Fa {
            color : rgba( $gn-white-color, 1 )!important;
          }
        }
        .Fa {
          transition: color 0.25s ease-in-out;
          color: rgba( $gn-white-color, 1 );
        }
      }
      .Fa {
        color: rgba($gn-white-color, 1);
        width:10px;
        margin-right: 0.5rem;
      }
      &:hover {
        background-color: rgba($gn-badge-color, 1) !important;
        //color : rgba($gn-white-color, 1);
      }
      &.left {

      }
      &.right {
        margin-left:auto;
      }
      &.disappear {
        visibility: hidden;
      }
    }
    .holder {
      width : 100%;
    }   

  }
  .Holder {
    overflow: hidden;
    flex-grow: 1;
    //height: calc( 100% - 50px);
    //margin-top: 11px;
    border-top: 1px solid rgba( $gn-black-color, 0.1 );

    .Docker {
      display: flex;
      flex-direction: column;
      .Mode {
        margin: 2rem 1.5rem;
        width : calc( 100% - 3rem);
        .btn {
          background-color: rgba(  $gn-frame-color, 1  ) !important;
          //color : rgba(  $gn-white-color, 1 );
          //border:0px solid rgba($gn-white-color, 0.1) !important;
          font-size: 100%;
          opacity: 1;
          transition: all 0.25s ease-in-out;
          transform: scale(1.05);
          position: relative;
          z-index : 5;
          &.Disabled {
            opacity: 0.5;
            transform: scale(1);
            z-index : 1;
          }
          &:hover {
            background-color: rgba(  $gn-frame-color, 0.5  ) !important;
          }
          &:first-child {
            border-top-left-radius: 0.5rem ;
            border-bottom-left-radius: 0.5rem ;
          }
          &:last-child {
            border-top-right-radius: 0.5rem ;
            border-bottom-right-radius: 0.5rem ;
            margin-left:2px
          }
        }

      }
      .Title {
        background-color: rgba(  $gn-frame-color, 1  );
        color : rgba( $gn-white-color, 1 );
        padding : 0.25rem 0rem;
        cursor: default;
        width: calc( 100% - 3rem );
        margin:0px auto;
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
        font-size: 100%;
        font-weight: bolder;
      }
      .Value {
        margin:0rem 1.5rem;
        margin-top: 1px;
        margin-bottom: 2rem;
        background-color: rgba( $gn-black-color, 0.05 );
        color : rgba( $gn-black-color, 1 );
        font-size: 100%;
        border-style: none;
        width : calc( 100% - 4.5rem);
        border-radius: 0px;
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        transition: background-color 0.25s ease-in-out;
        &:hover {
          background-color: rgba( $gn-frame-color, 0.1 );
        }
      }
      .Text {
        margin:0rem 1.5rem;
        margin-top: 1px;
        margin-bottom: 2rem;
        background-color: rgba( $gn-black-color, 0.05 );
        color : rgba( $gn-black-color, 1 );
        font-size: 100%;
        border-style: none;
        width : calc( 100% - 4.5rem);
        border-radius: 0px;
        min-height: 250px;
        transition: all 0.25s ease-in-out;
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        &:hover {
          background-color: rgba( $gn-frame-color, 0.1 );
        }
      }
      &.Two {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 2rem;
        padding: 0px 1.5rem;
        .Input {
          overflow: hidden;
          flex-basis: 50%;
          position: relative;
          transition: all 0.25s ease-in-out;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
            margin-left: 1px;
          }
          &:hover, &:focus {
            .TValue {
              opacity: 0;
            }
            .TInput {
              opacity: 1 !important;
              background-color: rgba( $gn-frame-color, 0.1 );
            }
          }
          .TTitle {
            color: rgba( $gn-white-color, 1 );
            font-size: 90%;
            background-color: rgba(  $gn-frame-color, 1  );
            margin-bottom: 1px;
            padding:0.25rem 0px;
          }
          .TValue {
            color: rgba( $gn-black-color, 1 );
            font-size: 90%;
            background-color: rgba( $gn-black-color, 0.05 );
            border-style: none;
            padding:0.25rem 0px;
            text-align: center;
            border-radius: 0px;
            transition: background-color 0.25s ease-in-out;
            height : calc(1.5em + 0.75rem + 2px);
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: relative;
            z-index: 1;
            &.TInput {
              opacity: 0;
              position: absolute;
              z-index: 9;
              &:focus, &.Locked  {
                opacity: 1 !important;
                background-color: rgba( $gn-frame-color, 0.1 );
              }
            }
            &.Hide {
              opacity: 0;
            }
          }
        }
      }
      .Btn {
        width:fit-content;
        position: relative;
        margin-top:2.0rem;
        margin-right:1.5rem !important;
        margin-left: auto;
        border-radius: 0.5rem;
        background-color: rgba($gn-frame-color, 1) !important;
        color : rgba($gn-white-color, 1);
        border: 0px solid transparent;
        font-weight: bolder;
        &:hover {
          background-color: rgba($gn-badge-color, 1) !important;
          //color : $gn-white-color;
        }
        .Fa {
          color: rgba($gn-white-color, 1);
          width:10px;
          margin-right: 0.5rem;
        }
      }
      .Regions {
        position: relative;
        //margin-right:0px !important;
        border-radius: 0.5rem !important;
        margin: 2rem 0px;
        margin-bottom: 0px;
        margin-left : 1.5rem;
        margin-right : auto;
        background-color : transparent !important;
        //background-color: rgba($gn-badge-color, 0.5) !important;
        color : rgba($gn-white-color, 1);
        border: 0px solid rgba($gn-badge-color, 0.5) !important;
        font-weight: bolder;
        .btn {
          border-radius: 0.5rem !important;
          padding:0.375rem 1rem;
          font-weight: bolder;
          border-width: 0px;       
          display: flex;
          flex-direction: row;           
          .Scope {
            display: flex;
            flex-direction: row;
            font-size: 100%;
            font-weight: bolder;
            .Region {
              margin:auto 0px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              background-color: rgba( $gn-frame-color , 0);
              height : fit-content;
              .react-emojis {
                font-size: 20px;
                font-weight: 400;
                margin: auto 0.5rem;
                //margin-top: 0.5rem;
              }
            }
          }
          &::after {
            margin: auto;
            margin-left: 0.5rem;
          }
          //display: flex;
          //flex-direction: row;
          //justify-content: center;
        }
        .dropdown-menu {
          .Line {
            display: flex;
            flex-direction: row;
            font-size: 100%;
            font-weight: bolder;
            padding : 1rem 0.5rem;
            width : calc( 100% - 1rem);
            border-bottom: 1px solid rgba( $gn-black-color, 0.1 );

            .Region {
              margin:auto 0px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              background-color: rgba( $gn-frame-color , 0);
              height : fit-content;
              .react-emojis {
                font-size: 20px;
                font-weight: 400;
                margin: auto 0.5rem;
                //margin-top: 0.5rem;
              }
            }
          }
        }

      }
      &.H {
        flex-direction: row;
      } 
    }
  }
}

@media screen and (max-width: 750px)  {
  .TemplateDetail{

  }
}