@import "../../public/style.scss";
@import url(https://fonts.googleapis.com/css?family=Josefin+Sans:100,300,400);
 

.Portfolio-Card { 
  background-color: rgba( $gn-white-color, 1);
  //background-color: rgba($gn-frame-color, 1);
  margin : auto 0.5rem;
  width: calc( 320px - 1rem);
  height:auto;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba( $gn-black-color, 0.1 );
  transition : all 0.25s ease-in-out;
  cursor: pointer;
  z-index: 1;
  position: relative;
  border-radius: 0.5rem;
  //border-top-left-radius: 0.5rem;
  //border-top-right-radius: 0.5rem;
  overflow: initial;
  justify-content: flex-start;
  opacity: 1;
  &:hover:not(.title):not(.selected) {
    //background-color: rgba($gn-frame-color, 1);
    z-index : 4;
    //background-color: rgba( $gn-black-color, 0.1 );
    transform: scale3d(1.05, 1.05, 1.05);
    //box-shadow: 0px 0px 5px rgba($gn-black-color, 0.25);
    //opacity: 1;
    //background-color: rgba($gn-badge-color, 0.5) ;
    //border: 2px solid rgba( $gn-badge-color, 0.5 );
    box-shadow: 0px 0px 10px rgba($gn-badge-color, 0.75);
    filter: blur(0px) !important;
    //opacity: 1;
    .Middle {
      .Chart {
        transform: scale(1.1);
        .Wrapper {
          .Cirlce {
            box-shadow: 0px 0px 5px rgba( $gn-black-color, 0.5 );
          }
        }
      }
    }
    
    

  }
  &:hover .box:not(.title) {
    //opacity: 0.8;
  }
  &.noselected {
    opacity: 0.5;
    //filter: blur(5px);
    //background: rgba( var(--gn-black-color), 0.8 );
    //.Middle {
    //  background: rgba( var(--gn-black-color), 0.8 );
    //}
  }
  &.selected {
    z-index: 5;
    //background-color: rgba( $gn-black-color, 0.1 );
    transform: scale3d(1.1, 1.1, 1.1);
    box-shadow: 0px 0px 5px rgba($gn-black-color, 0.25);
    //position: sticky;
    //top:100px;
    //box-shadow: 0px 0px 5px rgba($gn-black-color,0.25);
    //height:50px;
    .box {
      opacity: 0.7;
      display : none;
      //line-height: 35px;
      //line-height: 30px;
      &.profit, &.name ,&.onhold {
        display : block;
        opacity: 1 !important;
        font-size: 150%;
        width:40%;
        line-height: 35px;
        //display: flex;
        //flex-direction: column;
        //justify-content: center;

      }
      &.profit {
        flex-direction: row;
        display: flex;
        justify-content: center;
        flex-shrink: 0 !important;;
        //margin:2.5px;
        .main {
          font-size: 100%;
          //display: flex;
          //flex-direction: row;
          //justify-content: center;
          span, svg {
            //display: flex;
            //flex-direction: column;
            //justify-content: center;
            //float: left;
          }
          //line-height: 40px;
        }
        .sub {
          margin-left: 1rem;
          font-size: 60%;
          opacity: 0.8;
          //display: flex;
          //flex-direction: row;
          //justify-content: center;
          span, svg {
            //float: left;
          }
          //line-height: 40px;
        }
      }
      &.initial {
        width:25%;
        flex-shrink: 0 !important;;
      }
      &.name {
        width:auto;
        font-weight: initial;
        font-size: 100%;
        white-space: nowrap;
       
      }
      &.onhold {
        width:40px;
        font-size: 100%;
        flex-shrink: 0 !important;
      }
      &.remove {
        width:40px;
        display : block;
        opacity: 1 !important;
        margin: 0px auto;
        flex-direction: row;
      }
    }
  }



  .Upper {
    height:auto;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
    margin-bottom:1rem;
    &.Gain {
      //background-color: rgba($gn-green-color, 1);
      .name {
        //color :rgba( $gn-green-color, 1 );
      }
      .Initial-Holder {
        .Title {
          color :rgba( $gn-green-color, 1 );
          border-color: rgba( $gn-green-color, 1 );
        }
        .Value {
          color :rgba( $gn-green-color, 1 );
        }
      }
    }
    &.Lost {
      //background-color: rgba($gn-red-color,1);
      .name {
        //color :rgba( $gn-red-color, 1 );
      }
      .Initial-Holder {
        .Title {
          color :rgba( $gn-red-color, 1 );
          border-color: rgba( $gn-red-color, 1 );
        }
       .Value {
          color :rgba( $gn-red-color, 1 );
        }
      }
    }
    &.Overdraft {
      //background-color: rgba($gn-dark-color,1);
      .name {
        //color :rgba($gn-grey-color, 0.5);
      }
      .Initial-Holder {
        .Title {
          color :rgba( $gn-black-color, 1 );
          border-color: rgba( $gn-black-color, 1 );
        }
        .Value {
          color :rgba( $gn-grey-color, 1 );
          text-decoration: line-through;
        }
      }
    }
    &.Normal {
      //background-color: rgba($gn-grey-color, 1);
      .name {
        //color :rgba($gn-dark-color,1);
      }
      .Initial-Holder {
        .Value {
          color :rgba( $gn-dark-color, 1 );
        }
      }
    }
    .name {
      color :rgba( $gn-frame-color, 1 );
      font-size: 120%;
      padding: 1rem 30px;
      padding-bottom: 0rem;
      font-weight:lighter;
      overflow: hidden;
      white-space:pre;
      text-overflow: ellipsis;
      font-weight: bolder;
      text-align: left;
    }
    .Initial-Holder {
      display: flex;
      flex-direction: column;
      .Title {
        margin: 1rem auto;
        margin-bottom: 0px;
        //background-color: rgba( $gn-frame-color , 1);
        border : 2px solid rgba( $gn-frame-color , 0.5);
        font-size: 60%;
        padding: 0rem 2rem;
        width: fit-content;
        border-radius: 0.5rem;
        font-weight: bolder;
        color : rgba( $gn-frame-color , 1 );
      }
      .Value {
        margin: 0rem auto;
        margin-top: -0.25rem;
        color: rgba($gn-white-color, 1);
        font-size: 200%;
        padding: 0rem 0.5rem;
      }
    }
    .Star {
      position: absolute;
      top:0.5rem;
      left:0.5rem;
      width:25px;
      height:25px;
      color : rgba($gn-light-color, 1);
      opacity: 0.4;
      transition: all 0.25s ease-in-out;
      &.active {
        color : rgba($gn-white-color, 1);
        opacity: 1;
        filter: drop-shadow(0px 0px 1px rgba( $gn-white-color, 1 ) );
      }
      &:hover:not(.active) {
        color : rgba($gn-white-color, 1);
        opacity: 0.5;
      }
    }
    .Region {
      margin:auto 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: rgba( $gn-frame-color , 0);
      height : fit-content;
      position: absolute;
      top: 1rem;
      left: 0.25rem;
      .react-emojis {
        font-size: 20px;
        font-weight: 400;
        margin: auto 0.5rem;
        //margin-top: 0.5rem;
      }
    }
  }


  .Middle {
    z-index : 2;
    position: relative;
    width:calc(100% + 0rem);
    left: -0rem;
    //height:250px;
    margin: 0px auto;
    margin-top: -0px;
    background-color: rgba( $gn-white-color , 0.85 );
    backdrop-filter: blur( 5px );
    //box-shadow: 0px 0px 5px rgba( $gn-black-color, 0.2 );
    display: flex;
    flex-direction: row;
    padding-bottom: 10px;
    border-radius: 0.25rem;
    .Profit-Holder {
      display :flex;
      flex-direction: column;
      .Title {
        display: none;
          margin: 0rem auto;
          margin-top: -15px;
          margin-bottom: 0.5rem;
          background-color: rgba( $gn-white-color , 0.0);
          font-size: 60%;
          padding: 0rem 0.5rem;
          width: fit-content;
          font-weight: bolder;
          border-top-left-radius: 0.25rem;
          border-top-right-radius: 0.25rem;
          color : rgba( $gn-white-color , 1);
      }
      .Wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: auto;
        &.Gain {
          color: rgba($gn-green-color, 1);
        }
        &.Lost {
          color: rgba($gn-red-color, 1);
        }
        &.Normal {
          color: rgba($gn-grey-color, 1);
        }
        .fa {
          margin: 0.3rem;
          font-size: 20px;
        }
        span {
          font-size: 120%;
        }
      }
    }
    
    .Left {
      width:125px;
      flex-shrink: 0;
      &.Gain {
        //background-color: rgba($gn-green-color, 1);
        .name {
          //color :rgba( $gn-green-color, 1 );
        }
        .Initial-Holder {
          .Title {
            //color :rgba( $gn-green-color, 1 );
            //border-color: rgba( $gn-green-color, 1 );
          }
          .Value {
            color :rgba( $gn-green-color, 1 );
          }
        }
      }
      &.Lost {
        //background-color: rgba($gn-red-color,1);
        .name {
          //color :rgba( $gn-red-color, 1 );
        }
        .Initial-Holder {
          .Title {
            //color :rgba( $gn-red-color, 1 );
            //border-color: rgba( $gn-red-color, 1 );
          }
         .Value {
            color :rgba( $gn-red-color, 1 );
          }
        }
      }
      &.Overdraft {
        //background-color: rgba($gn-dark-color,1);
        .name {
          //color :rgba($gn-grey-color, 0.5);
        }
        .Initial-Holder {
          .Title {
            //color :rgba( $gn-black-color, 1 );
            //border-color: rgba( $gn-black-color, 1 );
          }
          .Value {
            color :rgba( $gn-grey-color, 1 );
            text-decoration: line-through;
            font-size: 100%;
          }
        }
      }
      &.Normal {
        //background-color: rgba($gn-grey-color, 1);
        .name {
          //color :rgba($gn-dark-color,1);
        }
        .Initial-Holder {
          .Value {
            color :rgba( $gn-dark-color, 1 );
          }
        }
      }
      .Initial-Holder, .Profit-Holder {
        display: flex;
        flex-direction: column;
        padding:0px 1rem;
        .Title {
          margin: 1rem 0rem;
          margin-bottom: 0px;
          //background-color: rgba( $gn-frame-color , 1);
          //border : 2px solid rgba( $gn-frame-color , 0.5);
          font-size: 80%;
          padding: 0rem 0rem;
          width: fit-content;
          border-radius: 0.5rem;
          //font-weight: light;
          color : rgba( $gn-black-color , 0.5 );
        }
        .Value {
          margin: 0rem 0rem;
          margin-top: 0.0rem;
          color: rgba($gn-white-color, 1);
          font-size: 150%;
          padding: 0rem 0rem;
          //font-weight: bold;
          &.Percent {
            font-size: 120%;
            color : rgba( $gn-black-color, 1);
            text-align: right;
          }
        }
        
      }
    }

    .Right {
      .Chart {
        width : calc(90% - 0px);
        position: relative;
        margin: 0px auto;
        //max-height: 200px;
        display: inline-block;
        position: relative;
        transition: all 0.25s ease-in-out;
        .Wrapper {
          width: 100%;
          margin-top: 100%;
          .Circle {
            margin: auto 0px;;
            top:5%;
            left:5%;
            position: absolute;
            display: flex;
            width: 90%;
            height:90%;
            border-radius: 50%;
            background: conic-gradient(rgba( $gn-black-color, 0.75 ) var(--progress), rgba( $gn-black-color, 0.5 ) 0deg);
            font-size: 0;
            animation: .4s ease-out turn_in reverse; 
            &.gain {
              background: conic-gradient(rgba( $gn-green-color, 0.75 ) var(--progress), rgba( $gn-black-color, 0.5 ) 0deg);
            }
            &.lose {
              background: conic-gradient(rgba( $gn-black-color, 0.25 ) var(--progress), rgba( $gn-red-color, 0.5 ) 0deg);
            }
          }
          .Circle-Inner {
            //content: attr(data-progress);
            display: flex;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            margin: 15px;
            border-radius: 50%;
            background: white;     
            text-align: center;
            position: relative;
            .Scores {
              position: absolute;
              left:0px;
              right: 0px;
              top:0px;
              bottom: 0px;
              background-color: white;
              z-index:5;
              border-radius: 50%;
              margin: 5px;
              overflow: hidden;
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              .box {
                flex-basis: calc(50% - 2px);
                margin: 1px;
                flex-direction: column;
                display: flex;
                justify-content: center;
                transition: all 0.25s ease-in-out;
                position: relative;
                z-index: 1;
                &:hover {
                  transform: scale(1.1);
                  z-index: 5;
                  box-shadow: 0px 0px 5px rgba($gn-black-color, 0.5);
                }
                .Title {
                  height:15px;
                  color: $gn-white-color;
                  font-size: 9px;
                  line-height: 9px;
                  text-align: center;
                }
                .Rank {
                  height:15px;
                  color: $gn-white-color;
                  font-size: 10px;
                  line-height: 7px;
                  text-align: center;
                }
                &:first-child {
                  .Title, .Rank {
                    transform: rotate(315deg) translate(-10px, -5px);
                  }
                  .Rank {
                    transform: rotate(315deg) translate(0px, -5px);
                  }
                }
                &:nth-child(2) {
                  .Title, .Rank {
                    transform: rotate(45deg) translate(10px, -5px);
                  }
                  .Rank {
                    transform: rotate(45deg) translate(0px, -5px);
                  }
                }
                &:nth-child(3) {
                  .Title, .Rank {
                    transform: rotate(45deg) translate(0px, 15px);
                  }
                  .Title {
                    transform: rotate(45deg) translate(5px, 15px);
                  }
                }
                &:nth-child(4) {
                  .Title, .Rank {
                    transform: rotate(315deg) translate(-8px, 15px);
                  }
                  .Rank {
                    transform: rotate(315deg) translate(0px, 15px);
                  }
                }
              }
            }
            .Wrapper {
              width:auto;
              position: absolute;
              left:0px;
              right: 0px;
              top:0px;
              bottom: 0px;
              display: flex;
              flex-direction: row;
              justify-content: center;
              border-radius: 50%;
              margin: 40px;
              //position: relative;
              z-index: 10;
              background-color: rgba($gn-white-color, 0.9) ;
              backdrop-filter: blur(4px);
              &.Gain {
                color: rgba($gn-green-color, 1);
              }
              &.Lost {
                color: rgba($gn-red-color, 1);
              }
              &.Normal {
                color: rgba($gn-grey-color, 1);
              }
              .fa {
                margin: auto 0.3rem;
                font-size: 1.5rem;
              }
              .Percent {
                margin: auto 0rem;
                font-size: 2rem;
              }
            }
          }

          @keyframes turn_in {
            5% {
              background: conic-gradient(red calc(var(--progress) * .95), gray 0deg);
            }
            10% {
              background: conic-gradient(red calc(var(--progress) * .9), gray 0deg);
            }
            15% {
              background: conic-gradient(red calc(var(--progress) * .85), gray 0deg);
            }
            20% {
              background: conic-gradient(red calc(var(--progress) * .8), gray 0deg);
            }
            25% {
              background: conic-gradient(red calc(var(--progress) * .75), gray 0deg);
            }
            30% {
              background: conic-gradient(red calc(var(--progress) * .7), gray 0deg);
            }
            35% {
              background: conic-gradient(red calc(var(--progress) * .65), gray 0deg);
            }
            40% {
              background: conic-gradient(red calc(var(--progress) * .6), gray 0deg);
            }
            45% {
              background: conic-gradient(red calc(var(--progress) * .55), gray 0deg);
            }
            50% {
              background: conic-gradient(red calc(var(--progress) * .5), gray 0deg);
            }
            55% {
              background: conic-gradient(red calc(var(--progress) * .45), gray 0deg);
            }
            60% {
              background: conic-gradient(red calc(var(--progress) * .4), gray 0deg);
            }
            65% {
              background: conic-gradient(red calc(var(--progress) * .35), gray 0deg);
            }
            70% {
              background: conic-gradient(red calc(var(--progress) * 0.3), gray 0deg);
            }
            75% {
              background: conic-gradient(red calc(var(--progress) * 0.25), gray 0deg);
            }
            80% {
              background: conic-gradient(red calc(var(--progress) * .2), gray 0deg);
              }
            85% {
              background: conic-gradient(red calc(var(--progress) * .15), gray 0deg);
              }
            90% {
                background: conic-gradient(red calc(var(--progress) * .1), gray 0deg);
              }
            95% {
                background: conic-gradient(red calc(var(--progress) * .05), gray 0deg);
              }
            100% {
              background: conic-gradient(gray 0deg);
            }
          }

        }


        




      }
    }


  }

  .Lower {
    display: flex;
    flex-direction: row;
    //background-color: aqua;
    flex-grow: 1;
    padding: 0.5rem 1rem;
    font-weight: bolder;
    .Subscribed {
      justify-self: start;
      background-color: rgba($gn-frame-color-4, 1);
      color : rgba($gn-white-color, 1);
      margin: auto 0.5rem;
      padding: 0rem 0.5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 70%;
      height:15px;
      border-radius: 0.25rem;
    }
    .Owner {
      justify-self: start;
      background-color: rgba($gn-black-color, 1);
      color : rgba($gn-white-color, 1);
      margin: auto 0.5rem;
      padding: 0rem 0.5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 70%;
      height:15px;
      border-radius: 0.25rem;
    }
    .Recent {
      justify-self: start;
      background-color: rgba($gn-badge-color, 1);
      color : rgba($gn-white-color, 1);
      margin: auto 0.5rem;
      margin-left: auto;
      padding: 0rem 0.5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 70%;
      height:15px;
      border-radius: 0.25rem;
    }
    .Onhold {
      margin:  0.5rem;
      margin-left: auto;
      padding: 0rem 0.5rem;
      background-color: rgba($gn-badge-color, 1);
      position: relative;
      display: flex;
      flex-direction: column;
      border-radius: 0.25rem;
      font-size: 70%;
      justify-content: center;
      height:15px;
      color : rgba( $gn-white-color , 1);
      &.Empty {
        background-color: rgba($gn-light-color, 1);
      }
      .Title {
        //position: absolute;
        //top:0px;
        color : rgba( $gn-white-color , 0.9);
        font-size: 60%;
        margin: auto;
      }
      .Value {
        color : rgba( $gn-white-color , 1);
      }

    }

    .More {
      margin-left: auto;
      display: flex;
      flex-direction: row;
      font-weight: lighter;
      color : rgba( $gn-black-color , 0.5 );
      .Fa {
        margin: auto 0.5rem;
      }
      .Text {
        margin: auto 0rem;
      }
    }

  }

}

@media screen and (max-width: 750px)  {
  .Portfolio-Card { 
    //justify-content: space-evenly;
    //height:400px;
    height: auto;
    margin: auto;
    .title{
      font-size: 75% !important;
      width:auto !important;
    }
    .read {
      margin-left: 5px;
    }

    .Middle {
      .Chart {
        width:60%;
      }
    }
  }
}
