@import "../../../public/style.scss";

.IDE {
  flex-grow : 1;
  position: relative;
  //min-height: 30px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  background-color: rgba( $gn-white-color, 1 );
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  border-top:1px solid rgba( $gn-black-color, 0.1 ) ;
  padding-top:0px;
  //background-color: rgba( $gn-badge-color, 0.25 );;
  .Textarea {
    height: 100% !important;
    border-style: none;
    background-color: rgba( $gn-white-color, 1 );
    border-bottom: 1px solid rgba( $gn-white-color, 0.05 );
    .ace_gutter {
      width: 20px !important;
      .ace_gutter-layer {
        width: 20px !important;
        .ace_gutter-cell  {
          padding:0px 5px !important
        }
      }
    }
    .ace_content {
      transform : translate(0px, 0px) !important;
      border-style: none;
      //width:100% !important;
    }
    .ace_scroller {
      left: 27.4023px !important;
    }
  }
}


@media screen and (max-width: 751px)  {
  .Garlican-IDE {

  }
}