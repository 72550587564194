@import "../public/style.scss";

* {
  -webkit-box-sizing: initial !important;
  -moz-box-sizing: initial !important;
  box-sizing: initial !important;
}

@keyframes _flashing {
  from {
    background-color: rgba($gn-red-color , 0.5 );
    color: rgba($gn-white-color, 1);
    transform: scale3d(1, 1, 1);
    border:1px solid rgba($gn-red-color, 1);
    //height:200px;
    //height:20px;
    
  }
  to {
    background-color: transparent;
    color: rgba($gn-red-color , 1 );
    //background-color: rgba($gn-red-color , 1 );
    transform: scale3d(1.1, 1.1, 1.1);
    border:0px dashed rgba($gn-red-color, 1);
    border-style:groove;
    
  }
}


.Controller-Holder {

  width:100%;
  height:40px;
  z-index: 99;
  background-color: rgba($gn-white-color, 1);
  display: flex;
  flex-direction: row;
  //border-bottom: 1px solid rgba( $gn-black-color, 0.05 );
  &.dark, &.tiny{
    background-color: rgba( $gn-black-color , 0.8 );
    box-shadow: 0 2px 5px 0 rgba( 255, 255, 255, 0.1 );
    backdrop-filter: blur( 2px );
    -webkit-backdrop-filter: blur( 2px );
    border-top: 0px solid rgba($gn-light-color, 1);
    height:30px;
  }
  .Controller-Frame {
    position: relative;
    top:0px;
    padding : 0rem 1rem;
    flex-grow: 1;
    &.dark, &.tiny{
      padding : 0rem 0rem;
    }

    .Controller-Brand {
      padding: 0px;
      display: flex;
      flex-direction: row;
      text-decoration: none;
        &:hover {
          text-decoration: none;
        }
      cursor: pointer;
      .Controller-Logo {
        float:left;
        width:25px;
        height:25px;
        /*padding : 2px 0px;*/
        margin: auto 0.5rem;
        border: 0px solid rgba($gn-white-color, 1);
        color :rgba( $gn-frame-color , 1 );
        /*background-color: $gn-frame-color;
        color : $gn-white-color;
        border-radius: 7.5px;
        -moz-border-radius:7.5px;
        -webkit-border-radius:7.5px;*/
        //line-height: 35px;
        font-weight: 600;
        font-size: 30px;
        &.dark{
          margin: 5px 5px;
          width:20px;
          height:20px;
        }
      }
      .Controller-Title {
        //float:left;
        //height:50px;
        /*padding : 2px 0px;*/
        margin: auto 0.5rem;
        color: rgba($gn-frame-color, 1);
        //border-radius: 7.5px;
        //-moz-border-radius:7.5px;
        //-webkit-border-radius:7.5px;
        //line-height: 50px;
        font-weight: light;
        font-size: 14px;        
        &.dark, &.tiny{
          margin: 0px;
          color: rgba($gn-white-color, 1);
          line-height: 30px;
          height:30px;
          font-size: 12px;
        }
        &.manager {
          margin: 0px;
          //margin-right: 0.5rem;
          line-height: 30px;
          height:30px;
          font-size: 15px;
          .Name {
            margin: 0px;
            margin-right: 0.1rem;
          }
        }
        .beta {
          font-weight: lighter;
          line-height: 30px;
          font-size: 8px;
          color : rgba($gn-frame-color-2, 1);
        }
      }
    }

    .Controller-Frame-Nav {
      justify-content: space-between;
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      &.dark, &.tiny {
        margin-left: auto;
        justify-content: flex-end;
      }
    }

    .Controller-Wrapper {
      //float: left;
      margin: auto 1rem;
      color : rgba($gn-black-color, 1);
      position: relative;
      display: flex;
      flex-direction: row;
      transition: all 0.15s ease-in-out;
      padding:0px !important;

      height:50px;

      &:hover {
        .Controller-Icon, .Controller-Text {
          color : rgba($gn-frame-color, 1);
        }
      }
      .dropdown {
        padding:0px;
        .dropdown-toggle {
          padding: 0px;
          /* line-height: 50px; */
          color: #354149;
          text-align: left;
          position: relative;
          width: 100%;
          //height: 100%;
          display: flex;
          flex-direction: row;
          &:hover {
            color: rgba($gn-dark-color, 1);
          }
          &:after {
            margin : auto;
          }
        }
        .dropdown-menu {
          //padding-left : 1.5rem;
          //padding-right : 1.5rem;
          transition: visibility,opacity 0.25s ease-in-out;
          visibility :hidden;
          opacity: 0;
          &.show {
            visibility :visible;
            opacity: 1;
          }
          flex-direction: column;
          display: flex;
          width: max-content;
          &:hover {
            //color : rgba($gn-dark-color, 1) !important;
              .Controller-Icon, .Controller-Text {
                color : rgba($gn-dark-color, 1);
              }
          }
          .dropdown-item {
            color: rgba($gn-frame-color, 1);
            display: inline-block;
            padding: 0px 0rem;
            transition: all 0.25s ease-in-out;
            display: flex;
            flex-direction: row;
            border-bottom: 1px solid rgba( $gn-black-color, 0.05);
            font-weight: light !important;
            height : 30px;
            .Controller-Icon {
              transition: all 0.25s ease-in-out;
              padding-left: 1rem;
              //margin: auto;
            }
            .Controller-Text {
              transition: all 0.25s ease-in-out;
              padding-right: 1rem;
              margin: auto;
            }
            &:hover {
              background-color: rgba($gn-frame-color , .8);
              .Controller-Icon, .Controller-Text {
                color: rgba($gn-light-color, 1) !important;
              }
              //.Controller-Icon, .Controller-Text {
              //  background-color: rgba($gn-frame-color, 1);
              //  color: rgba($gn-light-color, 1);
              //}
            }
            &:last-child {
              border-bottom: 0px solid rgba( $gn-black-color, 0.05 );
            }
          }
        }
      }
      .Controller-Wrapper-Underline {
        position: absolute;
        bottom: 4px;
        left:-2.5px;
        right:-2.5px;
        height:2px;
        background-color : rgba($gn-frame-color, 1);
      }
      .Controller-Icon {
        //float:left;
        width : 12.5px;
        height:12.5px;
        //line-height: 50px;
        /*font-size: 15px;*/
        /*color : rgba($gn-frame-color-2, 1)*/
        padding: 0px 2.5px;
        //margin-top : 17.5px;
        margin: auto 0px;
        cursor: pointer;
        color : rgba($gn-dark-color, 1);
        path {
          -webkit-box-sizing: border-box !important;
          -moz-box-sizing: border-box !important;
          box-sizing: border-box !important;
        }
      }
      .Controller-Text {
        cursor: pointer;
        /*font-size: 14px;*/
        //font-weight: 500;
        //line-height: 50px;
        font-weight: lighter;
        //float: left;
        margin: auto 0px;
        margin-left: 0.5rem;
        color : rgba($gn-dark-color, 1);
        &.dark, &.tiny{
          line-height: 30px;
          color : rgba($gn-white-color, 1);
        }
        /*font-size: 18px;*/
      }
      .Controller-Icon:hover {
        /*margin-top : 10px;
        color : rgba($gn-white-color, 1);
        width : 15px;
        padding: 0px 2.5px;
        background-color: rgba($gn-frame-color-2, 1);
        border-radius: 25px;
        -moz-border-radius:25px;
        -webkit-border-radius:25px;*/
      }
    }
    .Controller-Wrapper:hover {
      color : rgba($gn-frame-color, 1);
    }

    .Controller-Panel {
      margin-left: auto;
      .Bell {
        //font-size: 15px;
        //width:20px;
        width: calc( 20px - 0.5rem );
        height: calc( 20px - 0.5rem );
        margin: auto;
        padding:0.25rem 0.25rem;
        margin-right: 1rem;
        color : rgba( $gn-frame-color , 1 );
        border: 1px solid rgba($gn-frame-color , 1);
        transition: all 0.25s ease-in-out;
        border-radius: 50%;
        
        &:hover {
          background-color: rgba($gn-frame-color , 1);
          color : rgba( $gn-white-color , 1 )
        }
        &.Shine {
          -webkit-animation: _flashing 1s ease-in-out infinite alternate;
          border-radius: 30px;
        }
      }

      .nav-link {
        padding:0px;
        display: flex;
        flex-direction: row;
        &.dark, &.tiny {
          padding:0px 0.5rem;
          height: 30px;
        }
        .Controller-Login-Panel {
          //float: right;
          //height : 50px;
          margin:auto 0px;
          margin-left: auto;
          margin-right: 1rem;
          display: flex;
          flex-direction: row;
          &.dark, &.tiny {
            height : 30px;
            font-size: 12px;
            margin-right: 0px;
          }
          .Controller-Login-Panel-Text {
            color : rgba($gn-black-color, 1);
            font-weight: 400;
            line-height: 50px;
            float : left;
            margin: 0px 5px;
            cursor: pointer;
            transition: color 0.15s ease-in-out;
            &.dark, &.tiny {
              height : 30px;
              font-size: 12px;
              line-height: 30px;
              color :rgba( $gn-white-color , 1 ) !important;
            }
            &:hover {
              color :rgba( $gn-frame-color , 1 ) !important;
            }
          }
          .Controller-Login-Panel-User-Frame {
            color : rgba($gn-black-color, 1);
            //line-height: 50px;
            float : left;
            margin: auto 0px;
            cursor: pointer;
            transition: color 0.15s ease-in-out;
            display: flex;
          flex-direction: row;
            .Controller-Login-Panel-User-Pic {
                //float:left;
                width:20px;
                height: 20px;
                margin: auto 0px;
                color : rgba($gn-white-color, 1);
                background-color: rgba($gn-frame-color, 1);
                padding:2.5px;
                margin-right: 1rem;
                border-radius: 50%;
                &.dark, &.tiny {
                  margin: 5px 0px; 
                  margin-right: 5px;
                }
            }
            .Controller-Login-Panel-User-Text {
              //line-height: 50px;
              //float : left;
              margin: auto 0px;
              &.dark, &.tiny {
                font-size: 12px;
                //line-height: 30px;
                color :rgba( $gn-white-color , 1 ) !important;
              }
            }
          }
          .Controller-Login-Panel-Text:hover, .Controller-Login-Panel-User-Text:hover {
            color : rgba($gn-frame-color, 1);
          }
        }
        .Controller-Option {
          /*float:right;
          margin-right: 10px;
          margin-top: 10px;*/
          height: calc(25px - 4px);
          width : 40px;
          font-size: 12px;
          border-width: 2px;
          margin: auto 0px;
          /*background-color: rgba($gn-frame-color, 1);
          color : rgba($gn-white-color, 1);*/
          background-color: rgba($gn-white-color, 1);
          border-radius: 0.25rem;
          &.dark, &.tiny {
            background-color: rgba( $gn-black-color, 0.5);
            color: rgba($gn-white-color, 1);
            border-style: none;
            height: 23px!important;
            border-radius: 0rem;
            margin: auto 0px;
          }
          option {
            min-height: auto;
          }
        }
      }

    }
  }
}

@media screen and (max-width: 992px)  {
  .Controller-Holder {
      //height : 50px !important;
      &.dark, &.tiny{
        //height : 30px !important;
      }
    .Controller-Frame {
      top : 0px !important;
      &.navbar-expand-lg {
        padding: 0px 0rem;
        background-color: rgba( $gn-white-color, 0.9 );
        backdrop-filter: blur(5px); 
      }
      &.dark {
        background-color: rgba( $gn-black-color, 0.5 );
      }
      
      .Controller-Wrapper-Underline {
        display: none;
      }
      .Controller-Logo {
        /*margin-left: calc( 50% - 7.5px );*/
      }
      .Controller-Title {
        /*display: none;*/
      }

      .Controller-Login-Panel {
       /*display: none;*/
       .Controller-Login-Panel-Text, .Controller-Login-Panel-User-Text {
          color : rgba($gn-white-color, 1) !important;
          line-height: initial !important;
          &.dark, &.tiny {
            color :rgba( $gn-white-color , 1 ) !important;
          }
          &:hover {
            color : rgba($gn-badge-color, 1);
          }
        }
      }
      .Controller-Frame-Nav {
        flex-direction: column;
        margin-top: 5px;
        transition: all 0.25s ease-in-out;
        &.collapse {
          height: 0px;
          overflow: hidden;
          &.show{
            background-color: rgba( $gn-white-color, 0.95 );
            backdrop-filter: blur(5px);
            height: fit-content;
            overflow: hidden;
          }
        }
        .navbar-nav {
          width: 100%;
          .Controller-Wrapper {
            //flex-grow: 1;
            width: calc( 100vw - 1rem);
            height: auto;
            min-height: 50px;
            margin: 0px 0.5rem;
            .dropdown-toggle {
              .Controller-Wrapper {
                margin: 0px 0rem;
              }
            }
           }
          .dropdown {
            .dropdown-menu {
              visibility: visible !important;
              opacity: 1 !important;
              border-width: 0px;
              width:100%;
              .dropdown-item {
                
                .Controller-Icon {
                  margin : auto 0rem;
                  margin-left: 2rem;
                }
                .Controller-Text {
                  //text-align: left;
                  margin : auto 0rem;
                  margin-left: 2rem;
                }
              }
            }
          }
        }
        &.dark {
          margin-top: 0px;
        }
        
      }
      .Controller-Panel {
        height:30px;
        border-bottom: 2px solid rgba($gn-frame-color, 1);
        background-color: rgba($gn-frame-color, 1);
        color : rgba($gn-white-color, 1);
        flex-direction: row;
        //margin-left: -1rem;
        //width: calc(100% + 2rem);
        display: flex;
        flex-direction: row;
        &.dark, &.tiny {
          background-color : rgba($gn-black-color, 0.8);
          box-shadow: 0 2px 5px 0 rgba( 255, 255, 255, 0.1 );
          backdrop-filter: blur( 2px );
          -webkit-backdrop-filter: blur( 2px );
          height: 30px;
        }
        .nav-link {
          display: flex;
          flex-direction: row;
          .Controller-Option {
            //line-height: 50px;
            //height:50px;
            margin: auto 0.625rem;
            background-color: rgba($gn-frame-color , .8) !important;
            color: rgba($gn-white-color, 1);
            border-color: rgba($gn-white-color, 1);
            border-radius: 0.25rem;
            border-width: 1px;
            height:calc( 20px - 2px );
            //margin: auto 0.5rem;
            option {
              //background-color: rgba($gn-frame-color , .8);
            }
            &.dark, &.tiny {
              background-color: transparent;
              color: rgba($gn-white-color, 1);
              border-color: rgba($gn-white-color, 1);
            }
          }
        }
      }

      .navbar-toggler {
        border-width: 0px;
      }

      &.dark, &.tiny {
        .navbar-toggler {
          //display: none;
          background-color: rgba( $gn-white-color , 0.25 );
          padding:0px 0.5rem;
          border-radius: 0px;
          .navbar-toggler-icon {
            //color : rgba($gn-white-color, 1) !important;
          }
        }    
      }

    }

    
  } 
}

//@-webkit-keyframes glow {
