@import "../../public/style.scss";

.Loading{
    top:0px;
    left:0px;
    right:0px;
    bottom: 0px;
    z-index: 2000 !important;
    background-color: rgba( $gn-white-color , 0.9);
    position: fixed;
    backdrop-filter: blur( 5px );
    -webkit-backdrop-filter: blur( 5px );
    backdrop-filter: blur(2px);
    display: flex;
    flex-direction: row;
    
    .Column {
      flex-basis: 25%;
      display: flex;
      flex-direction: column;
      height: auto;
      .Holder {
        display: flex;
        flex-direction: column;
        margin: auto;
        height: fit-content;
        transition: all 0.25s ease-in-out;
        overflow: hidden;
        .Image {
          width : 60%;
          margin : 2rem auto;
          //margin-bottom: 2rem;
        }
        .Title {
          width : 80%;
          max-width: 400px;
          margin: 0rem auto;
          margin-bottom: 2rem;
          font-size: 150%;
          font-weight: bolder;
          color : rgba( $gn-frame-color, 1);
          min-height: 50px;
        }
        .Message {
          width : 70%;
          margin : 0rem auto;
          font-size: 100%;
          font-weight: bolder;
          color : rgba( $gn-black-color, 0.8);
          min-height: 200px;
        }
        &.selected {
          transform: scale(1.2);
        }
      }
      &.Last {
        background-color: rgba( $gn-frame-color, 1);
        border-left: 1px solid rgba( $gn-black-color, 0.1);
        .Holder {
          .Title {
            color: rgba($gn-white-color, 1);
          }
          .Message {
            color: rgba($gn-white-color, 0.75);
          }
        }
      }
      
    }

    .Loading-Message {
      position: absolute;
      bottom:0px;
      left:0px;
      right:0px;
      display: flex;
      flex-direction: row;
      .L-Holder {
        display: flex;
        flex-direction: row;
        margin: auto;
        width: fit-content;
        margin-bottom: 2rem;
        .Logo {
          width :40px;
          margin: auto 0rem;
          margin-right: 0.5rem;
        }
        .Text {
          color : rgba( $gn-frame-color, 1);
          font-size: 150%;
          margin: auto 0rem;
          font-weight: bolder;
        }
      }
    }
    
}


@media screen and (max-width: 960px)  {
  .Loading{
    //bottom: auto;
    //height: fit-content;
    overflow: auto;
    flex-direction: column;
    overflow-x: hidden;
    .Column {
      .Holder {
        
        .Column {
          flex-basis: 100%;
          border-left: 0px solid rgba( $gn-black-color, 0.1) !important;
        }
        
      }
    }
    .Loading-Message {
      position: sticky;
      bottom: 0px;
      padding-top: 2rem;
      background-color: rgba( $gn-white-color, 1);
    }
  }
}



.app-Loading {
    z-index: 2000 !important;
    background-color: rgba( $gn-dark-color , 0.9);
    position: absolute;
    backdrop-filter: blur( 5px );
    -webkit-backdrop-filter: blur( 5px );
    .modal-content {
      background-color: transparent !important;
      border-color : transparent !important;
      top: calc( 50vh - 150px );
    }
    .app-Loading-Header {
      border-width : 0px;
      justify-content: center;
      padding-bottom: 0px;
      .app-Loading-Header-Logo {
            width:50px;
            height:50px;
            /*padding : 2px 0px;*/
            margin: 7.5px 5px;
            color : rgba( $gn-frame-color, 1);
            line-height: 60px;
            font-weight: 600;
            font-size: 30px;
      }
      .app-Loading-Header-Title {
            height:65px;
            /*padding : 2px 0px;*/
            margin: 0px 2.5px;
            color: rgba( $gn-white-color, 1) !important;
            border-radius: 7.5px;
            -moz-border-radius:7.5px;
            -webkit-border-radius:7.5px;
            line-height: 65px;
            font-weight: 600;
            font-size: 1.5rem;
      }
    }
    .app-Loading-Frame {
      /*top: calc( 50vh - 50px );*/
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
    .app-Loading-Spinner {
      background-color: rgba($gn-frame-color, 1);
      margin:0px 2.5px;
    }
    .app-Loading-Word {
      font-weight: bold;
      color : rgba($gn-white-color, 1);
      /*top: calc( 50vh - 0px );
      position: absolute;*/
      justify-content: center;
      border-top: 0px none transparent;
      width: calc(100% - 2rem);;
      padding: 1rem;
      padding-top: 0px;
    }
}
  