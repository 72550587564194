@import "../../public/style.scss";

.Botfolio {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: rgba( $gn-frame-color, 0.0);
  //backdrop-filter:brightness( 0.5 );
  .panel {
    height: 100px;
    flex-shrink: 0;
    flex-direction: column;
    display: flex;
    .btn-group {
      margin: 0.5rem 0.5rem;
      //margin-top : 15px;
      //margin-bottom: 0.5rem;
      .btn {
        font-size: 90%;
        padding:0.25rem 0.5rem;
        background-color: transparent !important;
        border-color:transparent !important;
        //background-color: rgba( $gn-badge-color, 1  ) !important;
        filter: brightness(0.75);
        color : rgba($gn-black-color, 1);
        
        margin:0px 1px;
        opacity: 0.5;
        transition: all 0.25s ease-in-out;
        position: relative;
        z-index : 1;
        width: fit-content;
        height: auto;
        &:focus {
          border-width: 0px !important;
          border-color:transparent !important;
        }
        &:hover {
          //background-color: rgba( $gn-frame-color, 0.75  ) !important;;
          opacity: 0.75;
          filter: brightness(0.8);
        }
        &.active {
          opacity : 1;
          //background-color: rgba( $gn-frame-color, 1  ) !important;
          border-bottom: 2px solid rgba( $gn-frame-color, 1  ) !important;
          transform: scale3d(1.2,1.2,1.2);
          z-index : 5;
          filter: brightness(1);
        }
      }
    }
    .Lower {
      flex-direction: row;
      display: flex;
      .Regions {
        position: relative;
        //margin-right:0px !important;
        border-radius: 0.5rem !important;
        margin: 0rem 0px;
        margin-left : 0.5rem;
        margin-right : auto;
        margin-top: 1.2rem;;
        background-color : transparent !important;
        //background-color: rgba($gn-badge-color, 0.5) !important;
        color : rgba($gn-white-color, 1);
        border: 0px solid rgba($gn-badge-color, 0.5) !important;
        font-weight: bolder;
        .btn {
          border-radius: 0.5rem !important;
          padding: 0.25rem 1em;
          font-weight: bolder;
          border-width: 0px;       
          display: flex;
          flex-direction: row;           
          .Scope {
            display: flex;
            flex-direction: row;
            font-size: 100%;
            font-weight: bolder;
            .Region {
              margin:auto 0px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              background-color: rgba( $gn-frame-color , 0);
              height : fit-content;
              .react-emojis {
                font-size: 20px;
                font-weight: 400;
                margin: auto 0.5rem;
                //margin-top: 0.5rem;
              }
            }
          }
          &::after {
            margin: auto;
            margin-left: 0.5rem;
          }
          //display: flex;
          //flex-direction: row;
          //justify-content: center;
        }
        .dropdown-menu {
          .Line {
            display: flex;
            flex-direction: row;
            font-size: 100%;
            font-weight: bolder;
            padding : 1rem 0.5rem;
            width : calc( 100% - 1rem);
            border-bottom: 1px solid rgba( $gn-black-color, 0.1 );
            .Region {
              margin:auto 0px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              background-color: rgba( $gn-frame-color , 0);
              height : fit-content;
              .react-emojis {
                font-size: 20px;
                font-weight: 400;
                margin: auto 0.5rem;
                //margin-top: 0.5rem;
              }
            }
          }
        }

      }
      .Insert {
        font-size: 110%;
        background-color: rgba( $gn-frame-color, 1  ) !important;
        border: 0px solid rgba( $gn-frame-color, 0.5  ) !important;
        color : rgba( $gn-white-color, 1  );
        border-radius: 0.5rem;
        padding:0.25rem 1em;
        //border-radius: 0px;
        min-height:25px;
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-weight: bolder;
        margin-left: auto;
        margin-right: 0.5rem;
        display: flex;
        flex-direction: row;
        .Fa{
          font-size: 20px;
          width: 0.8rem;
          margin: auto;
          margin-right: 0.5rem;
          font-weight: bolder;
        }
        div {
          margin: auto;
        }
        &:hover {
          color : rgba($gn-white-color, 1) !important;
          background-color: rgba( $gn-badge-color, 0.8  ) !important;
        }
      }
    }
  }
  .holder {
    border-top : 1px solid rgba( $gn-black-color, 0.1 );
    //height:100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex-wrap: nowrap;
    flex-grow: 1;
    padding-top: 10px;
    padding-bottom:100px;
    .list {
      position: relative;
      width: calc( 100% - 3rem);
      margin: 2.5px 1rem;
      background-color: rgba( $gn-white-color, 0.85 );
      color : rgba($gn-white-color, 1);
      flex-grow: 0;
      flex-shrink: 0;
      //border: 1px solid rgba( $gn-white-color, 0.05 );
      border-bottom: 1px solid rgba( $gn-black-color, 0.1 );
      border-radius: 0rem;
      cursor: pointer;
      padding:5px 0.5rem;
      transition: all 0.25s ease-in-out;
      backdrop-filter: blur(5px);
      &.selected {
        z-index: 10;
        transform: scale3d(1.1, 1.1, 1.1) !important;
        border-bottom: 2px solid rgba( $gn-frame-color, 1 );
        border-top: 2px solid rgba( $gn-frame-color, 1 );
        opacity:1 !important;
        position: sticky;
        top: 0.5rem;
        bottom: 0.5rem;
      }
      &.noselect {
        opacity:0.25;
        margin-top: -0px;
        z-index: 5;
        background-color: rgba( $gn-black-color, 0.05 );
        //filter : blur(2px);
        &:hover {
          opacity:0.75;
          filter : blur(0px);
        }
      }
      &:hover {
        opacity:1;
        //background-color: rgba( $gn-fraame-color, 0.1 );
        transform: scale3d(1.05, 1.05, 1.05);
      }
      .panel {
        display: flex;
        flex-direction: row;
        height: 20px;
        margin-bottom: 5px;
        .Region {
          margin:auto 0px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          background-color: rgba( $gn-frame-color , 0);
          height : fit-content;
          .react-emojis {
            font-size: 20px;
            font-weight: 400;
            margin: auto 0.5rem;
            //margin-top: 0.5rem;
          }
        }
        .badge {
          display: flex;
          flex-direction: column;
          justify-content: center;
          border-radius: 0.25rem;
          font-size: 100%;
          cursor: default;
          &.private {
            border: 2px solid rgba( $gn-black-color, 0.5 );
            color : rgba( $gn-black-color, 0.5 );
          }
          &.public {
            border: 2px solid rgba( $gn-green-color, 0.9 );
            color : rgba( $gn-green-color, 0.5 );
          }
          &.purchased {
            border: 2px solid rgba( $gn-frame-color-3, 1 );
            color : rgba( $gn-frame-color-3, 1 );
          }
        }
        .btn {
          background-color: rgba( $gn-red-color, 1 ) !important;
          border-style: none;
          flex-direction: column;
          display: flex;
          justify-content: center;
          border-radius: 0px;
          margin-left: auto;
          opacity: 0.5;
          transition: opacity 0.25s ease-in-out;
          .fa {
            color : rgba($gn-white-color, 1);
          }
          &:hover {
            background-color: rgba( $gn-red-color, 1 ) !important;
            opacity: 1;
          }
        }
      }
      .upper {
        color : rgba($gn-black-color, 0.8);
        font-size:90%;
        font-weight: bolder;
        text-align: left;
        //border-bottom: 1px solid rgba( $gn-badge-color, 0.25 );
      }
      .middle {
        color : rgba($gn-light-color, 1);
        font-size:75%;
        text-align: left;
        display: flex;
        flex-direction: row;
        height: auto;
        .holder {
          border-top : 0px solid rgba( $gn-black-color, 0.1 );
          display: flex;
          flex-direction: column;
          margin:0px 0.25rem;
          padding: 0.5rem 0px!important;
          cursor: default;
          .name {
            padding : 0rem 0rem;
            //background-color: rgba( $gn-black-color, 0.5 );
            color : rgba( $gn-black-color, 0.5 );
            font-size: 80%;
            text-align: right;
          }
            .value {
              flex-grow :1;
              height:25px;
              margin-top: 1px;
              display: flex;
              flex-direction: row;
              //justify-content: center;
              text-align: right;
              color: rgba($gn-black-color, 0.5);
              font-size: 110%;
              line-height: 25px;
              justify-content: flex-end;
              &.up {
                background-color: transparent !important;
                color: rgba($gn-green-color, 0.9) !important; 
              }
              &.down {
                background-color: transparent !important;
                color: rgba($gn-red-color, 0.9) !important;
              }
              .row {
                display: flex;
                flex-direction: row;
                justify-content: center;
                margin:0px;
                span{
                  &.Current {
                    font-size: 200%;
                    margin-right: 0.5rem;
                  }
                  text-align: center;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  //color : rgba( $gn-white-color, 1);
                  margin:auto;
                  //font-size: 110%;
                  .fa {
                    margin:auto 0.25rem;
                    font-size: 75%;
                  }
                }

              }
          }
        }
      }
      .lower {
        display: flex;
        flex-direction: row;
        height: auto;
        //border-bottom: 1px solid rgba( $gn-badge-color, 0.25 );
        .line{
          display: flex;
          flex-direction: row;
          justify-content: stretch;
          .box2 {

          }
          .box4 {

          }
        }
        .holder {
          display: flex;
          flex-direction: column;
          margin:0px 0.25rem;
          padding: 0.5rem 0px!important;
          cursor: default;
          .name {
            padding : 0rem 0.25rem;
            background-color: rgba( $gn-white-color, 0.05 );
            color : rgba( $gn-white-color, 0.7 );
            font-size: 80%;
            text-align: center;
            border-top-left-radius: 0.25rem;
            border-top-right-radius: 0.25rem;
          }
          .score {
            flex-grow :1;
            height:25px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            border-bottom-left-radius: 0.25rem;
            border-bottom-right-radius: 0.25rem;
          }
        }
      }
      .lower {
        color : rgba($gn-light-color, 1);
        font-size:75%;
        text-align: left;
      }
    }
  } 
}

@media screen and (max-width: 750px)  {
  .Botfolio{

  }
}