@import "../public/style.scss";

.table.table-dark {
    color : rgba($gn-white-color, 1) !important;
    td {
        padding:0px !important;
    }
}
.Over-Table {
    width : 270px; 
    .g-table {
        margin: auto;
    }
}

.Overview-Holder {
    position: relative;
    width:100%;
    height:100%;
    overflow: hidden;
    display: flex;
    flex-direction: row-reverse;
    transition: all 0.25s ease-in-out;
    //left:0px;
    &.Slide-Left {
        //left:320px;
        transform: translateX(320px);
    }

    .Overview-Left {
        flex-grow : 1;
        //width : calc( 70% - 10px );
        margin : 5px;
        height: auto;
        background-color: rgba($gn-white-color, 1);
        overflow: auto;
        position: relative;
        z-index : 10;
        display: flex;
        flex-direction: column;

        .Overview-Left-Top-Panel {
            width : calc( 100% - 0rem );
            padding : 1.5rem 0rem;
            float: left;
            height:60px;
            position: sticky;
            top: 0px;
            z-index: 99;
            background-color: rgba( $gn-white-color, 0.9 );
            backdrop-filter: blur(4px);
            display: flex;
            flex-direction: row;
            flex-shrink: 0;
            .Overview-Left-Top-Title {
                color : rgba($gn-black-color, 1);   
                margin: auto;
                margin-left: 0px;
                //margin-right: auto;
                display: flex;
                flex-direction: row;
                height:60px;
                .Pagename {
                    margin: auto;
                    margin-right: 1rem;
                    font-size: 120%;
                    font-weight: bolder;
                    text-transform: uppercase;
                    color : rgba( $gn-frame-color, 1 ) ;
                    //margin-left: 1rem;
                    .Overview-Left-Top-Fa {
                        margin-right: 0rem;
                    }
                    cursor: default;
                    //transition : color 0.25s ease-in;
                    //color : rgba($gn-dark-color, 1);
                    span {
                        //margin-left: 5px;     
                    }
                    //&:hover {
                    //    color : rgba($gn-green-color, 1) !important;
                    //}   
                }
                .Overview-Left-Top-Fa {
                    cursor: pointer;
                    //margin-left: 5px;
                    width:40px;
                    height: 20px;
                    color : rgba($gn-frame-color, 1) !important;
                    &:hover {
                        color : rgba($gn-green-color, 1) !important;
                    }
                    margin: auto 0px;
                    margin-right: 1rem;
                    &:last-child {
                        margin-left: 0rem;
                    }
                    
                }
                .FHolder {
                    cursor: pointer;
                    margin: 0rem 0rem;
                    margin-right: 1rem;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    &:hover {
                        .Overview-Left-Top-Fa {
                            color : rgba($gn-green-color, 1) !important;
                        }
                        .SFa, .Text {
                            color : rgba($gn-green-color, 1) !important;
                        }
                    }
                    &.selected {
                        background-color: rgba( $gn-frame-color, 1 );
                        transform: scale3d(1.25, 1.25, 1.25) translateX(4px);
                        box-shadow: 0px 0px 5px rgba($gn-black-color, 0.5);
                        z-index: 10;
                        .Overview-Left-Top-Fa {
                            //background-color: rgba($gn-black-color, 0.8);
                        }
                        .Overview-Left-Top-Fa, .SFa, .Text { 
                            color : rgba( $gn-white-color, 1 ) !important; 
                            
                        }
                    }
                    .Overview-Left-Top-Fa {
                        transition: all 0.25s ease-in-out;
                        margin:0rem;
                        margin-right: 0px;
                        position: relative;
                        padding: 20px 5px;
                    }
                    .SFa, .Text {
                        transition: all 0.25s ease-in-out;
                        position: absolute;
                        color : rgba($gn-frame-color, 1);
                        width: 10px;
                        height : 10px;
                        text-align: center;
                        margin-bottom: -10px;
                        left: calc( 50% - 5px );
                        bottom: 10px;
                        //margin: 0rem auto;
                    }
                    .Text {
                        width: 100%;
                        left: 0px;
                        right:0px;
                        text-align: center;
                        font-size: 10px;
                    }
                }

            }
            .Overview-Left-Top-Button-Gp {
                color : rgba($gn-black-color, 1);   
                //margin-top: 20px;
                margin: auto 0px;
                //height:40px;
                //line-height: 40px;
                height:100%;
                //margin-right: auto;
                .noselect {
                    opacity: 0.25;
                }
                .btn {
                    height:100%;
                    padding: 0px 1.0rem;
                }
            }
            .Overview-Left-Top-Filter {
                float: right;
                color : rgba($gn-black-color, 1);   
                height:80px;
                .Overview-Left-Top-Filter-Filter {
                    height:30px;
                    line-height: 30px;
                    position: relative;
                    top:25px;
                    .Overview-Left-Top-Filter-Dropdown {
                        background-color: transparent !important;
                        border : 0px;
                        color : rgba($gn-black-color,1 );   
                        &:hover {
                            background-color: rgba($gn-green-color, 1) !important;
                            color : rgba($gn-white-color, 1);
                        }
                    }
                    .Overview-Left-Top-Filter-Dropdown-Item {
                        width : calc( 100% - 3.0rem );
                    }
                }
            }
            .Overview-Left-Top-Create {
                float:right;
                margin-top: 20px;
                height: 40px;
                line-height: 40px;
                padding: 0px 1rem;
            }
            .Cart {
                //float:right;
                //margin-top: 20px;
                margin:auto;
                margin-right: 0.5rem;
                //height: 40px;
                //line-height: 40px;
                padding: 0.25rem 1rem;
                //color: rgba($gn-frame-color, 0.8 );
                color : rgba($gn-white-color, 1 )!important;
                background-color: rgba($gn-frame-color, 1 )!important;
                border: 2px solid rgba($gn-frame-color, 0.8 ) !important;
                border-radius: 1rem;
                //height:40px;
                font-weight: bolder;
                .Fa { 
                    margin-right: 0.5rem;
                }
                &:hover {
                    color : rgba($gn-white-color, 1 ) !important;;
                    background-color: rgba($gn-badge-color, 1 )!important;
                    border: 2px solid rgba($gn-badge-color, 1 )!important;;
                }
            }
            .Compare-Wrapper {
                position: absolute;
                bottom : -10px;
                left:0px;
                background-color: rgba( $gn-frame-color, 0.75 );
                backdrop-filter: blur(10px);
                color : rgba($gn-white-color, 1);
                font-size: 80%;
                padding: 0.25rem 1rem;
                width : calc( 100% - 2rem);
                cursor: pointer;
                &:hover {
                    background-color: rgba( $gn-badge-color, 0.75 );
                }
            }
        }
        .Overview-Left-Content {
            float:left;
            width : calc( 100% - 0px );
            padding : 0px 0px; 
            margin-top: 10px; 
            padding-bottom: 80px;
            display: flex;
            flex-direction: column;
            .lazyload-wrapper {
                width : calc( 100% - 12px );
                margin : 0px 6px;
                height : auto;
                //position: relative;
                //z-index : 10;
                .lazyload-placeholder {
                    background-color: rgba($gn-white-color, 1);
                }
                
            }

            
        }
        .Overview-Left-Content-1 {
            float:left;
            width : calc( 100% - 0px );
            padding : 0px 0px; 
            display: flex;
            flex-direction: row;
            flex-wrap:wrap;
            justify-content: center;
            max-width: calc( 1200px + 2rem );
            margin: auto;
            margin-top: 2rem; 
            padding-bottom:4rem;
            .lazyload-wrapper {
                width : auto;
                margin : 0.25rem 0rem;
                height: auto;
                .lazyload-placeholder {
                    background-color: rgba($gn-white-color, 1);
                }
                &.selected {
                    position: sticky;
                    top: 95px;
                    z-index:999;
                    //bottom:-65px;
                    bottom:1rem;
                }
            }

            .Overview-Left-More {
                float: left;
                margin-top: 1.5rem;
                //margin-right: 5px;
                display: flex;
                width : 100px;
                margin-left: auto;
                margin-right: auto;
                //width: calc( 100% - 35px);
                justify-content: center;
                //background-color: transparent !important;
                //backdrop-filter: blur( 2px );
                color : rgba($gn-frame-color, 0.8);
                background-color: transparent !important;
                border: 2px solid rgba($gn-frame-color, 0.8) !important;
                font-weight: bolder;
                &:hover {
                    //box-shadow: 0px 0px 10px rgba($gn-black-color, 0.25);
                    //border-color: $gn-frame-color !important;
                    backdrop-filter: blur( 2px );
                    -webkit-backdrop-filter: blur( 2px );
                    background-color: rgba($gn-frame-color, 0.8) !important;
                    border: 2px solid rgba($gn-frame-color, 0.8) !important;
                    color : rgba($gn-white-color, 1);
                }
            }
        }
        .Diagram {
            flex-grow: 1;
            background-color: transparent;
            //padding:1rem;
            position: relative;
            
            .Wrapper {
                position: absolute;
                top: calc( 1.5rem + 2px );
                left: calc( 0.5rem + 20px ) ;
                right: 1.5rem;
                bottom: calc( 1rem - 2px + 15px);
                border-top-right-radius: 1rem;
                border: 2px solid rgba( $gn-frame-color, 0.1 );
                border-left-width: 0px;
                border-bottom-width: 0px;
                //background: rgba($gn-white-color, 0.25);
                //background: -moz-linear-gradient(45deg, rgba( $gn-white-color,0.55) 0%, rgba($gn-frame-color,0.5) 100%);
                //background: -webkit-linear-gradient(45deg, rgba($gn-white-color,0.5) 0%, rgba($gn-frame-color,0.5) 100%);
                //background: linear-gradient(45deg, rgba($gn-white-color,0.5) 0%, rgba($gn-frame-color,0.5) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#ff0000",GradientType=1);
                .Risk {
                    width:20px;
                    position: absolute;
                    top: calc( 0.0rem - 2px);
                    bottom:calc(1rem - 35px - 0rem ) ;
                    left:calc(0rem - 21px) ;
                    border-bottom-left-radius: 1rem;
                    border-top-left-radius: 1rem;
                    text-orientation: mixed;
                    writing-mode: vertical-rl;
                    text-align: left;
                    padding-top: 1rem;
                }
                .Profit {
                    height:20px;
                    position: absolute;
                    bottom:calc(0rem - 21px) ;
                    left: calc(0rem + 1px + 0rem) ;
                    right: calc( 0rem - 2px);
                    border-bottom-right-radius: 1rem;
                    text-align: right;
                    padding-right: 1rem;
                }
                .Risk, .Profit { 
                    background-color: rgba($gn-frame-color, 1 ) ;
                    color : rgba($gn-white-color, 1 ) ;
                    font-weight: bolder;
                    z-index : 2;cursor: default;
                }
                .Vertical {
                    top: 0rem;
                    left: calc( 50% - 1px );
                    bottom:0px;
                    width: 2px;
                }
                .Horizontal {
                    top: calc( 50% - 1px );
                    left:1rem;
                    right:0rem;
                    height: 2px;
                }
                .Vertical, .Horizontal {
                    z-index : 1;
                    position :absolute;
                    background-color: rgba($gn-frame-color, 0.1 );
                }
                .High {
                    top:1rem;
                    //bottom: calc( % + 1px );
                    //left: calc( 50% - 1px );
                    right:1rem;
                    //right: 0px;
                    border-top-right-radius: 1rem;
                    color : rgba( $gn-frame-color, 0.1 );
                    
                }
                .Low {
                    bottom:1rem;
                    left: 1.5rem;
                    color : rgba( $gn-frame-color, 0.1 );
                }
                .High, .Low {
                    position: absolute;
                    z-index: 2;
                    //background-color: rgba($gn-frame-color, 0.1 ) ;
                    flex-direction: column;
                    display: flex;
                    justify-content: center;
                    font-size: x-large;
                    font-size: 10vw;
                    z-index : 2;cursor: default;
                }
            }

        }
        .Legend {
            display: flex;
            flex-direction: row;
            height : 45px;
            flex-shrink: 0;
            .Holder {
                height: calc( 100% - 7px);
                padding:0px 0rem;
                margin: auto 0rem;
                margin-right: 0.5rem;
                display: flex;
                flex-direction: row;
                position: relative;
                border: 2px solid rgba( $gn-frame-color,1 );
                border-radius: 0.25rem;
                &:last-child {
                    margin-right: 0px;
                }
                .Title {
                    background-color: rgba( $gn-frame-color, 1 );
                    color : rgba( $gn-white-color, 1 );
                    height: fit-content;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding-left: 0.5rem;
                    padding-right: 0.5rem;
                    height:auto;
                    width:50px;
                    line-height: normal;
                    font-size: 80%;
                    font-weight: bolder;
                    cursor: default;
                }
                    .Low {
                        font-size: 60% ;
                    }
                    .High {
                        font-size: 120% ;
                    }
                    .Low, .High {
                        margin: auto 0.5rem;
                        //background-color: rgba( $gn-white-color,1 );
                        color : rgba( $gn-frame-color,1 );
                        font-weight: bolder;
                        cursor: default;
                    }
                .Win {
                  margin: auto 0.25rem;
                  border-radius: 50%;
                  border: 2px solid rgba( $gn-frame-color, 1 );  
                }
                .Turnover {
                    width:100px;
                    background: rgba($gn-white-color, 0.25);
                    background: -moz-linear-gradient(90deg, rgba( $gn-white-color,0.5) 0%, rgba($gn-frame-color,1) 100%);
                    background: -webkit-linear-gradient(90deg, rgba($gn-white-color,0.5) 0%, rgba($gn-frame-color,1) 100%);
                    background: linear-gradient(90deg, rgba($gn-white-color,0.5) 0%, rgba($gn-frame-color,1) 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#ff0000",GradientType=1);
                }
            }
        }
        .Statement {
            color : rgba($gn-white-color, 1);
            background-color : rgba($gn-frame-color, 1);
            margin: 0.5rem auto;
            font-weight: bolder;
            font-size: 120%;
            width:100%;
            padding:0.5rem 0rem;
            cursor: default;
        }
        .Overview-Left-More {
            //float: left;
            margin-top: 1.5rem;
            //margin-right: 5px;
            display: flex;
            width : 100px;
            margin-left: auto;
            margin-right: auto;
            //width: calc( 100% - 35px);
            justify-content: center;
            //background-color: transparent !important;
            //backdrop-filter: blur( 2px );
            color : rgba($gn-frame-color, 0.8);
            background-color: transparent !important;
            border: 2px solid rgba($gn-frame-color, 0.8) !important;
            font-weight: bolder;
            &:hover {
                //box-shadow: 0px 0px 10px rgba($gn-black-color, 0.25);
                //border-color: $gn-frame-color !important;
                backdrop-filter: blur( 2px );
                -webkit-backdrop-filter: blur( 2px );
                background-color: rgba($gn-frame-color, 0.8) !important;
                border: 2px solid rgba($gn-frame-color, 0.8) !important;
                color : rgba($gn-white-color, 1);
            }
        }
        .Picker {
            display: flex;
            flex-direction: row;
            height:0px;
            overflow: hidden;
            opacity: 0;
            transition: all 0.25s ease-in-out;
            margin-bottom: 0rem;
            flex-shrink: 0;
            position: sticky;
            top:92px;
            background-color: rgba($gn-light-color, 0.9);
            backdrop-filter: blur(5px);
            z-index : 95;
            &.Appear {
                height:80px;
                overflow: initial;
                opacity: 1;
                margin-bottom: 0.5rem;
            }
            .Holder {
                width:80px;
                background-color: rgba($gn-frame-color, 1 );
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                margin-right: 0.5rem;
                position: relative;
                z-index : 1;
                transition: all 0.25s ease-in-out;
                cursor: pointer;
                .Fa {
                    color : rgba($gn-white-color, 1 );
                    width : 40px;
                    height: 40px;
                    margin: auto;
                }
                .Name {
                    font-size: 70%;
                    color : rgba($gn-white-color, 1 );
                }
                &:hover {
                    background-color: rgba($gn-badge-color, 1 );
                    transform: scale3d(1.1, 1.1, 1.1);
                    z-index : 2;
                }
            }
        }
    }

    .Overview-Right {
        max-width:400px;
        min-width:320px;
        flex-shrink: 0;
        flex-basis: 30%;
        //width : calc( 30% - 0rem );
        display: flex;
        flex-direction: column;
        height:100%;;
        position: relative;
        z-index : 5;
        background-color: rgba( $gn-frame-color, 1 );
        .Show-Btn {
            display: none;
        }
        .Show-Content {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            overflow: hidden;
        }
    }

    .Overview-Drawer {
        width : 0px;
        //margin : 5px;
        height: 0px;
        //background-color: rgba($gn-black-color, 0.75);
        overflow: hidden;
        //padding-bottom: 80px;
        position: absolute;
        z-index: 99999;
        top:80px;
        left:0px;
        bottom:0px;
        //backdrop-filter: blur( 10px );
        display: flex;
        flex-direction: column;
        //box-shadow: 0px 0px 5px rgba($gn-black-color, 0.25);
        border-right: 1px solid rgba($gn-black-color, 0.05);
        border-top: 1px solid rgba($gn-black-color, 0.05);
        opacity: 0;
        transition: all 0.25s ease-in-out;
        flex-grow: 0;
        max-width: 800px;
        //background-color: red;
        &.Appear {
            flex-grow: 1;
            width:auto;
            //width : calc( 70% - 0px );
            //width : 500px;
            height: auto;
            opacity: 1;
        }
    }

    .Card-Placeholder {
        float:left;
        width : calc( 50% - 12px );
        margin : 5px 5px;
        height : 400px;
        position: relative;
        z-index : 10;
        background-color: rgba($gn-light-color, 1);
    }
}



@media screen and (max-width: 1601px)  {
    .Overview-Holder {
        .Overview-Left {
            .Overview-Left-Content-1 {

                .lazyload-wrapper {
                    margin : 0.25rem 0rem;
                    width : auto;
                    //flex-basis: calc(33% - 0rem);
                    //width: calc( 33% - 0rem);                    
                }
            }
        }
    }
}


@media screen and (max-width: 960px)  {
    .Overview-Holder {
        .Overview-Left {
            .Overview-Left-Content-1 {
                max-width: calc( 640px + 0rem );
                .lazyload-wrapper {
                    margin : 0.25rem 0rem;
                    flex-basis: calc(50% - 0rem);
                    width: calc( 50% - 0rem);    
                    //width : auto;                
                }
            }
        }
    }
}


@media screen and (max-width: 950px)  {
    .Overview-Holder {
        .Overview-Left {
            width : calc(100% - 10px);
            .Overview-Left-Top-Panel {
                .Overview-Left-Top-Title {
                }
                .Overview-Left-Top-Filter {
                    .Overview-Left-Top-Filter-Filter {
                        .Overview-Left-Top-Filter-Dropdown { 
                        }
                        .Overview-Left-Top-Filter-Dropdown-Item {
                        }
                    }
                }
            }
            .Overview-Left-Content {    
                .lazyload-wrapper {
                    float:left;
                    width : calc( 100% - 12px );
                    .lazyload-placeholder {

                    }
                }
                .Overview-Left-More {
                    float : left;
                    background-color: transparent;
                    //color : $gn-frame-color;
                    border-color: rgba($gn-frame-color, 1);

                }

            }
            .Overview-Left-Content-1 {
                max-width: calc( 640px + 0rem );
                .lazyload-wrapper {
                    margin : 0.25rem 0rem;
                    flex-basis: calc(50% - 0rem);
                    width: calc( 50% - 0rem);
                    //height : 300px;
                    //width : auto;
                    
                }
            }
            

        }
        .Overview-Right {
            height:auto;
            width : calc(0% - 0px);
            position: absolute;
            top:0px;
            z-index:99;
            padding: 0px;
            margin: 0px;
            right:0px;
            margin-bottom: 0px;
            width:0px;
            min-width: 0px;
            min-height: 0px;
            transition: all 0.25s ease-in-out;
            .Show-Content {
                opacity: 0;
                height : 0px;
                width : 0px;
                overflow: hidden;
                transition: all 0.25s ease-in-out;

            }
            .Overview-Right-Top-Panel {
 
                .Overview-Right-Top-Title {

                }
            }
            .Overview-Right-Content {

            }
            &.Show {
                width : fit-content;
                height:auto;
                bottom:0px;
                width : calc(100% - 40px);  
                .Show-Content {
                    opacity: 1;
                    //display : block; 
                    position: absolute;
                    top:0px;
                    right: 0px;
                    bottom:0px;
                    width : calc(100% - 0px);
                    height: auto;
                    display: flex;
                    flex-direction: column;
                    padding: 0px;
                }
            }
            .Show-Btn {
                display: block;
                width:40px;
                height: 30px;
                position: absolute;
                top:0px;
                left:-40px;
                background-color: rgba( $gn-frame-color, 1 );
                cursor: pointer;
                border-top-left-radius: 1rem;
                border-bottom-left-radius: 1rem;
                display: flex;
                flex-direction: column;
                .Fa {
                    color : rgba( $gn-white-color,1);
                    margin: auto;
                    font-size: 110%;
                }
            }
        }
        .Card-Placeholder {

        }
    }
}

@media screen and (max-width: 560px)  {

    .Overview-Holder {      
        .Overview-Left {
            .Overview-Left-Top-Panel {
                .Overview-Left-Top-Title {
                    .Sort {
                        display: none;
                    }
                }
                .Overview-Left-Top-Filter {
                    .Overview-Left-Top-Filter-Filter {
                        .Overview-Left-Top-Filter-Dropdown { 
                        }
                        .Overview-Left-Top-Filter-Dropdown-Item {
                        }
                    }
                }
            }
            .Overview-Left-Content {    
                .lazyload-wrapper {
                    .lazyload-placeholder {

                    }
                }
                .Overview-Left-More {

                }

            }
            .Overview-Left-Content-1 {
                .lazyload-wrapper {
                    margin: 0.25rem auto;
                    flex-basis: calc(100% - 0rem);
                    width: calc( 100% - 0rem);
                    //height:300px;
                }
            }
            .Legend {
                height: auto;
                display: flex;
                flex-direction: column;
                .Holder {
                    margin-top: 0.5rem;
                    width: fit-content;
                    height:40px;
                }
            }
        }
        .Overview-Drawer {
            &.Appear {
                width : calc( 100% - 0px );
                z-index : 999999;
            }
        }
    }
}