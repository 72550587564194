@import "../../../public/style.scss";

.Garlican-Content-Template-Add {
  position:relative !important;
  top:0px!important;
  border: 0px solid !important;
}

.Garlican-Content-Template-Container {
    position: absolute;
    top: 0px;
    left: 0px;
    right:0px;
    z-index: 999;
    bottom:0px;
    flex-grow: 1;
    flex-direction: column;
    border: 0px solid rgba($gn-dark-color, 0.25);
    background-color: rgba($gn-frame-color, 0);
    transition: visibility, opacity 0.25s ease-in-out;
    padding: 0px 0.625rem;
    padding-top:0px;
    padding-bottom: 10px;
    //flex-basis: 100%;
    display: flex;
    //flex-wrap: wrap;
    //align-content: baseline;
    border-radius: 0rem;
    //border-top-right-radius: 0.5rem;
    //border-bottom-right-radius: 0.5rem;
    overflow: hidden;

    .Manage {
      //border : 2px solid rgba(  $gn-badge-color, 0.5 ) !important;
      border : 2px solid rgba(  $gn-frame-color, 1 ) !important;
      background-color: rgba(  $gn-frame-color, 1 ) !important;
      transition: all 0.25s ease-in-out;
      font-size: 110%;
      border-radius: 0.5rem;
      width: fit-content;
      margin-left: auto;
      margin-top: 1rem;
      font-weight: bolder;
      margin-bottom: 1.5rem;
      .Fa {
        margin-right: 0.5rem;
        width:15px;
      }
      &:hover {
        //color : rgba(  $gn-black-color, 0.8 ) !important;
        background-color: rgba(  $gn-badge-color,1 ) !important;
      }
    }

    .Garlican-Content-List-Title {
      width:100%;
      margin-bottom : 20px;
      color : rgba(  $gn-black-color, 1 );
    }
    .Garlican-Content-List-Add {
      position: absolute;
      right:15px;
      top:15px;
    }

    .Garlican-Content-Template-Search {
      height: 45px;
      .form-control {
        border: 2px solid rgba(  $gn-frame-color, 1 );
        border-top-left-radius :  0.5rem !important;
        border-bottom-left-radius : 0.5rem  !important;
        color : rgba(  $gn-frame-color, 1 );
      }
      .fa {
        color : rgba($gn-white-color, 1);   
      }
      button {
        border: 2px solid rgba(  $gn-frame-color, 1 ) !important;
        padding : calc(0.75rem + 1px) 0.5rem; 
        border-radius: 0.5rem;
        border-top-left-radius : 0px !important;
        border-bottom-left-radius : 0px !important;
        color : rgba($gn-white-color, 1) !important;
        height: max-content;
      }
      .reset {
        border-radius : 0px !important;
        margin: 0px 2px;
        color : rgba($gn-white-color, 1) !important;
      }
    
    }

    .Garlican-Content-List-Panel {
        padding-left: 0px;
        padding-right: 0px;
        margin : 0px 0.625rem;
        width: calc(100% - 1.25rem);
        display: flex;
        flex-direction: row;
        margin-top: 0.5rem;
        .Item {
          color : rgba($gn-black-color, 0.75);
          margin-right: 1rem;
          opacity: 0.5;
          padding: 0rem 0.5rem;
          cursor: pointer;
          &.selected {
            opacity: 1;
            border-bottom: 2px solid rgba($gn-frame-color, 1);
          }
        } 
    }
  
    .Garlican-Content-List-Container {
      width: calc(100% - 0rem);
      flex-direction: column;
      display: flex;
      flex-wrap: nowrap;
      overflow: auto;
      flex-grow: 1;
      border-top : 1px solid rgba($gn-black-color, 0.1);
      border-bottom : 1px solid rgba($gn-black-color, 0.1);
      padding: 0rem 0rem;
      margin-top: 1rem;
      padding-bottom: 1rem;
      .Garlican-Content-List-Full {
        margin: 5px 0rem !important;
        flex-basis: calc(100% - 0rem) !important;
      }
      .Garlican-Content-List {
        flex-wrap: wrap;
        padding-left: 0px;
        padding-right: 0px;
        width: calc( 100% - 0.5rem );
        margin: 0.5rem 0rem;
        opacity: 1;
        box-shadow: none;
        //overflow: hidden;
        transition: background-color,opacity 0.25s ease-in-out;
        background-color: rgba( $gn-white-color ,  1 );
        border-radius: 0.25rem;
        border-width: 0px;
        border: 2px solid rgba($gn-frame-color, 1);
        cursor: pointer;
        &:hover {
          //box-shadow: 0px 0px 10px rgba($gn-dark-color, 0.5);
          background-color: rgba( $gn-badge-color ,  0.1 ) !important;
          opacity: 1;
          //transform: scale3d(1.05, 1.05, 1.05);;
        }
        &:hover .toast-header:not(.name) {
          //background-color: rgba($gn-badge-color, 1);
          //color : rgba($gn-dark-color, 1);
        }
        .toast-header {
          background-color: rgba($gn-white-color, 1);
          color : rgba($gn-black-color, 1);
          transition: all 0.25s ease-in-out;
          font-size: 120%;
          flex-direction: row;
          justify-content: space-between;
          .close {
            color : rgba($gn-white-color, 1);
            &:hover {
              color : rgba($gn-dark-color, 1);
            }
          }
        }
        .name {
          background-color: rgba($gn-light-color, 1);
          color : rgba($gn-black-color, 0.5);
          font-size: 80%;
          .pic {
            width:10px;
            height: 10px;
            margin: 0px 5px;
            color : rgba($gn-white-color, 1);
            background-color: rgba($gn-frame-color, 1);
            /*padding:2.5px;*/
            line-height: 25px;
          }
          .span {
            height:40px;
            line-height: 25px;
            margin-left: 5px;
            cursor: pointer;
          }
        }
        .toast-body {
          text-align: left;
          padding : .25rem .5rem;
          .badge-public {
            border: 2px solid rgba($gn-green-color, 1);
            color : rgba($gn-green-color, 1);
            background-color: rgba( $gn-white-color ,  1 );
            margin-right: 1rem;
            position: relative;
            z-index: 10;
            font-size: 110%;
          }
          .badge-protected {
            border: 2px solid rgba($gn-black-color, 0.8);
            color : rgba($gn-black-color, 0.8);
            background-color: rgba( $gn-white-color ,  1 );
            margin-right: 1rem;
            position: relative;
            z-index: 10;
            font-size: 110%;
          }
          .btn {
            margin : 0px 0px;
          }
          &.control {
            height : 30px !important;
            width:100%;
            padding : 0px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            background-color: rgba($gn-black-color, 0.00) !important;
            backdrop-filter: blur(0px);
            margin:auto;
            margin-top: -10px;
            position: relative;
            z-index:10;
            .fa {
              //background-color: rgba($gn-frame-color, 1);
              color : rgba($gn-frame-color, 1);
              width:40px;
              font-size: 20px;
              transition: color 0.25s ease-in-out;
              padding: 0.25rem 0.5rem;
              &:hover {
                color : rgba($gn-badge-color, 1);
              }
            }
          }
          &.content {
            height:40px;
            transition: height,opacity 0.25s ease-in-out;
            overflow: hidden;
            .description{
              filter: blur(5px);
              position: relative;
              z-index: 5;
              opacity: 0.5;
            }
            &.active {
              height: fit-content;
              .description {
                filter: blur(0px);
                opacity: 1;
                white-space: break-spaces;
              }
            }
          }
          &.Use-Wrapper {
            display: flex;
            flex-direction: row;
            .Use {
              margin-left: auto;
              border-radius: 0.5rem;
              margin-bottom: 0.5rem;
              &.Used {
                background-color: rgba(  $gn-badge-color, 0 ) !important ;
                border: 2px solid rgba( $gn-frame-color, 1);
                color : rgba(  $gn-frame-color, 1 ) !important ;
              }
            }
          }
        }
      }
      .Garlican-Content-List-More {
        margin : 10px 0.625rem;
        flex-basis: calc(33% - 1.25rem);
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  
}
.show {
      visibility: visible;
      opacity : 1;      
}
.hide {
      visibility: hidden;
      opacity : 0;
}





@media screen and (max-width: 751px)  {
    .Garlican-Content-Template-Container{
      z-index: 9999 !important;
      .Garlican-Content-List-Container {
        //flex-direction: column;
        .Garlican-Content-List {
          flex-basis: calc( 100% - 1.25rem);
        }
      }
    }
}