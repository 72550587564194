@import "../../public/style.scss";

.User-Holder {
  /*position:absolute;
  top:0px;
  left:0px;
  right : 0px;
  bottom:0px;
  z-index: 99;
  background-color: $gn-white-color;*/
    .User-Title {
      position: relative;
      width : calc( 100% - 2rem);
      line-height: 60px;
      text-align: center;
      margin-bottom: 20px;
      .close {
        line-height: 50px;
      }
      .User-Title-Frame {
        float:left;
        .User-Title-Logo {
          color : rgba($gn-frame-color, 1);
          float:left;
          width:35px;
          height:35px;
          /*padding : 2px 0px;*/
          margin: 7.5px 5px;
          /*border: 0px solid $gn-white-color;
          background-color: $gn-frame-color;
          color : $gn-white-color;
          border-radius: 7.5px;
          -moz-border-radius:7.5px;
          -webkit-border-radius:7.5px;*/
          line-height: 35px;
          font-weight: 600;
          font-size: 30px;
        }
        .User-Title-Title {
          float:left;
          height:50px;
          /*padding : 2px 0px;*/
          margin: 0px 2.5px;
          color: rgba($gn-black-color, 1);
          border-radius: 7.5px;
          -moz-border-radius:7.5px;
          -webkit-border-radius:7.5px;
          line-height: 50px;
          font-weight: 600;
          font-size: 18px;
        }
      }
    }

    .User-Body {
      flex-direction: column;
      display: flex;
      .User-Body-Image-Container {
        position: relative;
        height:300px;
        width:100%;
        background-color: rgba($gn-white-color, 1);
        .User-Body-Image-Btn {
          position: absolute;
          right:5px;
          bottom:5px;
        }
      }
      .User-Body-Title {
        text-align: center;
        margin-bottom: 40px;
      }
      .User-Input {
        position: relative;
        margin : 0px calc( 50% - 150px);
        padding : 0px 5px;
        text-align: left;
        color : rgba($gn-dark-color, 1);
        font-size: 15px;;
        margin-bottom: 5px;
        height:40px;
        width : 300px;
        line-height: 40px;
        border : 0px;
        border-bottom: 1px rgba($gn-grey-color, 1) solid;
      }
      .User-Body-Image {
        //margin-left : calc(50% - 50px);
        margin: auto;
        height:100px;
        width:100px;
        color: rgba($gn-white-color, 1);
        background-color: rgba($gn-frame-color, 1);
        //padding:10px;
        border-radius: 50%;
        border: 4px solid rgba($gn-frame-color, 1);
        &.Fa {
          padding:20px !important;
          width:60px;
          height:60px;
        }
      }
      .User-Body-Upload {
        //width: 100%;
        height:100px;
        flex-direction: row;
        display: flex;
        justify-content: center;
        //height:auto;
        padding:20px 0px;
        padding-top:0px;
        margin-top: -108px;
        opacity: 0.25px;
        transition: all 0.25s ease-in-out;
        &:hover{
          margin-top: -108px;
          .Fa {
            padding:2rem;
            width:calc( 108px - 4rem);
            height:calc( 108px - 4rem);
            background-color: rgba($gn-white-color, 0.75);
            opacity: 1;
            backdrop-filter: blur( 5px );
          }
        }
        .Fa {  
          border-radius: 50%;
          margin-top: auto;
          color: rgba($gn-frame-color, 1);
          padding:0.5rem;
          background-color: rgba($gn-white-color, 0.5);
          transition: all 0.25s ease-in-out;
          opacity: 0;
          width:10px;
          height:10px;
        }
      }
      .User-Stm-Frame {
        height:40px;
        line-height: 40px;
        margin : 0px calc( 50% - 150px);
        width : 310px;
        float:left;
        text-align: left;
        color : rgba($gn-frame-color, 1);
      }
      .User-Btn-Frame {
        margin : 0px calc( 50% - 155px);
        width : 310px;
        float:left;
        padding : 0px 0px;
        text-align: center;
        .User-Btn {
          position: relative;
          padding : 0px 20px;
          height:40px;
          line-height: 40px;
          margin: 0px 0px;
          cursor: pointer;
          background-color: rgba($gn-frame-color, 1);
          color : rgba($gn-white-color, 1);
          &:first-child {
            margin-right: 10px;
          }
        }
        .User-Btn-Black {
          background-color: rgba($gn-frame-color, 1);
        }
        .User-Btn:hover {
          background-color: rgba($gn-green-color, 1);
        }
      }
      .User-Body-Info {
        flex-direction: row;
        display: flex;
        flex-wrap: wrap;
        .Wrapper {
          margin:1.5rem 0.5rem;
          position: relative;
          transition: all 0.25s ease-in-out;
          //background-color: rgba($gn-frame-color, 1) !important;
          &.PWrapper {
            margin: 1.5rem auto;
            width: calc( 50% - 0px - 1.0rem);
            flex-basis : calc( 50% - 0px - 1.0rem) !important;
            position: relative;
            z-index : 90;
            width: fit-content;
            .PLabel {
              transition: all 0.25s ease-in-out;
              position: absolute;
              z-index : 5;
              font-size: smaller;
              top: -20px;
              left:0px;
              text-align: left;
              color : rgba( $gn-black-color, 0.5 ) !important;
            }
            .PBody {
              display: flex;
              flex-direction: row;
              position: relative;
              .react-tel-input  {
                width: calc( 100% - 100px - 0.25rem);
                .form-control {
                  border-color: rgba($gn-black-color, 0.1);
                  color : rgba( $gn-black-color, 0.8 );
                  font-weight: bolder;
                  width : calc( 100% - 50px - 0.75rem);
                }
                .flag-dropdown  {
                  border-color: rgba($gn-black-color, 0.1);
                  //background-color: rgba( $gn-black-color, 0.05 );
                }
              }
              .Vertify {
                position: absolute;
                height: 20px;
                top:15px;
                right : calc( 100px + 0.75rem );
                font-weight: bolder;
                font-size: 80%;
                color : rgba( $gn-green-color, 0.5 );
                //color : rgba( $gn-white-color, 1 );
                //padding: 0rem 1rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                border-radius: 1rem;
                &.Unvertify {
                  color : rgba( $gn-red-color, 0.5 );
                }
              }
              .Send-Btn {
                display: flex;
                flex-direction: column;
                justify-content: center;
                //width : 100px !important;
                //padding:0px;
                margin-left: 0.25rem;
                height: auto;
                color : rgba($gn-white-color, 1);
                &:hover {
                  background-color: rgba($gn-badge-color, 1) !important;
                  border-color: rgba($gn-badge-color, 1) !important;
                  color : rgba($gn-black-color, 1);
                }
              }
            }
            .PVertify {
              display: flex;
              flex-direction: column;
              position: relative;
              .PVertify-Wrapper {
                margin-top: 1rem;
                display: flex;
                flex-direction: row;
                .PVertify-Btn {
                  //margin: 0px;
                  width: fit-content;
                  color : rgba($gn-white-color, 1);
                  &.Left {
                    margin-left: auto;
                  }
                  &.Right {
                    margin-left: 1rem;
                  }
                }
              }
            }
          }
          &:hover:not(.PWrapper) {
            background-color: rgba($gn-frame-color, 0.1) !important;
            box-shadow: 0px 0px 5px rgba($gn-frame-color, 0.2) !important;;
            .WLabel {
              top:-20px;
              color : rgba($gn-black-color, 1);
              font-size: 100% !important;
              opacity: 1;
            }
          }
          .WLabel {
            transition: all 0.25s ease-in-out;
            position: absolute;
            height:10px;
            top:-5px;
            left:0px;
            font-size: smaller !important;
            color : rgba($gn-black-color, 0.75); 
            opacity: 0.5;
          }
          .Value, .Textarea {
            padding: 0rem 1rem;
            padding-top: 1rem;
            width: (calc( 100% - 2rem ));
            border-width: 0px;
            border-bottom: 1px solid rgba($gn-black-color, 0.25);
            border-radius: 0px;
            background-color: transparent;
          }
          .Textarea {
            //border-left: 1px solid rgba($gn-black-color, 0.25);
            //border-right: 1px solid rgba($gn-black-color, 0.25);
            height: auto;
          }
          &.Half {
            flex-basis: calc( 50% - 1rem);
          }
          &.Full {
            flex-basis: calc( 100% - 1rem);
          }

        }

      }

    }

    .LWrapper {
      //border:;
      display: flex;
      flex-direction: row;
      margin: 1.5rem auto;
      width : 90%;
      margin-bottom: 2.5rem;
      .Link {
        background-color: rgba($gn-light-color, 1);
        border: 2px solid rgba($gn-frame-color, 0.25);
        flex-grow: 1;
        border-right-width: 0px;
        padding: 0rem 1rem;
        height : auto;
      }
      .btn {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        height: auto;
      };
      //margin-bottom: 20px;
    }

    .User-Bottom-Frame {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      span {
        margin: 0px 0.5rem;
      }
      margin-top: 1rem;
      .Footer-Frame-Control-a{
        color: rgba($gn-dark-color, 1);
        &:hover {
          color: rgba($gn-frame-color, 1);
          text-decoration: none;
        }
      }
      .Copyright {
        //@at-root:left;
        //height:30px;
        /*padding : 2px 0px;*/
        margin: 0px 2.5px;
        //margin-left: auto;
        margin-left: 0.5rem;
        margin-right: auto;
        color: rgba($gn-dark-color, 1);
        border-radius: 7.5px;
        -moz-border-radius:7.5px;
        -webkit-border-radius:7.5px;
        //line-height: 30px;
        font-size: 0.8rem;
        font-weight: 600;
        &.dark {
          color: rgba($gn-white-color, 1);
        }
      }
    }
}